import { DEFAULT_ERROR_MESSAGE } from "../../../constants";
import JSZip from "jszip";
import L from "leaflet";
import { MAP_TILE_LAYERS } from "../constants";
import download from "downloadjs";
import { geoJSONUtil } from "../utils";
import { useSnackbar } from "../../../hooks";

export const useGetMapTiles = (map, parcellaireState, selectedTileLayer) => {
    const { openSnackbar } = useSnackbar();

    return () => {
        const bounds = geoJSONUtil
            .getBoundsOfFeatures(parcellaireState.transformedGeoJson.features)
            .map((coord) => coord.reverse());
        // Has to be animated for moveend
        map.flyToBounds(bounds, { maxZoom: 19 });

        map.once("moveend", () => {
            map.eachLayer(async (layer) => {
                if (layer instanceof L.TileLayer) {
                    const urls = getTileUrls(
                        map.getBounds(),
                        layer,
                        map.getZoom(),
                        map
                    );
                    const zip = new JSZip();

                    try {
                        const promises = urls.map(async (url) => {
                            const blob = (await fetch(url)).blob();
                            const [, zoom, coord1, coord2] =
                                /\/(\d+)\/(\d+)\/(\d+)(@2x)?(\.png)?$/.exec(
                                    url
                                );
                            zip.file(
                                // openstreetmap and dark layers coordinates are not in the same order as the two others
                                // we're making openstreetmap's coordinates order the default one as it's the one used by the literal
                                selectedTileLayer ===
                                    MAP_TILE_LAYERS.OPEN_STREET_MAP ||
                                    selectedTileLayer === MAP_TILE_LAYERS.DARK
                                    ? `${zoom}_${coord1}_${coord2}.png`
                                    : `${zoom}_${coord2}_${coord1}.png`,
                                blob,
                                { binary: true }
                            );
                        });

                        await Promise.all(promises);
                        const archive = await zip.generateAsync({
                            type: "blob",
                        });
                        download(archive, "tiles.zip");
                    } catch {
                        openSnackbar(DEFAULT_ERROR_MESSAGE, "error");
                    }
                }
            });
        });
    };
};

function getTileUrls(bounds, tileLayer, zoom, map) {
    const min = map.project(bounds.getNorthWest(), zoom).divideBy(256).floor();
    const max = map.project(bounds.getSouthEast(), zoom).divideBy(256).floor();
    const urls = [];

    for (var i = min.x; i <= max.x; i++) {
        for (var j = min.y; j <= max.y; j++) {
            var coords = new L.Point(i, j);
            coords.z = zoom;
            urls.push(tileLayer.getTileUrl(coords));
        }
    }

    return urls;
}
