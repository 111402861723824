import { BE_RESOURCE_ACCESS, KC_ROLES } from "../constants";

import { useOidcAccessToken } from "@axa-fr/react-oidc";

export function useIsBasicRole() {
    const { accessTokenPayload } = useOidcAccessToken();

    const roles =
        accessTokenPayload?.resource_access[BE_RESOURCE_ACCESS]?.roles;

    return !(
        roles &&
        [KC_ROLES.ADMIN, KC_ROLES.BACKOFFICE].some((role) =>
            roles.includes(role)
        )
    );
}
