import {
    Autocomplete,
    Checkbox,
    Divider,
    Grid,
    TextField,
    Typography,
} from "@mui/material";
import { Controller, useWatch } from "react-hook-form";

import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { MODEL_PROPTYPES } from "../../../models";
import PropTypes from "prop-types";
import { TRAIT_GROUP_CATEGORY } from "../../../constants";
import { useMemo } from "react";
import { useTraitGroupDataTypes } from "../../../hooks";

PipelineTemplateTraitForm.propTypes = {
    selectedAcquisitionVector: PropTypes.shape(
        MODEL_PROPTYPES.AcquisitionVector
    ),
};

export function PipelineTemplateTraitForm({ selectedAcquisitionVector }) {
    const {
        data: { rows: traitGroupDataTypes },
    } = useTraitGroupDataTypes();

    const selectedCropUuid = useWatch({ name: "cropUuid" });

    const filteredTraitGroupDataTypeData = useMemo(() => {
        /**
         * we filter trait group data types by their trait group category.
         * if there's a selectedAcquisitionVector, we also filter out any trait group data type that does not have the same data type as the selectedAcquisitionVector.
         */
        const selectedDataTypes = selectedAcquisitionVector?.SensorBundles.map(
            (bundle) => bundle.Sensor.dataType
        );
        const traitGroupFiltering = (traitGroupCategory) => (TGDT) =>
            selectedDataTypes?.includes(TGDT.dataType) &&
            TGDT.TraitGroup.category === traitGroupCategory;

        return {
            canopy: traitGroupDataTypes.filter(
                traitGroupFiltering(TRAIT_GROUP_CATEGORY.CANOPY_DEVELOPMENT)
            ),
            biomass: traitGroupDataTypes.filter(
                traitGroupFiltering(TRAIT_GROUP_CATEGORY.BIOMASS_PROXY)
            ),
            trial: traitGroupDataTypes.filter(
                traitGroupFiltering(TRAIT_GROUP_CATEGORY.TRIAL_QUALITY)
            ),
            harvest: traitGroupDataTypes.filter(
                traitGroupFiltering(
                    TRAIT_GROUP_CATEGORY.HARVEST_INDEX_AND_QUALITY
                )
            ),
            plant: traitGroupDataTypes.filter(
                traitGroupFiltering(TRAIT_GROUP_CATEGORY.PLANT_STRESS)
            ),
        };
    }, [selectedAcquisitionVector, traitGroupDataTypes]);

    const isDisabled = !(selectedAcquisitionVector && selectedCropUuid);

    return (
        <Grid item xs={12}>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Typography>Select the traits to compute</Typography>
                    <Divider />
                </Grid>

                <Grid container item xs={12} spacing={1}>
                    <Grid item md={5} xs={12}>
                        <TraitGroupDataTypeAutocomplete
                            options={filteredTraitGroupDataTypeData.canopy}
                            disabled={isDisabled}
                            id={"canopyDevelopment"}
                            label={"Canopy Development"}
                        />
                    </Grid>
                    <Grid item md={5} xs={12}>
                        <TraitGroupDataTypeAutocomplete
                            options={filteredTraitGroupDataTypeData.biomass}
                            disabled={isDisabled}
                            id={"biomassProxy"}
                            label={"Biomass Proxy"}
                        />
                    </Grid>
                </Grid>
                <Grid container item xs={12} spacing={1}>
                    <Grid item md={5} xs={12}>
                        <TraitGroupDataTypeAutocomplete
                            options={filteredTraitGroupDataTypeData.trial}
                            disabled={isDisabled}
                            id={"trialQuality"}
                            label={"Trial Quality"}
                        />
                    </Grid>
                    <Grid item md={5} xs={12}>
                        <TraitGroupDataTypeAutocomplete
                            options={filteredTraitGroupDataTypeData.harvest}
                            disabled={isDisabled}
                            id={"harvestIndex"}
                            label={"Harvest Index & Quality"}
                        />
                    </Grid>
                </Grid>
                <Grid container item xs={12} spacing={1}>
                    <Grid item md={5} xs={12}>
                        <TraitGroupDataTypeAutocomplete
                            options={filteredTraitGroupDataTypeData.plant}
                            disabled={isDisabled}
                            id={"plantStress"}
                            label={"Plant Stress"}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const TraitGroupDataTypeAutocomplete = ({ options, disabled, id, label }) => {
    return (
        <Controller
            name={id}
            render={({ field: { ref, onChange, name, ...fieldProps } }) => (
                <Autocomplete
                    {...fieldProps}
                    multiple
                    id={id}
                    // the options array has to be sorted by its groupBy parameter (and it's also sorted by name for convenience)
                    // groupBy only groups the nearest options together if they have the same parameter (dataType), sorting removes duplicate groups
                    options={options.sort((a, b) =>
                        -b.dataType.localeCompare(a.dataType) === 0
                            ? -b.TraitGroup.name.localeCompare(
                                  a.TraitGroup.name
                              )
                            : -b.dataType.localeCompare(a.dataType)
                    )}
                    groupBy={(option) => option.dataType}
                    isOptionEqualToValue={(option, value) =>
                        option.uuid === value.uuid
                    }
                    disabled={disabled}
                    disableCloseOnSelect
                    getOptionLabel={(option) =>
                        `${option.TraitGroup.name} (${option.dataType})`
                    }
                    onChange={(_, value) => onChange(value)}
                    renderOption={(props, option, { selected }) => (
                        <li {...props}>
                            <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{
                                    marginRight: 8,
                                }}
                                checked={selected}
                            />
                            {`${option.TraitGroup.name} (${option.dataType})`}
                        </li>
                    )}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            inputRef={ref}
                            name={name}
                            label={label}
                        />
                    )}
                />
            )}
        />
    );
};
