import { Stack, TextField, Typography } from "@mui/material";
import { useFormContext, useWatch } from "react-hook-form";

import { INPUT_VALIDATION } from "../../constants";

export const PlotGridForm = () => {
    const {
        register,
        formState: { errors },
    } = useFormContext();

    const [xCount, yCount] = useWatch({ name: ["xCount", "yCount"] });
    const plotCount = (xCount ? xCount : 1) * (yCount ? yCount : 1);

    return (
        <Stack direction="row" spacing={1} alignItems="center">
            <TextField
                type="number"
                label="X (columns)"
                id="xCount"
                error={Boolean(errors.xCount)}
                helperText={errors.xCount?.message}
                {...register("xCount", {
                    required: INPUT_VALIDATION.REQUIRED,
                    valueAsNumber: true,
                    validate: (value) => value > 0 || INPUT_VALIDATION.POSITIVE,
                })}
                inputProps={inputProps}
            />
            <Typography>×</Typography>
            <TextField
                type="number"
                label="Y (rows)"
                id="yCount"
                error={Boolean(errors.yCount)}
                helperText={errors.yCount?.message}
                {...register("yCount", {
                    required: INPUT_VALIDATION.REQUIRED,
                    valueAsNumber: true,
                    validate: (value) => value > 0 || INPUT_VALIDATION.POSITIVE,
                })}
                inputProps={inputProps}
            />
            <Typography sx={{ whiteSpace: "nowrap" }}>
                = {plotCount} plot
                {plotCount > 1 && "s"}
            </Typography>
        </Stack>
    );
};

const inputProps = { inputMode: "decimal", step: "1" };
