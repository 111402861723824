import { EARTH_RADIUS } from "../constants";

/**
 * Calculates distance, in m, from point x to point y.
 * Implements the Harversine formula: https://en.wikipedia.org/wiki/Haversine_formula
 * @param {number[]} x - First point of coordinates
 * @param {number[]} y - Second point of coordinates
 * @returns {number} Amount of m between x and y
 */
export function getDistanceFromPoints(x, y) {
    const dLat = (y[1] - x[1]) * (Math.PI / 180);
    const dLon = (y[0] - x[0]) * (Math.PI / 180);
    const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(x[1] * (Math.PI / 180)) *
            Math.cos(y[1] * (Math.PI / 180)) *
            Math.sin(dLon / 2) *
            Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    return EARTH_RADIUS * c;
}
