import { Controller, useFormContext, useWatch } from "react-hook-form";
import { Divider, Grid, MenuItem, TextField, Typography } from "@mui/material";

import { usePresets } from "../../../hooks";

export function PipelineTemplatePresetForm() {
    const {
        formState: { errors },
    } = useFormContext();
    const selectedPresetUuid = useWatch({ name: "presetUuid" });

    const {
        data: { rows: presets },
    } = usePresets();

    const TGDTSortedByDataType = Object.groupBy(
        selectedPresetUuid
            ? presets.find((preset) => preset.uuid === selectedPresetUuid)
                  .TraitGroupDataTypes
            : [],
        ({ dataType }) => dataType
    );

    return (
        <Grid item xs={12} container spacing={1}>
            <Grid item xs={12}>
                <Typography>Select a preset</Typography>
                <Divider />
            </Grid>
            <Grid item xs={6}>
                <Controller
                    name="presetUuid"
                    render={({ field: { ref, onChange, ...fieldProps } }) => (
                        <TextField
                            {...fieldProps}
                            fullWidth
                            id="presetUuid"
                            select
                            label="Preset"
                            inputRef={ref}
                            error={Boolean(errors.presetUuid)}
                            helperText={errors.presetUuid?.message}
                            onChange={(event) => {
                                onChange(event.target.value);
                            }}
                        >
                            {presets.map((preset) => (
                                <MenuItem key={preset.uuid} value={preset.uuid}>
                                    {preset.name}
                                </MenuItem>
                            ))}
                        </TextField>
                    )}
                />
            </Grid>
            <Grid item xs={6} />
            <Grid item xs={12}>
                {Object.keys(TGDTSortedByDataType).map((dataType) => (
                    <Grid key={dataType} item container spacing={0.5}>
                        <Grid item xs={12}>
                            <Typography
                                sx={{ fontStyle: "italic", color: "grey" }}
                            >
                                <strong>{`Selected traits (${dataType})`}</strong>
                            </Typography>
                            <Divider />
                        </Grid>

                        <Grid item xs={12}>
                            <Typography>
                                {TGDTSortedByDataType[dataType]
                                    .map(({ TraitGroup }) => TraitGroup.name)
                                    .join(" ; ")}
                            </Typography>
                        </Grid>
                    </Grid>
                ))}
            </Grid>
        </Grid>
    );
}
