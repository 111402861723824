import {
    Autocomplete,
    Card,
    Dialog,
    FormControlLabel,
    Grid,
    Stack,
    Switch,
    TextField,
    Typography,
} from "@mui/material";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import { Link, useSearchParams } from "react-router-dom";
import {
    createSortSearchParams,
    objectArrayStringify,
    userNamesComparator,
} from "../../utils";
import { useCallback, useMemo, useState } from "react";
import { useFetch, useSnackbar, useUsers } from "../../../hooks";

import AddLinkIcon from "@mui/icons-material/AddLink";
import { AlignmentModal } from "../.";
import { BACKEND_ROUTES } from "../../../backendRoutes";
import CropOriginalIcon from "@mui/icons-material/CropOriginal";
import { FRONTEND_ROUTES } from "../../../frontendRoutes";
import { FetchErrorAlert } from "../../../components/FetchErrorAlert";
import { FlagTooltipIcon } from "../../../components/FlagTooltipIcon";
import { MODES } from "../../acquisition-report/constants";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import { ReportStatus } from "./ReportStatus";
import Scrollbar from "../../../components/Scrollbar";
import { TrafficTableSkeleton } from "../TrafficTableSkeleton";
import { flagsSetter } from "./";
import { formatDateTime } from "../../../utils/formatTime";
import { laggy } from "../../../utils/SWRLaggy";
import useSWR from "swr";
import useSWRImmutable from "swr/immutable";

export function InboundTrafficTable() {
    const [filters, setFilters] = useSearchParams();

    const [visibilityModel, setVisibilityModel] = useState({
        reportStatus: true,
        company: true,
        campaign: true,
        site: true,
        acquisitionDate: true,
        uploadDate: true,
        dataType: true,
        acquisitionVector: true,
        supervisorUuid: true,
        flags: false,
        status: true,
        actions: true,
    });

    const [{ sortModel, paginationModel }, setGridState] = useState({
        ...initialState.sorting,
        ...initialState.pagination,
    });
    const [selectedMission, setSelectedMission] = useState(null);
    const [isAlignmentModalOpen, setIsAlignmentModalOpen] = useState(false);

    const filterParams = {
        contractUuid: filters.get("contractUuid"),
        companyUuid: filters.get("companyUuid"),
        supervisorUuid: filters.get("supervisorUuid"),
        showAllTraffic: filters.get("showAllTraffic") === "true",
    };

    const searchParams = new URLSearchParams({
        associationCompleted: false,
        parentInfo: true,
        experimentMissions: true,
        acquisitionVector: true,
        rawDatasetStatistics: visibilityModel.flags,
        limit: pageItemCount,
        offset: paginationModel.page * pageItemCount,
        ...(filterParams.contractUuid && {
            Site_contractUuid: filterParams.contractUuid,
        }),
        ...(filterParams.companyUuid && {
            Site_Contract_companyUuid: filterParams.companyUuid,
        }),
        ...(filterParams.supervisorUuid && {
            supervisorUuid: filterParams.supervisorUuid,
        }),
        onlySupervisedMissions: !filterParams.showAllTraffic,
        ...createSortSearchParams(sortModel, sortMap),
    });

    const {
        data: missions,
        error: missionsFetchError,
        isLagging,
        mutate,
    } = useSWR(`${BACKEND_ROUTES.MISSION}?${searchParams}`, { use: [laggy] });

    const { data: companies, error: companiesFetchError } = useSWRImmutable(
        BACKEND_ROUTES.COMPANY
    );

    const { data: contracts, error: contractsFetchError } = useSWRImmutable(
        BACKEND_ROUTES.CONTRACT
    );

    const { data: users, error: usersFetchError } = useUsers();

    const { openSnackbar } = useSnackbar();
    const { patch } = useFetch();

    const handleProcessRowUpdateError = useCallback(
        (error) => openSnackbar(error.message, "error"),
        [openSnackbar]
    );

    const updatePagination = useCallback(
        (newPaginationModel) =>
            setGridState((prevState) => ({
                ...prevState,
                paginationModel: newPaginationModel,
            })),
        []
    );

    const updateSort = useCallback(
        (newSortModel) =>
            setGridState((prevState) => ({
                ...prevState,
                sortModel: newSortModel,
            })),
        []
    );

    const processRowUpdate = useCallback(
        async (newRow) => {
            const updateMission = patch(
                `${BACKEND_ROUTES.MISSION}/${newRow.uuid}`,
                {
                    body: {
                        supervisorUuid:
                            newRow.supervisorUuid === invalidUuid
                                ? null
                                : newRow.supervisorUuid,
                    },
                    forwardError: true,
                }
            );
            await mutate(updateMission, { populateCache: false });

            openSnackbar(
                `Updated Mission of ${formatDateTime(
                    newRow.acquisitionDate
                )} successfully.`,
                "success"
            );

            return newRow;
        },
        [mutate, openSnackbar, patch]
    );

    const rows = useMemo(
        () =>
            missions?.rows.map((mission) => ({
                id: mission.uuid,
                uuid: mission.uuid,
                site: mission.Site.name,
                dataType: objectArrayStringify(
                    mission.AcquisitionVector.SensorBundles,
                    ["Sensor", "dataType"]
                ),
                acquisitionDate: new Date(mission.date),
                uploadDate: new Date(mission.createdAt),
                supervisorUuid: mission.supervisorUuid ?? invalidUuid,
                company: mission.Site.Contract.Company.name,
                campaign: mission.Site.Contract.name,
                acquisitionVector: mission.AcquisitionVector.name,
                status: mission.preProcessingStatus,
                experiments: mission.Site.Experiments,
                flags: mission.rawDatasetStatistics
                    ? flagsSetter(mission, mission.rawDatasetStatistics)
                    : [],
                reportStatus: mission.reportStatus,
            })),
        [missions]
    );

    const columns = useMemo(
        () =>
            users && [
                {
                    field: "reportStatus",
                    headerName: "Report",
                    minWidth: 60,
                    flex: 2,
                    sortable: false,
                    renderCell: (params) =>
                        params.value ? (
                            <ReportStatus status={params.value} />
                        ) : (
                            <Typography
                                sx={{
                                    textAlign: "center",
                                    paddingLeft: "8px",
                                }}
                            >
                                -
                            </Typography>
                        ),
                    filterable: false,
                },
                {
                    field: "actions",
                    headerName: "Actions",
                    type: "actions",
                    minWidth: 80,
                    flex: 2,
                    getActions: (params) => {
                        const openAlignmentModal = () => {
                            setSelectedMission(params.row.missionUuid);
                            setIsAlignmentModalOpen(true);
                        };

                        return [
                            <GridActionsCellItem
                                label="Acquisition report"
                                icon={<QueryStatsIcon />}
                                component={Link}
                                to={`/${FRONTEND_ROUTES.TRAFFIC}/${FRONTEND_ROUTES.INBOUND_TRAFFIC}/${FRONTEND_ROUTES.ACQUISITION_REPORT}/${params.row.id}?mode=${MODES.NORMAL}`}
                            />,
                            <GridActionsCellItem
                                label={
                                    params.row.experiments.length
                                        ? "Associate"
                                        : "Experiments are missing"
                                }
                                icon={<AddLinkIcon />}
                                disabled={!params.row.experiments.length}
                                component={Link}
                                to={`/${FRONTEND_ROUTES.TRAFFIC}/${FRONTEND_ROUTES.INBOUND_TRAFFIC}/${FRONTEND_ROUTES.INBOUND_TRAFFIC_DETAILS}/${params.row.id}`}
                                showInMenu
                            />,
                            <GridActionsCellItem
                                label="Alignment report"
                                icon={<CropOriginalIcon />}
                                onClick={openAlignmentModal}
                                showInMenu
                            />,
                        ];
                    },
                    filterable: false,
                },
                {
                    field: "company",
                    headerName: "Company",
                    minWidth: 160,
                    flex: 2,
                    sortable: false,
                    filterable: false,
                },
                {
                    field: "campaign",
                    headerName: "Campaign",
                    minWidth: 160,
                    flex: 2,
                    sortable: false,
                    filterable: false,
                },
                {
                    field: "site",
                    headerName: "Site",
                    minWidth: 160,
                    flex: 2,
                    sortable: false,
                    filterable: false,
                },
                {
                    field: "acquisitionDate",
                    type: "date",
                    headerName: "Acquisition Date",
                    minWidth: 140,
                    flex: 2,
                    filterable: false,
                },
                {
                    field: "uploadDate",
                    type: "date",
                    headerName: "Upload Date",
                    minWidth: 140,
                    flex: 2,
                    filterable: false,
                },
                {
                    field: "dataType",
                    headerName: "Type",
                    minWidth: 160,
                    flex: 2,
                    sortable: false,
                    filterable: false,
                },
                {
                    field: "acquisitionVector",
                    headerName: "Acquisition Vector",
                    minWidth: 160,
                    flex: 2,
                    sortable: false,
                    filterable: false,
                },
                {
                    field: "supervisorUuid",
                    headerName: "Supervisor",
                    minWidth: 160,
                    flex: 2,
                    editable: true,
                    type: "singleSelect",
                    sortComparator: (userUuidA, userUuidB) =>
                        userNamesComparator(
                            userUuidA,
                            userUuidB,
                            users,
                            invalidUuid,
                            "No Supervisor"
                        ),
                    valueOptions: [
                        ...users.map((user) => ({
                            value: user.uuid,
                            label: `${user.firstName} ${user.lastName}`,
                        })),
                        { value: invalidUuid, label: "No supervisor" },
                    ],
                    sortable: false,
                    filterable: false,
                },
                {
                    field: "flags",
                    headerName: "Flags",
                    flex: 2,
                    renderCell: (param) =>
                        param.value.length ? (
                            param.value.map((flag) => (
                                <FlagTooltipIcon
                                    key={flag.value}
                                    Icon={flag.icon}
                                    message={flag.message}
                                />
                            ))
                        ) : (
                            <Typography>-</Typography>
                        ),
                    sortable: false,
                    filterable: false,
                },
                {
                    field: "status",
                    headerName: "Status",
                    minWidth: 160,
                    flex: 2,
                    sortable: false,
                    filterable: false,
                },
            ],
        [users]
    );

    const selectedItems = useMemo(() => {
        return {
            company: companies
                ? companies.rows.find(
                      (company) => company.uuid === filterParams.companyUuid
                  )
                : null,
            contract: contracts
                ? contracts.rows.find(
                      (contract) => contract.uuid === filterParams.contractUuid
                  )
                : null,
            supervisor: users
                ? users.find(
                      (user) => user.uuid === filterParams.supervisorUuid
                  )
                : null,
        };
    }, [
        companies,
        contracts,
        users,
        filterParams.companyUuid,
        filterParams.contractUuid,
        filterParams.supervisorUuid,
    ]);

    const mergedFetchError =
        usersFetchError ??
        missionsFetchError ??
        contractsFetchError ??
        companiesFetchError;

    if (mergedFetchError) return <FetchErrorAlert error={mergedFetchError} />;

    if (!missions || !users || !contracts || !companies) {
        return <TrafficTableSkeleton />;
    }

    return (
        <>
            <Stack justifyContent="space-between" mb={2} spacing={1}>
                <Stack
                    justifyContent="space-between"
                    direction="row"
                    spacing={1}
                >
                    <Typography variant="h4" gutterBottom>
                        Inbound Traffic
                    </Typography>
                    <FormControlLabel
                        id="showAllTraffic"
                        name="showAllTraffic"
                        control={<Switch />}
                        label="Show Only Current User's Traffic"
                        labelPlacement="end"
                        checked={!filterParams.showAllTraffic}
                        onChange={(_) => {
                            setFilters((prevState) => {
                                const newSearchParams = new URLSearchParams(
                                    prevState
                                );
                                if (filterParams.showAllTraffic)
                                    newSearchParams.delete("showAllTraffic");
                                else
                                    newSearchParams.set(
                                        "showAllTraffic",
                                        !filterParams.showAllTraffic
                                    );
                                return newSearchParams;
                            });
                        }}
                    />
                </Stack>
                <Grid container spacing={1}>
                    <Grid item xs={3}>
                        <Autocomplete
                            id="company"
                            value={selectedItems.company ?? null}
                            options={companies.rows}
                            isOptionEqualToValue={(option, value) =>
                                option.uuid === value.uuid
                            }
                            getOptionLabel={(option) => option.name}
                            onChange={(_, value) => {
                                setFilters((prevState) => {
                                    const newSearchParams = new URLSearchParams(
                                        prevState
                                    );
                                    if (value) {
                                        newSearchParams.set(
                                            "companyUuid",
                                            value.uuid
                                        );
                                        if (
                                            selectedItems.contract
                                                ?.companyUuid !== value.uuid
                                        )
                                            newSearchParams.delete(
                                                "contractUuid"
                                            );
                                    } else {
                                        newSearchParams.delete("companyUuid");
                                    }
                                    return newSearchParams;
                                });
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Filter on company"
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <Autocomplete
                            id="contract"
                            value={selectedItems.contract ?? null}
                            options={contracts.rows.filter((contract) =>
                                filterParams.companyUuid
                                    ? filterParams.companyUuid ===
                                      contract.companyUuid
                                    : true
                            )}
                            isOptionEqualToValue={(option, value) =>
                                option.uuid === value.uuid
                            }
                            getOptionLabel={(option) => option.name}
                            onChange={(_, value) => {
                                setFilters((prevState) => {
                                    const newSearchParams = new URLSearchParams(
                                        prevState
                                    );
                                    if (value) {
                                        newSearchParams.set(
                                            "contractUuid",
                                            value.uuid
                                        );
                                    } else {
                                        newSearchParams.delete("contractUuid");
                                    }
                                    return newSearchParams;
                                });
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Filter on campaign"
                                />
                            )}
                        />
                    </Grid>
                    {filterParams.showAllTraffic && (
                        <Grid item xs={3}>
                            <Autocomplete
                                id="supervisor"
                                options={users}
                                value={selectedItems.supervisor ?? null}
                                isOptionEqualToValue={(option, value) =>
                                    option.uuid === value.uuid
                                }
                                getOptionLabel={(option) =>
                                    `${option.firstName} ${option.lastName}`
                                }
                                onChange={(_, value) => {
                                    setFilters((prevState) => {
                                        const newSearchParams =
                                            new URLSearchParams(prevState);
                                        if (value) {
                                            newSearchParams.set(
                                                "supervisorUuid",
                                                value.uuid
                                            );
                                        } else {
                                            newSearchParams.delete(
                                                "supervisorUuid"
                                            );
                                        }
                                        return newSearchParams;
                                    });
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Filter on supervisor"
                                    />
                                )}
                            />
                        </Grid>
                    )}
                </Grid>
            </Stack>

            <Card>
                <Scrollbar>
                    <DataGrid
                        rows={rows}
                        columns={columns}
                        pageSizeOptions={pageSizeOptions}
                        initialState={initialState}
                        paginationMode="server"
                        paginationModel={paginationModel}
                        onPaginationModelChange={updatePagination}
                        rowCount={missions.count}
                        sortingMode="server"
                        onSortModelChange={updateSort}
                        processRowUpdate={processRowUpdate}
                        onProcessRowUpdateError={handleProcessRowUpdateError}
                        loading={isLagging}
                        autoHeight
                        columnVisibilityModel={visibilityModel}
                        onColumnVisibilityModelChange={(newModel) =>
                            setVisibilityModel(newModel)
                        }
                    />
                </Scrollbar>
            </Card>

            <Dialog open={isAlignmentModalOpen} fullWidth maxWidth="sm">
                <AlignmentModal
                    missionUuid={selectedMission}
                    setIsOpen={setIsAlignmentModalOpen}
                />
            </Dialog>
        </>
    );
}

const pageItemCount = 50;
const pageSizeOptions = [pageItemCount];

const invalidUuid = "invalid uuid";

const initialState = {
    sorting: { sortModel: [{ field: "uploadDate", sort: "desc" }] },
    columns: { columnVisibilityModel: { status: false } },
    pagination: { paginationModel: { page: 0, pageSize: pageSizeOptions[0] } },
};

const sortMap = { acquisitionDate: "date", uploadDate: "createdAt" };
