import {
    Autocomplete,
    Card,
    Grid,
    IconButton,
    Stack,
    TextField,
    Tooltip,
    Typography,
} from "@mui/material";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useIsBasicRole, useUsers } from "../../hooks";
import { useMemo, useState } from "react";

import AddCircleOutlinedIcon from "@mui/icons-material/AddCircleOutlined";
import { BACKEND_ROUTES } from "../../backendRoutes";
import { CONTRACT_STATUS } from "../../constants";
import { ContractTableSkeleton } from "./ContractTableSkeleton";
import { FRONTEND_ROUTES } from "../../frontendRoutes";
import { FetchErrorAlert } from "../../components/FetchErrorAlert";
import Scrollbar from "../../components/Scrollbar";
import TrafficOutlinedIcon from "@mui/icons-material/TrafficOutlined";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { laggy } from "../../utils/SWRLaggy";
import { translateString } from "../utils";
import useSWR from "swr";

const pageItemCount = 100;

export function ContractTable() {
    const navigate = useNavigate();
    const isBasicRole = useIsBasicRole();
    const [searchParams, setSearchParams] = useSearchParams();

    const [page, setPage] = useState(0);
    const [sortState, setSortState] = useState([
        { field: "companyName", sort: "asc" },
    ]);

    const { data: users, error: usersFetchError } = useUsers();
    const { data: companies, error: companiesFetchError } = useSWR(
        `${BACKEND_ROUTES.COMPANY}?sort=name`
    );

    const statusSearchParams = searchParams.get("status"); // will return null if status doesn't exist

    const searchParamsObject = {
        parentInfo: "true",
        limit: pageItemCount,
        offset: page * pageItemCount,
        ...(statusSearchParams && {
            status: statusSearchParams,
        }),
        ...(searchParams.get("companyUuid") && {
            companyUuid: searchParams.get("companyUuid"),
        }),
        ...(searchParams.get("supervisorUuid") && {
            supervisorUuid: searchParams.get("supervisorUuid"),
        }),
        ...(searchParams.get("salespersonUuid") && {
            salespersonUuid: searchParams.get("salespersonUuid"),
        }),
        ...(sortState[0] && {
            sort: `${sortState[0].sort === "desc" ? "-" : ""}${translateString(
                sortState[0].field,
                {
                    supervisor: "Supervisor_firstName",
                    salesperson: "Salesperson_firstName",
                    companyName: "Company_name",
                }
            )}`,
        }),
    };

    const {
        data: contracts,
        error: contractsFetchError,
        isLagging,
    } = useSWR(
        `${BACKEND_ROUTES.CONTRACT}?${new URLSearchParams(searchParamsObject)}`,
        { use: [laggy] }
    );

    const gridColDef = useMemo(
        () => [
            {
                field: "actions",
                headerName: "Actions",
                type: "actions",
                minWidth: 90,
                maxWidth: 90,
                flex: 0.5,
                getActions: (params) => {
                    const actions = [
                        <Tooltip title="View details">
                            <GridActionsCellItem
                                label="View details"
                                icon={<VisibilityIcon color="primary" />}
                                component={Link}
                                to={`/${FRONTEND_ROUTES.ORDERS}/${FRONTEND_ROUTES.CONTRACT}/${params.row.id}`}
                            />
                        </Tooltip>,
                    ];

                    if (!isBasicRole)
                        actions.push(
                            <Tooltip title="Go to Traffic">
                                <GridActionsCellItem
                                    label="Go to Traffic"
                                    icon={
                                        <TrafficOutlinedIcon color="primary" />
                                    }
                                    component={Link}
                                    to={`/${FRONTEND_ROUTES.TRAFFIC}/${FRONTEND_ROUTES.INBOUND_TRAFFIC}?contractUuid=${params.row.id}`}
                                />
                            </Tooltip>
                        );

                    return actions;
                },
            },
            {
                field: "companyName",
                headerName: "Company name",
                filterable: false,
                minWidth: 180,
                flex: 3,
            },
            {
                field: "name",
                headerName: "Campaign name",
                filterable: false,
                minWidth: 250,
                flex: 3,
            },
            {
                field: "status",
                headerName: "Status",
                filterable: false,
                minWidth: 120,
                flex: 2,
            },
            {
                field: "startDate",
                headerName: "Start date",
                filterable: false,
                minWidth: 120,
                flex: 2,
            },
            {
                field: "endDate",
                headerName: "End date",
                filterable: false,
                minWidth: 120,
                flex: 2,
            },
            {
                field: "salesperson",
                headerName: "Project Lead",
                filterable: false,
                minWidth: 180,
                flex: 2,
            },
            {
                field: "supervisor",
                headerName: "Campaign Manager",
                filterable: false,
                minWidth: 180,
                flex: 2,
            },
            {
                field: "reportDate",
                headerName: "Report Date",
                minWidth: 180,
                flex: 2,
                sortable: false,
                filterable: false,
            },
        ],
        [isBasicRole]
    );

    const columnVisibilityModel = useMemo(
        () => ({ supervisor: !isBasicRole, reportDate: !isBasicRole }),
        [isBasicRole]
    );
    const company =
        companies?.rows.find(
            (company) => company.uuid === searchParams.get("companyUuid")
        ) ?? null;
    const supervisor =
        users?.find(
            (user) => user.uuid === searchParams.get("supervisorUuid")
        ) ?? null;
    const salesperson =
        users?.find(
            (user) => user.uuid === searchParams.get("salespersonUuid")
        ) ?? null;

    const mergedFetchError =
        contractsFetchError ?? usersFetchError ?? companiesFetchError;
    if (mergedFetchError && !contracts)
        return <FetchErrorAlert error={mergedFetchError} />;
    if ((!users && !isBasicRole) || !contracts || !companies)
        return <ContractTableSkeleton />;

    const gridRows = contracts.rows.map((contract) => {
        return {
            id: contract.uuid,
            companyName: contract.Company.name,
            name: contract.name,
            status: contract.status,
            startDate: contract.startDate,
            endDate: contract.endDate,
            supervisor: `${contract.Supervisor.firstName} ${contract.Supervisor.lastName}`,
            salesperson: `${contract.Salesperson.firstName} ${contract.Salesperson.lastName}`,
            reportDate: contract.reportDate,
        };
    });

    return (
        <Stack>
            <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                mb={2}
            >
                <Typography variant="h4" gutterBottom>
                    Campaigns
                </Typography>
                <Stack direction="row" spacing={1}>
                    {!isBasicRole && (
                        <IconButton
                            size="large"
                            onClick={() =>
                                navigate(
                                    `/${FRONTEND_ROUTES.ORDERS}/${FRONTEND_ROUTES.CONTRACT_CREATE}`
                                )
                            }
                        >
                            <AddCircleOutlinedIcon
                                fontSize="inherit"
                                color="primary"
                            />
                        </IconButton>
                    )}
                </Stack>
            </Stack>
            <Grid container spacing={1} mb={2}>
                {!isBasicRole && (
                    <Grid item xs={3}>
                        <Autocomplete
                            id="outlined-select-status"
                            value={statusSearchParams}
                            options={Object.values(CONTRACT_STATUS)}
                            fullWidth
                            onChange={(_, value) =>
                                setSearchParams((prevState) => {
                                    const newSearchParams = new URLSearchParams(
                                        prevState
                                    );
                                    if (value) {
                                        newSearchParams.set("status", value);
                                    } else {
                                        newSearchParams.delete("status");
                                    }
                                    return newSearchParams;
                                })
                            }
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Filter by status"
                                />
                            )}
                        />
                    </Grid>
                )}
                <Grid item xs={3}>
                    <Autocomplete
                        id="company"
                        value={company}
                        options={companies.rows}
                        isOptionEqualToValue={(option, value) =>
                            option.uuid === value.uuid
                        }
                        fullWidth
                        getOptionLabel={(option) => option.name}
                        onChange={(_, value) =>
                            setSearchParams((prevState) => {
                                const newSearchParams = new URLSearchParams(
                                    prevState
                                );
                                if (value) {
                                    newSearchParams.set(
                                        "companyUuid",
                                        value.uuid
                                    );
                                } else {
                                    newSearchParams.delete("companyUuid");
                                }
                                return newSearchParams;
                            })
                        }
                        renderInput={(params) => (
                            <TextField {...params} label="Filter on company" />
                        )}
                    />
                </Grid>
                {!isBasicRole && (
                    <>
                        <Grid item xs={3}>
                            <Autocomplete
                                id="supervisor"
                                options={users}
                                value={supervisor}
                                isOptionEqualToValue={(option, value) =>
                                    option.uuid === value.uuid
                                }
                                fullWidth
                                getOptionLabel={(option) =>
                                    `${option.firstName} ${option.lastName}`
                                }
                                onChange={(_, value) => {
                                    setSearchParams((prevState) => {
                                        const newSearchParams =
                                            new URLSearchParams(prevState);
                                        if (value) {
                                            newSearchParams.set(
                                                "supervisorUuid",
                                                value.uuid
                                            );
                                        } else {
                                            newSearchParams.delete(
                                                "supervisorUuid"
                                            );
                                        }
                                        return newSearchParams;
                                    });
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Filter on campaign manager"
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <Autocomplete
                                id="salesperson"
                                options={users}
                                value={salesperson}
                                isOptionEqualToValue={(option, value) =>
                                    option.uuid === value.uuid
                                }
                                fullWidth
                                getOptionLabel={(option) =>
                                    `${option.firstName} ${option.lastName}`
                                }
                                onChange={(_, value) => {
                                    setSearchParams((prevState) => {
                                        const newSearchParams =
                                            new URLSearchParams(prevState);
                                        if (value) {
                                            newSearchParams.set(
                                                "salespersonUuid",
                                                value.uuid
                                            );
                                        } else {
                                            newSearchParams.delete(
                                                "salespersonUuid"
                                            );
                                        }
                                        return newSearchParams;
                                    });
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Filter on project lead"
                                    />
                                )}
                            />
                        </Grid>
                    </>
                )}
            </Grid>
            <Card>
                <Stack spacing={2}>
                    <Scrollbar>
                        <DataGrid
                            autoHeight
                            rows={gridRows}
                            columns={gridColDef}
                            pageSizeOptions={[pageItemCount]}
                            initialState={{ sorting: { sortModel: sortState } }}
                            paginationMode="server"
                            paginationModel={{
                                page,
                                pageSize: pageItemCount,
                            }}
                            onPaginationModelChange={(newPageModel) => {
                                setPage(newPageModel.page);
                            }}
                            rowCount={contracts.count}
                            sortingMode="server"
                            onSortModelChange={setSortState}
                            loading={isLagging}
                            disableColumnSelector={isBasicRole}
                            columnVisibilityModel={columnVisibilityModel}
                        />
                    </Scrollbar>
                </Stack>
            </Card>
        </Stack>
    );
}
