import { Polyline } from "react-leaflet";
import { geoJSONUtil } from "./utils";

export function VectorPolyline({ vector, checkpointGeoJsonData }) {
    const oldFeature = geoJSONUtil.findFeatureById(
        checkpointGeoJsonData,
        vector.shapeObjectId
    );
    const oldCenter = geoJSONUtil.getCenterOfFeature(oldFeature);

    const polyline = [
        [oldCenter[1], oldCenter[0]],
        [oldCenter[1] + vector.latitude, oldCenter[0] + vector.longitude],
    ];

    return (
        <Polyline
            // TODO: find why interactive doesn't work here
            pathOptions={{ color: "silver", interactive: false }}
            positions={polyline}
        />
    );
}
