import { Alert, Button, Stack } from "@mui/material";
import { useFieldArray, useFormContext } from "react-hook-form";

import AddCircleIcon from "@mui/icons-material/AddCircle";
import { DataAllowanceRow } from "./DataAllowanceRow";
import { SYSTEMS } from "../../constants";
import { eachMonthOfInterval } from "date-fns";
import { formatDateISO } from "../../utils/formatTime";
import { useState } from "react";

const systemValues = new Set(Object.values(SYSTEMS));
export const DataAllowanceForm = () => {
    const [expandedDataAllowance, setExpandedDataAllowance] = useState();

    const { append, remove, update, fields } = useFieldArray({
        name: "dataAllowances",
    });

    const { watch } = useFormContext();
    const [startDate, endDate, dataAllowances] = watch([
        "startDate",
        "endDate",
        "dataAllowances",
    ]);
    const dataAllowanceFields = fields.map((field, index) => ({
        ...field,
        ...dataAllowances[index],
    }));

    const hasDateRange = startDate && endDate;
    const dataAllowanceSystems = new Set(
        dataAllowances
            .map((dataAllowance) => dataAllowance.system)
            .filter(Boolean)
    );
    const availableSystems = systemValues.difference(dataAllowanceSystems);
    const hasNonLiteralSystem = dataAllowanceSystems.has(SYSTEMS.LITERAL)
        ? dataAllowanceSystems.size > 1
        : dataAllowanceSystems.size > 0;

    return (
        <Stack spacing={1} sx={{ flex: 1 }}>
            <Stack direction="row" alignItems="center">
                <Button
                    variant="contained"
                    disabled={fields.length === systemValues.size}
                    startIcon={<AddCircleIcon />}
                    onClick={() => {
                        append({
                            system: "",
                            siteCount: null,
                            featureCount: null,
                            acquisitionCount: null,
                            monthlyAcquisitions: eachMonthOfInterval({
                                start: startDate,
                                end: endDate,
                            }).map((month) => ({
                                count: null,
                                date: formatDateISO(month),
                            })),
                        });
                    }}
                    sx={{ flexShrink: 0 }}
                >
                    {fields.length === systemValues.size
                        ? "No system available"
                        : "Data allowance"}
                </Button>
                {!hasDateRange && hasNonLiteralSystem && (
                    <Alert severity="warning" sx={{ ml: "auto", p: 0.5 }}>
                        Set date range to have monthly acquisitions
                    </Alert>
                )}
            </Stack>
            <Stack
                spacing={1}
                sx={{ flexGrow: 1, height: 0, overflow: "auto" }}
            >
                {dataAllowanceFields.map((dataAllowanceField, index) => (
                    <DataAllowanceRow
                        index={index}
                        key={dataAllowanceField.id}
                        remove={remove}
                        update={update}
                        expandedDataAllowance={expandedDataAllowance}
                        setExpandedDataAllowance={setExpandedDataAllowance}
                        availableSystems={[...availableSystems]}
                        dataAllowance={dataAllowanceField}
                    />
                ))}
            </Stack>
        </Stack>
    );
};
