import {
    Box,
    Card,
    CardContent,
    Divider,
    Stack,
    Typography,
} from "@mui/material";
import {
    fovFull,
    realFocalLengthFromFocalLength35,
    roundNumber,
} from "../utils";

import PropTypes from "prop-types";

MissionProtocolSensorData.propTypes = {
    pipelineTemplate: PropTypes.object.isRequired,
};

export function MissionProtocolSensorData({
    isLiteral = false,
    pipelineTemplate,
}) {
    return (
        <Card
            sx={{
                bgcolor: (theme) => theme.palette.grey[210],
            }}
        >
            <CardContent>
                <Stack spacing={2}>
                    <Box>
                        <Typography variant="h6">Data source</Typography>
                        <Divider />
                    </Box>

                    <CropSystemModel pipelineTemplate={pipelineTemplate} />

                    <Divider />

                    {!isLiteral && (
                        <>
                            {pipelineTemplate.AcquisitionVector.SensorBundles
                                .length === 1 ? (
                                <OneSensorData
                                    pipelineTemplate={pipelineTemplate}
                                />
                            ) : (
                                <MultipleSensorsData
                                    pipelineTemplate={pipelineTemplate}
                                />
                            )}
                            {
                                // Converting `null` to number gives 0
                                Number(
                                    pipelineTemplate.AcquisitionVector.minHeight
                                ) > 0 && (
                                    <HeightLimitsData
                                        pipelineTemplate={pipelineTemplate}
                                    />
                                )
                            }
                        </>
                    )}
                </Stack>
            </CardContent>
        </Card>
    );
}

function CropSystemModel({ pipelineTemplate }) {
    return (
        <>
            <Stack direction="row" justifyContent="space-between">
                <Typography>Crop</Typography>

                <Typography>{pipelineTemplate.Crop.name}</Typography>
            </Stack>

            <Stack direction="row" justifyContent="space-between">
                <Typography>System</Typography>

                <Typography>
                    {pipelineTemplate.AcquisitionVector.SystemModel.system}
                </Typography>
            </Stack>

            <Stack direction="row" justifyContent="space-between">
                <Typography>Model</Typography>

                <Typography>
                    {pipelineTemplate.AcquisitionVector.SystemModel.name}
                </Typography>
            </Stack>
        </>
    );
}

function OneSensorData({ pipelineTemplate }) {
    const usedSensor =
        pipelineTemplate.AcquisitionVector.SensorBundles[0].Sensor;
    const realFocalLength = realFocalLengthFromFocalLength35(
        usedSensor.sensorWidth,
        usedSensor.sensorHeight,
        usedSensor.focalLength35mmEqv ?? pipelineTemplate.focalLength35mmEqv
    );
    return (
        <Stack spacing={2}>
            <Stack direction="row" justifyContent="space-between">
                <Typography variant="subtitle2">{usedSensor.name}</Typography>
            </Stack>

            <Stack direction="row" justifyContent="space-between">
                <Typography>CMOS</Typography>

                <Typography>{usedSensor.cmos}</Typography>
            </Stack>

            <Stack direction="row" justifyContent="space-between">
                <Typography>Sensor size (mm)</Typography>

                <Typography>
                    {`${usedSensor.sensorWidth} x ${usedSensor.sensorHeight}`}
                </Typography>
            </Stack>

            <Stack direction="row" justifyContent="space-between">
                <Typography>Picture size (pixels)</Typography>

                <Typography>
                    {`${usedSensor.pixelWidth} x ${usedSensor.pixelHeight}`}
                </Typography>
            </Stack>

            <Stack direction="row" justifyContent="space-between">
                <Typography>Total pixels (MP)</Typography>

                <Typography>{usedSensor.megapixels}</Typography>
            </Stack>

            <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
            >
                <Typography>Focal length eqv 35 (mm)</Typography>

                <Typography>
                    {usedSensor.focalLength35mmEqv ??
                        pipelineTemplate.focalLength35mmEqv}
                </Typography>
            </Stack>
            <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
            >
                <Typography>Real focal length (mm)</Typography>

                <Typography>{roundNumber(realFocalLength, 1)}</Typography>
            </Stack>

            <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
            >
                <Typography>FOV (°)</Typography>

                <Typography>
                    {roundNumber(
                        fovFull(
                            realFocalLength,
                            usedSensor.sensorWidth,
                            usedSensor.sensorHeight
                        ),
                        1
                    )}
                </Typography>
            </Stack>

            <Divider />
        </Stack>
    );
}

function MultipleSensorsData({ pipelineTemplate }) {
    return pipelineTemplate.AcquisitionVector.SensorBundles.map((bundle) => (
        <Stack key={bundle.uuid} spacing={2}>
            <Stack direction="row" justifyContent="space-between">
                <Typography variant="subtitle2">
                    {bundle.Sensor.name}
                </Typography>
            </Stack>

            <Stack direction="row" justifyContent="space-between">
                <Typography>Data Type</Typography>

                <Typography>{bundle.Sensor.dataType}</Typography>
            </Stack>

            <Stack direction="row" justifyContent="space-between">
                <Typography>Picture size (pixels)</Typography>

                <Typography>
                    {`${bundle.Sensor.pixelWidth} x ${bundle.Sensor.pixelHeight}`}
                </Typography>
            </Stack>

            <Divider />
        </Stack>
    ));
}

function HeightLimitsData({ pipelineTemplate }) {
    return (
        <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
        >
            <Typography>Altitude limits (m)</Typography>

            <Typography>
                {`From ${pipelineTemplate.AcquisitionVector.minHeight} to ${pipelineTemplate.AcquisitionVector.maxHeight}`}
            </Typography>
        </Stack>
    );
}
