import {
    Autocomplete,
    Box,
    Button,
    Skeleton,
    Stack,
    TextField,
    ToggleButton,
    ToggleButtonGroup,
    Tooltip,
    Typography,
} from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import {
    DRAWER_WIDTH,
    GEOMAN_CUSTOM_TOGGLE_BUTTON_NAMES,
    TRANSFORM_RANGE_ERROR,
    UNKNOWN_TRANSFORM_ERROR,
} from "./constants";
import {
    LEAFLET_OVERLAYED_BUTTONS_ZINDEX,
    MUI_DEFAULT_SIZES,
} from "../../constants";
import {
    applyTransform,
    deleteLayers,
    readGeoJsonPlotFile,
    readMetadata,
    removeToolId,
} from "./utils";
import { useGetMapTiles, useLiteralSite } from "./hooks";

import { APPBAR_HEIGHT } from "../../layouts/orders/constants";
import AccountPopover from "../../layouts/orders/AccountPopover";
import ControlCameraIcon from "@mui/icons-material/ControlCamera";
import CropSquareIcon from "@mui/icons-material/CropSquare";
import { FetchErrorAlert } from "../../components/FetchErrorAlert";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import L from "leaflet";
import LockIcon from "@mui/icons-material/Lock";
import Logo from "../../components/Logo";
import LooksOneIcon from "@mui/icons-material/LooksOne";
import { PARCELLAIRE_REDUCER_ACTIONS } from "./parcellaireReducer";
import { TileLayerControl } from "./TileLayerControl";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useCallback } from "react";
import { useSnackbar } from "../../hooks";

export const ParcellaireHeader = ({
    blueRectangleData,
    displayToggles,
    isLiteral,
    map,
    orthoimageName,
    openDrawer,
    parcellaireDispatch,
    parcellaireState,
    plotColor,
    transformationToggles,
    selectableMetadata,
    selectedTileLayer,
    setBlueRectangleData,
    setFirstToggles,
    setGeoJsonKey,
    setOpenExportForm,
    setOrthoimageName,
    setPlotColor,
    setSecondToggles,
    setSelectableMetadata,
    setSelectedTileLayer,
}) => {
    const { openSnackbar } = useSnackbar();
    const getMapTiles = useGetMapTiles(
        map,
        parcellaireState,
        selectedTileLayer
    );

    const { setValue } = useFormContext();

    const { data: site, error: siteError } = useLiteralSite();

    const handleFirstToggleGroupChange = useCallback(
        (_, values) => {
            try {
                setFirstToggles(values);
            } catch (error) {
                // TODO: error boundary https://hiphen.atlassian.net/browse/HCC-822
                openSnackbar(
                    error instanceof RangeError
                        ? TRANSFORM_RANGE_ERROR
                        : UNKNOWN_TRANSFORM_ERROR,
                    "error"
                );
            }
            setGeoJsonKey(new Date().getTime());
        },
        [openSnackbar, setFirstToggles, setGeoJsonKey]
    );

    const handleSecondToggleGroupChange = useCallback(
        (_, values) => {
            try {
                const transform = applyTransform(parcellaireState, values);
                setSecondToggles(values);
                parcellaireDispatch({
                    type: PARCELLAIRE_REDUCER_ACTIONS.PM_EDIT,
                    transform,
                });
            } catch (error) {
                // TODO: error boundary https://hiphen.atlassian.net/browse/HCC-822
                openSnackbar(
                    error instanceof RangeError
                        ? TRANSFORM_RANGE_ERROR
                        : UNKNOWN_TRANSFORM_ERROR,
                    "error"
                );
            }
            setGeoJsonKey(new Date().getTime());
        },
        [
            openSnackbar,
            parcellaireDispatch,
            parcellaireState,
            setGeoJsonKey,
            setSecondToggles,
        ]
    );

    if (siteError) return <FetchErrorAlert error={siteError} />;

    if (isLiteral && !site) {
        return (
            <Skeleton
                animation="wave"
                variant="rectangular"
                width="100%"
                height="100vh"
            />
        );
    }

    const onGeoJsonUpload = (event) => {
        const geoJsonFile = event.target.files[0];

        if (geoJsonFile) {
            if (parcellaireState.initialGeoJsonData) {
                parcellaireDispatch({
                    type: PARCELLAIRE_REDUCER_ACTIONS.CLEAR_ALL_GEOJSON,
                });
            }

            readGeoJsonPlotFile(
                geoJsonFile,
                parcellaireDispatch,
                map,
                openSnackbar,
                setSelectableMetadata,
                site
            );

            // removing coord marker
            deleteLayers(map, (layer) => layer instanceof L.Marker);

            setBlueRectangleData(null);

            setValue("selectedMetadata", null);
        }
    };

    return (
        <Box
            sx={{ minHeight: APPBAR_HEIGHT, px: 3 }}
            display="flex"
            alignItems="center"
        >
            <Stack
                direction="row"
                alignItems="center"
                spacing={1}
                sx={{ width: 1 }}
            >
                <Logo
                    sx={{
                        height: MUI_DEFAULT_SIZES.BUTTON.MEDIUM,
                        width: (MUI_DEFAULT_SIZES.BUTTON.MEDIUM * 5) / 3,
                    }}
                />

                <Button
                    variant="contained"
                    component="label"
                    startIcon={<FileUploadIcon />}
                    sx={{ textTransform: "none" }}
                >
                    Geojson plot file
                    <input
                        hidden
                        type="file"
                        accept=".geojson"
                        onChange={onGeoJsonUpload}
                    />
                </Button>
                {!isLiteral && (
                    <>
                        <Button
                            variant="contained"
                            component="label"
                            startIcon={<FileUploadIcon />}
                            sx={{ textTransform: "none" }}
                        >
                            Json session-metadata
                            <input
                                hidden
                                type="file"
                                accept=".json"
                                onChange={(event) => {
                                    readMetadata(
                                        event,
                                        parcellaireDispatch,
                                        setGeoJsonKey
                                    );
                                }}
                            />
                        </Button>
                        <TextField
                            label="Name of the orthoimage"
                            value={orthoimageName}
                            onChange={(event) => {
                                setOrthoimageName(event.target.value);
                            }}
                        />
                    </>
                )}
                <TextField
                    label="Plot color"
                    id="microplotColorPicker"
                    type="color"
                    sx={{ width: "120px" }}
                    value={plotColor}
                    onChange={(e) => {
                        setPlotColor(e.target.value);
                        setGeoJsonKey(new Date().getTime());
                    }}
                />
                {isLiteral ? (
                    <Button
                        variant="contained"
                        component="label"
                        startIcon={<FileDownloadIcon />}
                        color="info"
                        sx={{ textTransform: "none" }}
                        onClick={() => {
                            setOpenExportForm(true);
                        }}
                        disabled={!parcellaireState.transformedGeoJson}
                    >
                        Export plot map
                    </Button>
                ) : (
                    <Button
                        variant="contained"
                        component="a"
                        startIcon={<FileDownloadIcon />}
                        color="info"
                        sx={{ textTransform: "none" }}
                        href={`data:text/json;charset=utf-8,${encodeURIComponent(
                            JSON.stringify(
                                removeToolId(
                                    parcellaireState.transformedGeoJson
                                )
                            )
                        )}`}
                        download="plotmap.geojson"
                        disabled={!parcellaireState.transformedGeoJson}
                    >
                        Export plot map
                    </Button>
                )}
                {isLiteral && (
                    <Button
                        variant="contained"
                        component="label"
                        disabled={!parcellaireState.initialGeoJsonData}
                        startIcon={<FileDownloadIcon />}
                        color="info"
                        sx={{ textTransform: "none" }}
                        onClick={getMapTiles}
                    >
                        Export map tiles
                    </Button>
                )}
                {Boolean(selectableMetadata?.length) && (
                    <Controller
                        name="selectedMetadata"
                        render={({
                            field: { ref, onChange, name, ...fieldProps },
                        }) => (
                            <Autocomplete
                                {...fieldProps}
                                id="selectedMetadata"
                                options={selectableMetadata}
                                sx={{ width: "200px" }}
                                isOptionEqualToValue={(option, value) =>
                                    option.id === value.id
                                }
                                getOptionLabel={(option) => option.label}
                                onChange={(_, value) => {
                                    onChange(value);
                                    setGeoJsonKey(new Date().getTime());
                                }}
                                componentsProps={{
                                    popper: {
                                        style: { width: "fit-content" },
                                    },
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        inputRef={ref}
                                        name={name}
                                        fullWidth
                                        label="Displayed metadata"
                                    />
                                )}
                            />
                        )}
                    />
                )}
                {parcellaireState.transformedGeoJson && (
                    <Stack>
                        <ToggleButtonGroup
                            color="secondary"
                            orientation="vertical"
                            value={displayToggles}
                            onChange={handleFirstToggleGroupChange}
                            sx={{
                                bgcolor: "white",
                                position: "absolute",
                                bottom: 140,
                                left: 10,
                                zIndex: LEAFLET_OVERLAYED_BUTTONS_ZINDEX,
                            }}
                        >
                            {blueRectangleData && (
                                <ToggleButton
                                    value={
                                        GEOMAN_CUSTOM_TOGGLE_BUTTON_NAMES.DISPLAY_BLUE_MODE
                                    }
                                    size="small"
                                    color="primary"
                                >
                                    <Tooltip
                                        placement="right"
                                        title={
                                            <Typography>
                                                Display field map
                                            </Typography>
                                        }
                                    >
                                        <CropSquareIcon />
                                    </Tooltip>
                                </ToggleButton>
                            )}
                            <ToggleButton
                                value={
                                    GEOMAN_CUSTOM_TOGGLE_BUTTON_NAMES.HIDDEN_PLOT_MODE
                                }
                                size="small"
                                color="primary"
                            >
                                <Tooltip
                                    placement="right"
                                    title={
                                        <Typography>Hide all plots</Typography>
                                    }
                                >
                                    <VisibilityOffIcon />
                                </Tooltip>
                            </ToggleButton>
                        </ToggleButtonGroup>
                        <ToggleButtonGroup
                            color="secondary"
                            orientation="vertical"
                            value={transformationToggles}
                            onChange={handleSecondToggleGroupChange}
                            sx={{
                                bgcolor: "white",
                                position: "absolute",
                                bottom: 10,
                                left: 10,
                                zIndex: LEAFLET_OVERLAYED_BUTTONS_ZINDEX,
                            }}
                        >
                            <ToggleButton
                                value={
                                    GEOMAN_CUSTOM_TOGGLE_BUTTON_NAMES.SINGLE_TARGET_MODE
                                }
                                size="small"
                                color="primary"
                            >
                                <Tooltip
                                    placement="right"
                                    title={
                                        <>
                                            <Typography>
                                                Apply only single translations
                                            </Typography>
                                            <Typography>
                                                (Overrides other modes)
                                            </Typography>
                                        </>
                                    }
                                >
                                    <LooksOneIcon />
                                </Tooltip>
                            </ToggleButton>
                            <ToggleButton
                                value={
                                    GEOMAN_CUSTOM_TOGGLE_BUTTON_NAMES.SHIFT_MODE
                                }
                                size="small"
                                color="primary"
                            >
                                <Tooltip
                                    placement="right"
                                    title={
                                        <Typography>
                                            Apply translation starting from the
                                            1st vector
                                        </Typography>
                                    }
                                >
                                    <ControlCameraIcon />
                                </Tooltip>
                            </ToggleButton>
                            <ToggleButton
                                value={
                                    GEOMAN_CUSTOM_TOGGLE_BUTTON_NAMES.LOCKED_SHAPE_MODE
                                }
                                size="small"
                                color="primary"
                            >
                                <Tooltip
                                    placement="right"
                                    title={
                                        <Typography>
                                            Do not change plot shape
                                        </Typography>
                                    }
                                >
                                    <LockIcon />
                                </Tooltip>
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </Stack>
                )}
                <Box
                    sx={(theme) => ({
                        backgroundColor: "silver",
                        position: "absolute",
                        p: 0.75,
                        borderRadius: 1,
                        bottom: 10,
                        transition: theme.transitions.create(
                            "right",
                            openDrawer
                                ? {
                                      duration:
                                          theme.transitions.duration
                                              .leavingScreen,
                                      easing: theme.transitions.easing.easeOut,
                                  }
                                : {
                                      duration:
                                          theme.transitions.duration
                                              .enteringScreen,
                                      easing: theme.transitions.easing.sharp,
                                  }
                        ),
                        right: openDrawer ? 10 + DRAWER_WIDTH : 10,
                        zIndex: "drawer",
                    })}
                >
                    <TileLayerControl
                        selectedTileLayer={selectedTileLayer}
                        setSelectedTileLayer={setSelectedTileLayer}
                    />
                </Box>
                <Box sx={{ flexGrow: 1 }} />
                <AccountPopover />
            </Stack>
        </Box>
    );
};
