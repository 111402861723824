import { BACKEND_ROUTES } from "../../../backendRoutes";
import { useParams } from "react-router-dom";
import useSWR from "swr";

export const useLiteralSite = () => {
    const { siteUuid } = useParams();

    return useSWR(
        siteUuid ? `${BACKEND_ROUTES.SITE}/${siteUuid}?parentInfo=true` : null
    );
};
