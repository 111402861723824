import { Divider, Grid } from "@mui/material";

import { ExperimentTable } from "../experiment";
import { MissionTable } from "../mission";
import { SiteEditForm } from "./SiteEditForm";

export const SiteDetails = () => (
    <Grid container spacing={2}>
        <Grid item xs={12}>
            <SiteEditForm />
        </Grid>
        <Grid item xs={12}>
            <Divider />
        </Grid>
        <Grid item xs={12}>
            <ExperimentTable />
        </Grid>
        <Grid item xs={12}>
            <Divider />
        </Grid>
        <Grid item xs={12}>
            <MissionTable />
        </Grid>
    </Grid>
);
