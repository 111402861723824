import {
    Button,
    Collapse,
    Dialog,
    DialogActions,
    Divider,
    Grid,
    IconButton,
    InputAdornment,
    Stack,
    Tab,
    Tabs,
    TextField,
    ToggleButton,
    Tooltip,
    Typography,
} from "@mui/material";
import { deleteLayers, readCSVToArray } from "./utils";
import { useFormContext, useWatch } from "react-hook-form";

import AddCircleIcon from "@mui/icons-material/AddCircle";
import { COORDINATE_DECIMAL_COUNT } from "./constants";
import DoneIcon from "@mui/icons-material/Done";
import { FieldBookTutorial } from "./FieldBookTutorial";
import { INPUT_VALIDATION } from "../../constants";
import L from "leaflet";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { PlotGridForm } from "./PlotGridForm";
import PropTypes from "prop-types";
import SearchIcon from "@mui/icons-material/Search";
import { TabPanel } from "../../components";
import { parcellaireCreationSubmitImport } from "./parcellaireCreationSubmitImport";
import { parcellaireCreationSubmitNoImport } from "./parcellaireCreationSubmitNoImport";
import { useLiteralSite } from "./hooks";
import useSWRMutation from "swr/mutation";
import { useSnackbar } from "../../hooks";
import { useState } from "react";

ParcellaireCreationFormNoImport.propTypes = {
    coordMarkerMode: PropTypes.bool.isRequired,
    isLiteral: PropTypes.bool,
    map: PropTypes.any,
    parcellaireDispatch: PropTypes.func.isRequired,
    setBlueRectangleData: PropTypes.func.isRequired,
    setCoordMarkerMode: PropTypes.func.isRequired,
    setGeoJsonKey: PropTypes.func.isRequired,
    setIsOpen: PropTypes.func,
    setSelectableMetadata: PropTypes.func.isRequired,
};

export function ParcellaireCreationFormNoImport({
    coordMarkerMode,
    isLiteral = false,
    map,
    parcellaireDispatch,
    setBlueRectangleData,
    setCoordMarkerMode,
    setGeoJsonKey,
    setIsOpen,
    setSelectableMetadata,
}) {
    const { openSnackbar } = useSnackbar();

    const [tabValue, setTabValue] = useState(0);
    const [location, setLocation] = useState("");
    const [isFieldBookTutorialOpen, setIsFieldBookTutorialOpen] =
        useState(false);

    const { data: site } = useLiteralSite();
    const { data: locations, trigger } = useSWRMutation(
        `https://nominatim.openstreetmap.org/search?format=jsonv2&accept-language=fr-FR&q=${encodeURI(location)}`
    );

    const {
        setValue,
        register,
        handleSubmit,
        resetField,
        formState: { errors },
    } = useFormContext();
    const [csvData, latitude, longitude] = useWatch({
        name: ["csvData", "latitude", "longitude"],
    });

    const hasCoordinates =
        latitude != null &&
        !isNaN(latitude) &&
        longitude != null &&
        !isNaN(longitude);

    const handleClick = async () => {
        try {
            const data = await trigger();

            if (data?.length) {
                const { boundingbox } = data[0];
                map.fitBounds([
                    [boundingbox[0], boundingbox[2]],
                    [boundingbox[1], boundingbox[3]],
                ]);
            }
        } catch {
            openSnackbar(
                "An error occurred while searching locations. Please try again in a few minutes.",
                "error"
            );
        }
    };

    const onSubmit = (payload) => {
        if (tabValue && isLiteral) {
            parcellaireCreationSubmitImport(
                payload,
                setIsOpen,
                parcellaireDispatch,
                map,
                setGeoJsonKey,
                openSnackbar,
                isLiteral,
                site,
                setSelectableMetadata
            );
        } else {
            parcellaireCreationSubmitNoImport(
                payload,
                setIsOpen,
                site,
                parcellaireDispatch,
                map,
                setGeoJsonKey,
                setSelectableMetadata
            );
        }

        resetField("selectedMetadata");

        setBlueRectangleData(null);
    };

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            label="Search location"
                            value={location}
                            onKeyDown={(event) => {
                                if (event.key === "Enter") {
                                    event.preventDefault();
                                    handleClick();
                                }
                            }}
                            onChange={(event) =>
                                setLocation(event.target.value)
                            }
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={handleClick}
                                            edge="end"
                                        >
                                            <SearchIcon />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            FormHelperTextProps={{
                                sx: { color: "warning.main" },
                            }}
                            helperText={
                                locations &&
                                !locations.length &&
                                "No location found"
                            }
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography>Initial coordinates</Typography>
                        <Divider />
                    </Grid>

                    <Grid item xs={2}>
                        <ToggleButton
                            color="primary"
                            value="check"
                            size="small"
                            selected={coordMarkerMode}
                            onChange={() => {
                                if (coordMarkerMode) {
                                    map.pm.enableGlobalDragMode();
                                } else {
                                    deleteLayers(
                                        map,
                                        (layer) =>
                                            layer instanceof L.Marker &&
                                            layer.options.customType !==
                                                "idLabel"
                                    );
                                    map.pm.enableDraw("Marker");
                                }
                                setCoordMarkerMode(!coordMarkerMode);
                            }}
                        >
                            <Tooltip
                                placement="left"
                                title={
                                    <Typography>
                                        Place coordinates marker on map
                                    </Typography>
                                }
                            >
                                <LocationOnIcon />
                            </Tooltip>
                        </ToggleButton>
                    </Grid>
                    <Grid item xs={5}>
                        <TextField
                            id="latitude"
                            type="number"
                            label="Latitude"
                            error={Boolean(errors.latitude)}
                            helperText={errors.latitude?.message}
                            inputProps={coordinatesInputProps}
                            {...register("latitude", {
                                required: INPUT_VALIDATION.REQUIRED,
                                validate: (value) =>
                                    (value >= -90 && value <= 90) ||
                                    INPUT_VALIDATION.LATITUDE_INVALID,
                                valueAsNumber: true,
                            })}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Grid>
                    <Grid item xs={5}>
                        <TextField
                            id="longitude"
                            type="number"
                            label="Longitude"
                            error={Boolean(errors.longitude)}
                            helperText={errors.longitude?.message}
                            inputProps={coordinatesInputProps}
                            {...register("longitude", {
                                required: INPUT_VALIDATION.REQUIRED,
                                validate: (value) =>
                                    (value >= -180 && value <= 180) ||
                                    INPUT_VALIDATION.LONGITUDE_INVALID,
                                valueAsNumber: true,
                            })}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Collapse in={hasCoordinates}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography>Plot geometry</Typography>
                                    <Divider />
                                </Grid>
                                {isLiteral ? (
                                    <>
                                        <Grid
                                            item
                                            xs={12}
                                            sx={{
                                                borderBottom: 1,
                                                borderColor: "divider",
                                            }}
                                        >
                                            <Tabs
                                                value={tabValue}
                                                onChange={(_, newValue) =>
                                                    setTabValue(newValue)
                                                }
                                                sx={{ flexGrow: 1 }}
                                            >
                                                <Tab label="Regular grid" />
                                                <Tab label="From field book" />
                                            </Tabs>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TabPanel
                                                value={tabValue}
                                                index={0}
                                                boxStyle={{ p: 0 }}
                                            >
                                                <PlotGridForm />
                                            </TabPanel>
                                            <TabPanel
                                                value={tabValue}
                                                index={1}
                                                boxStyle={{ p: 0 }}
                                            >
                                                <Stack
                                                    direction="row"
                                                    spacing={2}
                                                    justifyContent="center"
                                                >
                                                    <Button
                                                        variant="contained"
                                                        component="label"
                                                        sx={{
                                                            textTransform:
                                                                "none",
                                                        }}
                                                    >
                                                        Import field book
                                                        <input
                                                            hidden
                                                            type="file"
                                                            accept=".csv"
                                                            onChange={(
                                                                event
                                                            ) => {
                                                                const csvFile =
                                                                    event.target
                                                                        .files[0];

                                                                if (csvFile) {
                                                                    readCSVToArray(
                                                                        csvFile,
                                                                        setValue
                                                                    );
                                                                }
                                                            }}
                                                        />
                                                        {csvData && (
                                                            <DoneIcon />
                                                        )}
                                                    </Button>
                                                    <Button
                                                        variant="contained"
                                                        component="label"
                                                        sx={{
                                                            textTransform:
                                                                "none",
                                                        }}
                                                        onClick={() =>
                                                            setIsFieldBookTutorialOpen(
                                                                true
                                                            )
                                                        }
                                                    >
                                                        Tutorial
                                                    </Button>
                                                </Stack>
                                            </TabPanel>
                                        </Grid>
                                    </>
                                ) : (
                                    <Grid item xs={12}>
                                        <PlotGridForm />
                                    </Grid>
                                )}
                                <Grid item xs={6}>
                                    <TextField
                                        type="number"
                                        label="X size"
                                        id="xSize"
                                        error={Boolean(errors.xSize)}
                                        helperText={errors.xSize?.message}
                                        {...register("xSize", {
                                            required: INPUT_VALIDATION.REQUIRED,
                                            valueAsNumber: true,
                                            validate: (value) =>
                                                value > 0 ||
                                                INPUT_VALIDATION.POSITIVE,
                                        })}
                                        inputProps={{
                                            step: "0.01",
                                            inputMode: "decimal",
                                        }}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    m
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        type="number"
                                        label="Y size"
                                        id="ySize"
                                        error={Boolean(errors.ySize)}
                                        helperText={errors.ySize?.message}
                                        {...register("ySize", {
                                            required: INPUT_VALIDATION.REQUIRED,
                                            valueAsNumber: true,
                                            validate: (value) =>
                                                value > 0 ||
                                                INPUT_VALIDATION.POSITIVE,
                                        })}
                                        inputProps={{
                                            step: "0.01",
                                            inputMode: "decimal",
                                        }}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    m
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        type="number"
                                        label="X spacing"
                                        id="xSpacing"
                                        error={Boolean(errors.xSpacing)}
                                        helperText={errors.xSpacing?.message}
                                        {...register("xSpacing", {
                                            required: INPUT_VALIDATION.REQUIRED,
                                            valueAsNumber: true,
                                            validate: (value) =>
                                                value >= 0 ||
                                                INPUT_VALIDATION.NON_NEGATIVE,
                                        })}
                                        inputProps={{
                                            step: "0.01",
                                            inputMode: "decimal",
                                        }}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    m
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        type="number"
                                        label="Y spacing"
                                        id="ySpacing"
                                        error={Boolean(errors.ySpacing)}
                                        helperText={errors.ySpacing?.message}
                                        {...register("ySpacing", {
                                            required: INPUT_VALIDATION.REQUIRED,
                                            valueAsNumber: true,
                                            validate: (value) =>
                                                value >= 0 ||
                                                INPUT_VALIDATION.NON_NEGATIVE,
                                        })}
                                        inputProps={{
                                            step: "0.01",
                                            inputMode: "decimal",
                                        }}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    m
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        type="number"
                                        label="Rotation angle"
                                        id="angle"
                                        error={Boolean(errors.angle)}
                                        helperText={errors.angle?.message}
                                        {...register("angle", {
                                            valueAsNumber: true,
                                            validate: (value) =>
                                                (value >= 0 && value <= 360) ||
                                                INPUT_VALIDATION.DEGREE_INVALID,
                                        })}
                                        inputProps={{
                                            step: "0.1",
                                            inputMode: "decimal",
                                        }}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    °
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </Collapse>
                    </Grid>
                </Grid>
                <DialogActions>
                    <Button
                        variant="contained"
                        startIcon={<AddCircleIcon />}
                        type="submit"
                        disabled={!hasCoordinates}
                    >
                        Create
                    </Button>
                </DialogActions>
            </form>
            {isLiteral && (
                <Dialog open={isFieldBookTutorialOpen} fullWidth maxWidth="md">
                    <FieldBookTutorial
                        closeModal={() => setIsFieldBookTutorialOpen(false)}
                    />
                </Dialog>
            )}
        </>
    );
}

const coordinatesInputProps = {
    inputMode: "decimal",
    step: Math.pow(10, COORDINATE_DECIMAL_COUNT * -1),
};
