import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
} from "@mui/material";
import {
    useAcquisitionVectors,
    useBBCHStages,
    useCrops,
    usePresets,
    useSystemModels,
    useTraitGroupDataTypes,
} from "../../../hooks";

import { BACKEND_ROUTES } from "../../../backendRoutes";
import { FetchErrorAlert } from "../../../components/FetchErrorAlert";
import { PipelineTemplateForm } from "./PipelineTemplateForm";
import { PipelineTemplateFormSkeleton } from "./PipelineTemplateFormSkeleton";
import PropTypes from "prop-types";
import useSWR from "swr";
import { useState } from "react";

PipelineTemplateDialog.propTypes = {
    uuid: PropTypes.string,
    isCreation: PropTypes.bool.isRequired,
    contractUuid: PropTypes.string.isRequired,
    closeModal: PropTypes.func.isRequired,
    mutateTable: PropTypes.func.isRequired,
};

/**
 * If isCreation true and no uuid: we are creating a new pipeline template.
 * If isCreation true and uuid provided: we are cloning an existing pipeline template.
 * If isCreation false: uuid must be provided and we are modifying an existing pipeline template.
 */
export function PipelineTemplateDialog({
    uuid,
    isCreation,
    contractUuid,
    closeModal,
    mutateTable,
}) {
    // If isCreation false, uuid must be provided
    if (!isCreation && !uuid)
        throw new TypeError("uuid must be provided if isCreation is false");

    const [isOpenErrorDialog, setIsOpenErrorDialog] = useState(false);

    const {
        data: pipelineTemplate,
        error: pipelineTemplateError,
        isLoading: isPipelineTemplateLoading,
        mutate,
    } = useSWR(
        uuid
            ? `${BACKEND_ROUTES.PIPELINE_TEMPLATE}/${uuid}?parentInfo=true&traitGroups=true&flightSequences=true`
            : null
    );
    const {
        data: contract,
        error: contractError,
        isLoading: isContractLoading,
    } = useSWR(`${BACKEND_ROUTES.CONTRACT}/${contractUuid}?parentInfo=true`);
    const { error: cropsError, isLoading: areCropsLoading } = useCrops();
    const { error: systemModelsError, isLoading: areSystemModelsLoading } =
        useSystemModels();
    const {
        error: acquisitionVectorsError,
        isLoading: areAcquisitionVectorsLoading,
    } = useAcquisitionVectors();
    const {
        error: traitGroupDataTypesError,
        isLoading: areTraitGroupDataTypesLoading,
    } = useTraitGroupDataTypes();
    const { error: bbchStagesError, isLoading: areBBCHStagesLoading } =
        useBBCHStages();
    const { error: presetsError, isLoading: arePresetsLoading } = usePresets();

    const mergedFetchError =
        contractError ??
        cropsError ??
        systemModelsError ??
        acquisitionVectorsError ??
        pipelineTemplateError ??
        traitGroupDataTypesError ??
        bbchStagesError ??
        presetsError;

    if (mergedFetchError) return <FetchErrorAlert error={mergedFetchError} />;
    if (
        isContractLoading ||
        areCropsLoading ||
        areSystemModelsLoading ||
        areAcquisitionVectorsLoading ||
        isPipelineTemplateLoading ||
        areTraitGroupDataTypesLoading ||
        areBBCHStagesLoading ||
        arePresetsLoading
    )
        return <PipelineTemplateFormSkeleton />;

    const onClickErrorDialog = () => {
        setIsOpenErrorDialog(false);
    };

    return (
        <>
            <DialogTitle>
                {isCreation
                    ? "Create a new pipeline template"
                    : "Edit the pipeline template"}
            </DialogTitle>
            <Divider variant="middle" />
            <PipelineTemplateForm
                closeModal={closeModal}
                contract={contract}
                isCreation={isCreation}
                mutate={mutate}
                mutateTable={mutateTable}
                pipelineTemplate={pipelineTemplate}
                setIsOpenErrorDialog={setIsOpenErrorDialog}
            />
            <Dialog open={isOpenErrorDialog}>
                <DialogTitle>Error</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Any pipeline template must contain at least one trait
                        group with at least one BBCH stage selected.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        type="button"
                        variant="contained"
                        onClick={onClickErrorDialog}
                    >
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
