import { Box, Card, Stack, Typography } from "@mui/material";

import { BACKEND_ROUTES } from "../../backendRoutes";
import { DataGrid } from "@mui/x-data-grid";
import { ExperimentTableSkeleton } from "./ExperimentTableSkeleton";
import { FetchErrorAlert } from "../../components/FetchErrorAlert";
import { getDataGridHeight } from "../utils";
import { useMemo } from "react";
import { useParams } from "react-router-dom";
import useSWR from "swr";

export function ExperimentTable() {
    const { siteUuid } = useParams();

    const {
        data: experiments,
        error: experimentsError,
        isLoading: areExperimentsLoading,
    } = useSWR(
        `${BACKEND_ROUTES.EXPERIMENT}?parentInfo=true&siteUuid=${siteUuid}`
    );

    const rows = useMemo(
        () =>
            experiments?.rows.map((experiment) => ({
                id: experiment.uuid,
                uuid: experiment.uuid,
                name: experiment.name,
                crop: experiment.Crop.name,
                cropUuid: experiment.cropUuid,
            })),
        [experiments]
    );

    if (experimentsError) return <FetchErrorAlert error={experimentsError} />;
    if (areExperimentsLoading) return <ExperimentTableSkeleton />;

    return (
        <>
            <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                mb={2}
            >
                <Typography variant="h6" gutterBottom>
                    Experiments
                </Typography>
            </Stack>

            <Card>
                <Box
                    style={{
                        height: getDataGridHeight(400, experiments.rows.length),
                        width: "100%",
                    }}
                >
                    <DataGrid
                        rows={rows}
                        columns={columns}
                        pageSizeOptions={pageSizeOptions}
                        rowCount={experiments.count}
                        initialState={intialState}
                    />
                </Box>
            </Card>
        </>
    );
}

const columns = [
    { field: "name", headerName: "Experiment", minWidth: 300, flex: 3 },
    { field: "crop", headerName: "Crop", minWidth: 300, flex: 2 },
];

const intialState = {
    sorting: { sortModel: [{ field: "name", sort: "asc" }] },
};

const pageSizeOptions = [100];
