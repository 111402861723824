import "@geoman-io/leaflet-geoman-free";
import "@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css";
import "./leafletCustom.css";

import { COORDINATE_DECIMAL_COUNT } from "../constants";
import L from "leaflet";
import { PARCELLAIRE_REDUCER_ACTIONS } from "../parcellaireReducer";
import { deleteLayers } from "../utils";
import { roundNumber } from "../../utils/roundNumber";
import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useMap } from "react-leaflet";

export const useGeomanConfig = ({
    parcellaireDispatch,
    setCoordMarkerMode,
    setGeoJsonKey,
}) => {
    const map = useMap();
    const { reset, setValue } = useFormContext();

    useEffect(() => {
        map.pm.Toolbar.copyDrawControl("Polygon", {
            name: POLYGON_SELECT_NAME,
            block: "draw",
            title: "Select (polygon)",
            afterClick: (_, { button }) => {
                map.pm.Draw[POLYGON_SELECT_NAME].toggle();

                if (!button.toggled()) {
                    map.pm.enableGlobalDragMode();
                }
            },
            actions: [],
        });

        map.pm.Toolbar.createCustomControl({
            name: "polygonDelete",
            block: "draw",
            title: "Delete selection",
            className: "leaflet-pm-icon-trash",
            onClick: () => {
                deleteLayers(
                    map,
                    (layer) => layer.options.customType === "idLabel"
                );
                parcellaireDispatch({
                    type: PARCELLAIRE_REDUCER_ACTIONS.DELETE,
                });
                setGeoJsonKey(new Date().getTime());
            },
            afterClick: () => {
                map.pm.enableGlobalDragMode();
            },
            toggle: false,
        });

        map.pm.Toolbar.createCustomControl({
            name: "polygonLock",
            block: "draw",
            title: "Lock selection",
            className: "leaflet-pm-icon-lock",
            onClick: () => {
                parcellaireDispatch({
                    type: PARCELLAIRE_REDUCER_ACTIONS.LOCK,
                });
                setGeoJsonKey(new Date().getTime());
            },
            afterClick: () => {
                map.pm.enableGlobalDragMode();
            },
            toggle: false,
        });

        map.pm.Toolbar.createCustomControl({
            name: "polygonUnlock",
            block: "draw",
            title: "Unlock all",
            className: "leaflet-pm-icon-unlock",
            onClick: () => {
                parcellaireDispatch({
                    type: PARCELLAIRE_REDUCER_ACTIONS.UNLOCK,
                });
                setGeoJsonKey(new Date().getTime());
            },
            afterClick: () => {
                map.pm.enableGlobalDragMode();
            },
            toggle: false,
        });

        map.pm.Toolbar.createCustomControl({
            name: "unselect",
            block: "edit",
            title: "Save checkpoint & Unselect",
            className: "leaflet-pm-icon-save",
            onClick: () => {
                parcellaireDispatch({
                    type: PARCELLAIRE_REDUCER_ACTIONS.CHECKPOINT,
                });
                setGeoJsonKey(new Date().getTime());
            },
            afterClick: () => {
                map.pm.enableGlobalDragMode();
            },
            toggle: false,
        });

        map.pm.Toolbar.createCustomControl({
            name: "markerReset",
            block: "options",
            title: "Removes all line markers",
            className: "leaflet-pm-icon-delete",
            onClick: () => {
                deleteLayers(
                    map,
                    (layer) => layer.options.customType === "marker"
                );
            },
            afterClick: () => {
                map.pm.enableGlobalDragMode();
            },
            toggle: false,
        });

        map.pm.Toolbar.createCustomControl({
            name: "softReset",
            block: "options",
            title: "Reset to last checkpoint",
            className: "leaflet-pm-icon-soft-reset",
            onClick: () => {
                parcellaireDispatch({
                    type: PARCELLAIRE_REDUCER_ACTIONS.GO_TO_CHECKPOINT,
                });
                setGeoJsonKey(new Date().getTime());
            },
            afterClick: () => {
                map.pm.enableGlobalDragMode();
            },
            toggle: false,
        });

        const onCreateHandler = (e) => {
            if (e.shape === POLYGON_SELECT_NAME) {
                // If there is a selection
                const geoJsonSelect = e.layer.toGeoJSON();

                const allLayers = map.pm.getGeomanLayers();

                parcellaireDispatch({
                    type: PARCELLAIRE_REDUCER_ACTIONS.SELECT,
                    e,
                    geoJsonSelect,
                    allLayers,
                });

                setGeoJsonKey(new Date().getTime());

                e.layer.remove();
            } else if (e.shape === "Marker") {
                const { lat, lng } = e.layer.getLatLng();
                // If this is a coordinate marker
                setCoordMarkerMode(false);
                setValue(
                    "latitude",
                    roundNumber(lat, COORDINATE_DECIMAL_COUNT),
                    {
                        shouldDirty: true,
                    }
                );
                setValue(
                    "longitude",
                    roundNumber(lng, COORDINATE_DECIMAL_COUNT),
                    {
                        shouldDirty: true,
                    }
                );
                reset((formValues) => ({ ...formValues }));
            }
            map.pm.enableGlobalDragMode();
        };
        map.on("pm:create", onCreateHandler);

        map.pm.addControls({
            drawMarker: false,
            // drawPolyline: false,
            drawCircle: false,
            drawCircleMarker: false,
            drawRectangle: false,
            drawPolygon: false,
            drawText: false,
            coordMarkerDraw: false,
            editMode: false,
            dragMode: false,
            cutPolygon: false,
            removalMode: false,
            rotateMode: false,
            // positions: { options: "bottomleft" }, // changes position of specific control block
        });

        map.pm.setGlobalOptions({
            snappable: false,
            pmIgnore: false,
            markerStyle: {
                icon: geomanMarkerIcon,
                interactive: false,
            },
        });

        map.pm.Draw.Line.setPathOptions({
            interactive: false,
            color: "red",
            customType: "marker",
        });

        map.pm.enableGlobalDragMode();

        return () => {
            map.pm.removeControls();
            map.pm.setGlobalOptions({ pmIgnore: true, snappable: true });
            map.off("pm:create", onCreateHandler);
        };
    }, [
        map,
        parcellaireDispatch,
        reset,
        setCoordMarkerMode,
        setGeoJsonKey,
        setValue,
    ]);
};

const POLYGON_SELECT_NAME = "polygonSelect";

const geomanMarkerIcon = L.icon({
    iconUrl: "/static/marker-icon-2x.png",
    shadowUrl: "/static/marker-shadow.png",
    iconSize: L.Icon.Default.prototype.options.iconSize,
    shadowSize: L.Icon.Default.prototype.options.shadowSize,
    iconAnchor: L.Icon.Default.prototype.options.iconAnchor,
    shadowAnchor: L.Icon.Default.prototype.options.shadowAnchor,
    popupAnchor: L.Icon.Default.prototype.options.popupAnchor,
});
