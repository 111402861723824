import {
    Avatar,
    Box,
    Button,
    Divider,
    IconButton,
    MenuItem,
    Stack,
    Typography,
} from "@mui/material";
import { useOidc, useOidcAccessToken } from "@axa-fr/react-oidc";
import { useRef, useState } from "react";

import HomeIcon from "@mui/icons-material/Home";
import MenuPopover from "../../components/MenuPopover";
import { Link as RouterLink } from "react-router-dom";
import SchoolOutlinedIcon from "@mui/icons-material/SchoolOutlined";
import { alpha } from "@mui/material/styles";
import { useIsBasicRole } from "../../hooks";

const getInitials = (user) =>
    `${user.firstName?.[0] ? user.firstName[0] : ""}${
        user.lastName?.[0] ? user.lastName[0] : ""
    }`.toUpperCase();

export default function AccountPopover() {
    const menuOptions = useIsBasicRole()
        ? [
              {
                  label: "Home",
                  icon: HomeIcon,
                  linkTo: "/",
              },
          ]
        : [
              {
                  label: "Home",
                  icon: HomeIcon,
                  linkTo: "/",
              },
              {
                  label: "Academy",
                  icon: SchoolOutlinedIcon,
                  linkTo: "https://www.hiphen-plant.com/academy/",
              },
          ];
    const anchorRef = useRef(null);
    const [open, setOpen] = useState(false);
    const { logout } = useOidc();
    const { accessTokenPayload } = useOidcAccessToken();
    const {
        given_name: firstName,
        family_name: lastName,
        email,
    } = accessTokenPayload;

    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const handleLogout = async () => {
        await logout();
    };

    return (
        <>
            <IconButton
                ref={anchorRef}
                onClick={handleOpen}
                sx={{
                    padding: 0,
                    width: 44,
                    height: 44,
                    ...(open && {
                        "&:before": {
                            zIndex: 1,
                            content: "''",
                            width: "100%",
                            height: "100%",
                            borderRadius: "50%",
                            position: "absolute",
                            bgcolor: (theme) =>
                                alpha(theme.palette.grey[900], 0.72),
                        },
                    }),
                }}
            >
                <Avatar
                    sx={{ bgcolor: "primary.dark" }}
                    alt={getInitials({ firstName, lastName })}
                >
                    {getInitials({ firstName, lastName })}
                </Avatar>
            </IconButton>

            <MenuPopover
                open={open}
                onClose={handleClose}
                anchorEl={anchorRef.current}
                sx={{ width: 220 }}
            >
                <Box sx={{ my: 1.5, px: 2.5 }}>
                    <Typography variant="subtitle1" noWrap>
                        {`${firstName} ${lastName}`}
                    </Typography>
                    <Typography
                        variant="body2"
                        sx={{ color: "text.secondary" }}
                        noWrap
                    >
                        {email}
                    </Typography>
                    <Stack direction="row" alignItems="center" spacing={1}>
                        {/* <Typography
                            variant="body2"
                            sx={{ color: "text.secondary" }}
                        >
                            Role:
                        </Typography> */}
                        {/* TODO: display role */}
                        {/* <Chip label={user.userType} size="small" /> */}
                    </Stack>
                </Box>

                <Divider sx={{ my: 1 }} />

                {menuOptions.map((option) => (
                    <MenuItem
                        key={option.label}
                        to={option.linkTo}
                        component={RouterLink}
                        onClick={handleClose}
                        sx={{ typography: "body2", py: 1, px: 2.5 }}
                    >
                        <option.icon
                            sx={{
                                mr: 2,
                                width: 24,
                                height: 24,
                            }}
                        />

                        {option.label}
                    </MenuItem>
                ))}

                <Box sx={{ p: 2, pt: 1.5 }}>
                    <Button
                        fullWidth
                        color="inherit"
                        variant="outlined"
                        onClick={handleLogout}
                    >
                        Logout
                    </Button>
                </Box>
            </MenuPopover>
        </>
    );
}
