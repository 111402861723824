import { Box, DialogContent } from "@mui/material";
import { getGsdPerStage, getMinGsd } from "../utils";

import { MissionProtocolDetails } from "./MissionProtocolDetails";
import { MissionProtocolStageTable } from "./MissionProtocolStageTable";
import { MissionProtocolSummary } from "./MissionProtocolSummary";
import { useMemo } from "react";

export function MissionProtocolUav({
    pipelineTemplate,
    bbchStageData,
    TGDTSortedByDatatype,
    displayTraits,
    maxTraitCount,
}) {
    const minGSD = useMemo(
        () => getMinGsd(pipelineTemplate),
        [pipelineTemplate]
    );

    const gsdPerStage = useMemo(
        () => getGsdPerStage(pipelineTemplate, bbchStageData.rows),
        [pipelineTemplate, bbchStageData]
    );

    return (
        <>
            <MissionProtocolDetails
                pipelineTemplate={pipelineTemplate}
                bbchStageData={bbchStageData}
                TGDTSortedByDatatype={TGDTSortedByDatatype}
                gsdPerStage={gsdPerStage}
                minGSD={minGSD}
                displayTraits={displayTraits}
                maxTraitCount={maxTraitCount}
            />

            <div
                style={{
                    display: "none",
                }}
                id={pipelineTemplate.uuid}
            >
                {/* The DialogContent adds padding for correct pdf exportation. 
                It can be removed at the condition of readjusting the padding 
                in the pdf export function. */}
                <DialogContent>
                    <MissionProtocolDetails
                        pipelineTemplate={pipelineTemplate}
                        bbchStageData={bbchStageData}
                        TGDTSortedByDatatype={TGDTSortedByDatatype}
                        gsdPerStage={gsdPerStage}
                        minGSD={minGSD}
                        displayTraits={displayTraits}
                        maxTraitCount={maxTraitCount}
                        printed
                    />
                </DialogContent>
            </div>

            <div
                style={{
                    display: "none",
                    width: 600,
                }}
                id={`${pipelineTemplate.uuid}_summary`}
            >
                <MissionProtocolSummary
                    pipelineTemplate={pipelineTemplate}
                    bbchStageData={bbchStageData}
                    gsdPerStage={gsdPerStage}
                    minGSD={minGSD}
                />
            </div>

            <div
                style={{ display: "none" }}
                id={`${pipelineTemplate.uuid}_table`}
            >
                <Box pb={2}>
                    <MissionProtocolStageTable
                        pipelineTemplate={pipelineTemplate}
                        bbchStageData={bbchStageData}
                        TGDTSortedByDatatype={TGDTSortedByDatatype}
                        gsdPerStage={gsdPerStage}
                        minGSD={minGSD}
                        displayTraits={true} // forced to true because this table should always display the traits
                        maxTraitCount={maxTraitCount}
                        printed
                    />
                </Box>
            </div>
        </>
    );
}
