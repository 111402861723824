import {
    Card,
    Dialog,
    IconButton,
    Stack,
    Tooltip,
    Typography,
} from "@mui/material";
import { getDataGridHeight, objectArrayStringify } from "../utils";
import { initialModalState, modalReducer } from "./tableModalReducer";
import { useFetch, useIsBasicRole, useSnackbar } from "../../hooks";
import { useMemo, useReducer } from "react";

import AddCircleOutlinedIcon from "@mui/icons-material/AddCircleOutlined";
import { BACKEND_ROUTES } from "../../backendRoutes";
import { Box } from "@mui/system";
import { ConfirmModal } from "../../components/ConfirmModal";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { DataGrid } from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
import ExploreIcon from "@mui/icons-material/Explore";
import { MissionProtocol } from "../mission-protocol";
import { ModalTransition } from "../../components/ModalTransition";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import { PipelineTemplateDialog } from "./form";
import { SYSTEMS } from "../../constants";

export function PipelineTemplateTable({
    contractUuid,
    mutate,
    pipelineTemplates,
}) {
    const { destroy } = useFetch();
    const isBasicRole = useIsBasicRole();
    const { openSnackbar } = useSnackbar();
    const [modalState, modalDispatch] = useReducer(
        modalReducer,
        initialModalState
    );

    const selectedTemplateIsLiteral = useMemo(
        () =>
            pipelineTemplates.find(
                (template) => template.uuid === modalState.pipelineTemplateUuid
            )?.AcquisitionVector.SystemModel.system === SYSTEMS.LITERAL,
        [modalState.pipelineTemplateUuid, pipelineTemplates]
    );

    const columns = useMemo(
        () => [
            {
                field: "actionButtons",
                headerName: "Actions",
                sortable: false,
                hideable: false,
                filterable: false,
                flex: 2,
                minWidth: isBasicRole ? 75 : 150,
                disableClickEventBubbling: true,
                renderCell: (params) => {
                    const onClickEdit = () => {
                        modalDispatch({
                            type: "edit",
                            pipelineTemplateUuid: params.row.id,
                        });
                    };

                    const onClickDelete = () => {
                        modalDispatch({
                            type: "delete",
                            pipelineTemplateUuid: params.row.id,
                            pipelineTemplateName: params.row.name,
                        });
                    };

                    const onClickSop = () => {
                        modalDispatch({
                            type: "sop",
                            pipelineTemplateUuid: params.row.id,
                        });
                    };

                    const onClickClone = () => {
                        modalDispatch({
                            type: "clone",
                            pipelineTemplateUuid: params.row.id,
                        });
                    };

                    return (
                        <>
                            {!isBasicRole && (
                                <Tooltip title="Edit">
                                    <IconButton
                                        onClick={onClickEdit}
                                        sx={{
                                            "&:hover": {
                                                backgroundColor:
                                                    "primary.lighter",
                                            },
                                        }}
                                    >
                                        <ModeEditIcon
                                            fontSize="small"
                                            color="primary"
                                        />
                                    </IconButton>
                                </Tooltip>
                            )}

                            <Tooltip title="Protocol details">
                                <IconButton
                                    onClick={onClickSop}
                                    sx={{
                                        "&:hover": {
                                            backgroundColor: "primary.lighter",
                                        },
                                    }}
                                >
                                    <ExploreIcon
                                        fontSize="small"
                                        color="primary"
                                    />
                                </IconButton>
                            </Tooltip>

                            {!isBasicRole && (
                                <>
                                    <Tooltip title="Clone">
                                        <IconButton
                                            onClick={onClickClone}
                                            sx={{
                                                "&:hover": {
                                                    backgroundColor:
                                                        "primary.lighter",
                                                },
                                            }}
                                        >
                                            <ContentCopyIcon
                                                fontSize="small"
                                                color="primary"
                                            />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Delete">
                                        <IconButton
                                            onClick={onClickDelete}
                                            sx={{
                                                "&:hover": {
                                                    backgroundColor:
                                                        "primary.lighter",
                                                },
                                            }}
                                        >
                                            <DeleteIcon
                                                fontSize="small"
                                                color="primary"
                                            />
                                        </IconButton>
                                    </Tooltip>
                                </>
                            )}
                        </>
                    );
                },
            },
            {
                field: "name",
                headerName: "Name",
                minWidth: 200,
                flex: 3,
            },
            {
                field: "crop",
                headerName: "Crop",
                minWidth: 120,
                flex: 2,
            },
            {
                field: "system",
                headerName: "System",
                minWidth: 120,
                flex: 2,
            },
            {
                field: "acquisitionVector",
                headerName: "Acquisition Vector",
                minWidth: 280,
                flex: 3,
            },
            {
                field: "dataType",
                headerName: "Data Type",
                minWidth: 150,
                flex: 2,
            },
        ],
        [isBasicRole]
    );

    const rows = useMemo(
        () =>
            pipelineTemplates.map((pipeline) => {
                return {
                    id: pipeline.uuid,
                    crop: pipeline.Crop.name,
                    name: pipeline.name,
                    system: pipeline.AcquisitionVector.SystemModel.system,
                    dataType: objectArrayStringify(
                        pipeline.AcquisitionVector.SensorBundles,
                        ["Sensor", "dataType"]
                    ),
                    acquisitionVector: pipeline.AcquisitionVector.name,
                };
            }),
        [pipelineTemplates]
    );

    const closeModal = () => modalDispatch({ type: "close" });

    const onConfirmDelete = async () => {
        const deletingTemplateUuid = modalState.pipelineTemplateUuid;
        const deletingTemplateName = modalState.pipelineTemplateName;

        closeModal();

        const deleteResult = await destroy(
            `${BACKEND_ROUTES.PIPELINE_TEMPLATE}/${deletingTemplateUuid}`
        );

        if (deleteResult) {
            mutate();
            openSnackbar(
                `Template ${deletingTemplateName} deleted successfully.`,
                "success"
            );
        }
    };

    return (
        <>
            <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
            >
                <Typography variant="h6" gutterBottom>
                    Pipeline templates
                </Typography>

                <Stack direction="row" spacing={1}>
                    {!isBasicRole && (
                        <>
                            <IconButton
                                size="large"
                                onClick={() => {
                                    modalDispatch({
                                        type: "create",
                                    });
                                }}
                            >
                                <AddCircleOutlinedIcon
                                    fontSize="inherit"
                                    color="primary"
                                />
                            </IconButton>

                            <Dialog
                                open={modalState.modal === "form"}
                                fullWidth
                                maxWidth="xl"
                                TransitionComponent={ModalTransition}
                            >
                                <PipelineTemplateDialog
                                    uuid={modalState.pipelineTemplateUuid}
                                    isCreation={modalState.isCreation}
                                    contractUuid={contractUuid}
                                    closeModal={closeModal}
                                    mutateTable={mutate}
                                />
                            </Dialog>
                        </>
                    )}
                    <Dialog
                        open={modalState.modal === "sop"}
                        fullWidth
                        maxWidth={selectedTemplateIsLiteral ? "md" : "xl"}
                        TransitionComponent={ModalTransition}
                    >
                        <MissionProtocol
                            pipelineTemplateUuid={
                                modalState.pipelineTemplateUuid
                            }
                            closeModal={closeModal}
                        />
                    </Dialog>
                </Stack>
            </Stack>
            <Card>
                <Box
                    sx={{
                        height: getDataGridHeight(
                            600,
                            pipelineTemplates.length
                        ),
                        width: "100%",
                    }}
                >
                    <DataGrid
                        rows={rows}
                        columns={columns}
                        initialState={initialState}
                        pageSizeOptions={pageSizeOptions}
                    />
                </Box>
            </Card>
            <ConfirmModal
                isOpen={modalState.modal === "delete"}
                onCancel={closeModal}
                onConfirm={onConfirmDelete}
                title="Are you sure you want to delete the pipeline template?"
                contentText={
                    <>
                        All information of{" "}
                        <b>{modalState.pipelineTemplateName}</b> will be
                        deleted.
                    </>
                }
            />
        </>
    );
}

const initialState = {
    sorting: {
        sortModel: [{ field: "name", sort: "asc" }],
    },
};

const pageSizeOptions = [100];
