import { Button, DialogActions, DialogContent, Grid } from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";
import {
    useAcquisitionVectors,
    useBBCHStages,
    useFetch,
    usePresets,
    useSnackbar,
} from "../../../hooks";

import { BACKEND_ROUTES } from "../../../backendRoutes";
import { LoadingButton } from "@mui/lab";
import { PipelineTemplateBBCHForm } from "./PipelineTemplateBBCHForm";
import { PipelineTemplateDataForm } from "./PipelineTemplateDataForm";
import { PipelineTemplatePresetForm } from "./PipelineTemplatePresetForm";
import { PipelineTemplateTraitForm } from "./PipelineTemplateTraitForm";
import PropTypes from "prop-types";
import { SYSTEMS } from "../../../constants";
import { useDefaultValues } from "./useDefaultValues";

PipelineTemplateForm.propTypes = {
    closeModal: PropTypes.func.isRequired,
    contract: PropTypes.object.isRequired,
    isCreation: PropTypes.bool.isRequired,
    mutate: PropTypes.func.isRequired,
    mutateTable: PropTypes.func.isRequired,
    pipelineTemplate: PropTypes.object,
    setIsOpenErrorDialog: PropTypes.func.isRequired,
};

/**
 * If isCreation true and no pipelineTemplate: we are creating a new pipeline template.
 * If isCreation true and pipelineTemplate provided: we are cloning an existing pipeline template.
 * If isCreation false: pipelineTemplate must be provided and we are modifying an existing pipeline template.
 */
export function PipelineTemplateForm({
    closeModal,
    contract,
    isCreation,
    mutate,
    mutateTable,
    pipelineTemplate,
    setIsOpenErrorDialog,
}) {
    // If isCreation false, pipelineTemplate must be provided
    if (!isCreation && !pipelineTemplate)
        throw new TypeError(
            "pipelineTemplate must be provided if isCreation is false"
        );

    const { post, patch } = useFetch();
    const { openSnackbar } = useSnackbar();
    const {
        data: { rows: acquisitionVectors },
    } = useAcquisitionVectors();
    const {
        data: { rows: bbchStages },
    } = useBBCHStages();
    const {
        data: { rows: presets },
    } = usePresets();

    const defaultValues = useDefaultValues({
        isCreation,
        pipelineTemplate,
    });
    const formMethods = useForm({ defaultValues });
    const {
        handleSubmit,
        watch,
        formState: { isSubmitting },
    } = formMethods;

    const [selectedSystem, selectedAcquisitionVectorUuid] = watch([
        "system",
        "acquisitionVectorUuid",
    ]);

    const selectedAcquisitionVector = acquisitionVectors.find(
        (acquisitionVector) =>
            acquisitionVector.uuid === selectedAcquisitionVectorUuid
    );

    const hasNoFocalLength = selectedAcquisitionVector?.SensorBundles.some(
        (bundle) => !bundle.Sensor.focalLength35mmEqv
    );

    const onSubmit = async (payload) => {
        let body = { ...payload, contractUuid: contract.uuid };

        if (payload.system === SYSTEMS.LITERAL) {
            const bbchStageUuidZero = bbchStages.find(
                (bbch) => bbch.order === 1
            ).uuid;
            body = {
                ...body,
                focalLength35mmEqv: null,

                bbchTraits: presets
                    .find((preset) => preset.uuid === payload.presetUuid)
                    .TraitGroupDataTypes.map((tgdt) => ({
                        traitGroupDataTypeUuid: tgdt.uuid,
                        bbchStageUuids: [bbchStageUuidZero],
                    })),
                presetUuid: payload.presetUuid,
                flightSequences: null,
            };
        } else {
            const filteredTGDTs = Object.keys(payload.bbchTraits).filter(
                (traitGroupDataTypeUuid) =>
                    payload.bbchTraits[traitGroupDataTypeUuid].length
            );
            if (filteredTGDTs.length === 0) {
                setIsOpenErrorDialog(true);
                return;
            } else {
                const bbchTraitArray = filteredTGDTs.map(
                    (traitGroupDataTypeUuid) => ({
                        traitGroupDataTypeUuid,
                        bbchStageUuids:
                            payload.bbchTraits[traitGroupDataTypeUuid],
                    })
                );

                const formattedFlightSequences = Object.entries(
                    payload.flightSequences
                ).map(([bbchStageUuid, sequence]) => ({
                    bbchStageUuid,
                    sequence,
                }));

                body = {
                    ...body,
                    focalLength35mmEqv: hasNoFocalLength
                        ? payload.focalLength35mmEqv
                        : null, // will be null if the acquisition vector already has a focal length (because you can't input a value in that case)
                    bbchTraits: bbchTraitArray,
                    flightSequences: formattedFlightSequences,
                    presetUuid: null,
                };
            }
        }

        const updatedPipelineTemplate = await (isCreation
            ? post(BACKEND_ROUTES.PIPELINE_TEMPLATE, { body })
            : patch(
                  `${BACKEND_ROUTES.PIPELINE_TEMPLATE}/${pipelineTemplate.uuid}`,
                  { body }
              ));
        if (updatedPipelineTemplate) {
            // two mutates because it caused issues when updating a pipeline and then instantly reopening it
            mutate();
            mutateTable();
            closeModal();
            openSnackbar(
                `Template ${updatedPipelineTemplate.name} ${
                    isCreation ? "created" : "updated"
                } successfully.`,
                "success"
            );
        }
    };

    return (
        <FormProvider {...formMethods}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <DialogContent>
                    <Grid container spacing={3}>
                        <PipelineTemplateDataForm
                            hasNoFocalLength={hasNoFocalLength}
                        />
                        {selectedSystem === SYSTEMS.LITERAL ? (
                            <PipelineTemplatePresetForm />
                        ) : (
                            <>
                                <PipelineTemplateTraitForm
                                    selectedAcquisitionVector={
                                        selectedAcquisitionVector
                                    }
                                />
                                <PipelineTemplateBBCHForm />
                            </>
                        )}
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button type="button" onClick={closeModal}>
                        Cancel
                    </Button>
                    <LoadingButton
                        type="submit"
                        variant="contained"
                        loading={isSubmitting}
                    >
                        {isCreation ? "Create" : "Update"}
                    </LoadingButton>
                </DialogActions>
            </form>
        </FormProvider>
    );
}
