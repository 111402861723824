import "leaflet/dist/leaflet.css";

import {
    Box,
    Container,
    Dialog,
    Drawer,
    Fab,
    Skeleton,
    Stack,
    Typography,
    styled,
} from "@mui/material";
import {
    DRAWER_WIDTH,
    DYNAMIC_FEATURE_X_Y_FIELD_NAME,
    GEOMAN_CUSTOM_TOGGLE_BUTTON_NAMES,
    MAP_TILE_LAYERS,
} from "./constants";
import { FormProvider, useForm } from "react-hook-form";
import {
    initialParcellaireState,
    parcellaireReducer,
} from "./parcellaireReducer";
import { useReducer, useState } from "react";

import { APPBAR_HEIGHT } from "../../layouts/orders/constants";
import AddIcon from "@mui/icons-material/Add";
import { FetchErrorAlert } from "../../components/FetchErrorAlert";
import LastPageIcon from "@mui/icons-material/LastPage";
import { MapContainer } from "react-leaflet";
import { MapLayout } from "./MapLayout";
import { ParcellaireCreationContainer } from "./ParcellaireCreationContainer";
import { ParcellaireCreationFormNoImport } from "./ParcellaireCreationFormNoImport";
import { ParcellaireHeader } from "./ParcellaireHeader";
import { ParcellaireMetadataForm } from "./ParcellaireMetadataForm";
import { useLiteralRequests } from "./hooks";

export function Parcellaire({ isLiteral }) {
    const [map, setMap] = useState();
    const [plotColor, setPlotColor] = useState("#3388ff");
    const [openDrawer, setOpenDrawer] = useState(isLiteral);
    const [openExportForm, setOpenExportForm] = useState(false);
    const [orthoimageName, setOrthoimageName] = useState("plain");
    const [coordMarkerMode, setCoordMarkerMode] = useState(false);
    const [blueRectangleData, setBlueRectangleData] = useState(null);
    const [selectableMetadata, setSelectableMetadata] = useState([]);
    const [geoJsonKey, setGeoJsonKey] = useState(new Date().getTime());
    const [selectedTileLayer, setSelectedTileLayer] = useState(
        isLiteral ? MAP_TILE_LAYERS.OPEN_STREET_MAP : MAP_TILE_LAYERS.SATELLITE
    );
    const [displayToggles, setFirstToggles] = useState([]);
    const [transformationToggles, setSecondToggles] = useState([
        GEOMAN_CUSTOM_TOGGLE_BUTTON_NAMES.LOCKED_SHAPE_MODE,
    ]);
    const [parcellaireState, parcellaireDispatch] = useReducer(
        parcellaireReducer,
        initialParcellaireState
    );

    const methods = useForm({
        defaultValues: getParcellaireFormDefaultValues(isLiteral),
    });

    const [
        { error: siteError },
        { data: literalPipelineTemplates, error: pipelineTemplatesError },
    ] = useLiteralRequests();

    const error = siteError ?? pipelineTemplatesError;
    if (error) return <FetchErrorAlert error={error} />;

    // If `literalPipelineTemplates` is defined then both site and pipeline templates fetches are successful
    if (isLiteral && !literalPipelineTemplates) {
        return (
            <Skeleton
                animation="wave"
                variant="rectangular"
                width="100%"
                height="100vh"
            />
        );
    }

    return (
        <FormProvider {...methods}>
            <Stack direction="column">
                <ParcellaireHeader
                    blueRectangleData={blueRectangleData}
                    displayToggles={displayToggles}
                    isLiteral={isLiteral}
                    map={map}
                    orthoimageName={orthoimageName}
                    openDrawer={openDrawer}
                    parcellaireDispatch={parcellaireDispatch}
                    parcellaireState={parcellaireState}
                    plotColor={plotColor}
                    transformationToggles={transformationToggles}
                    selectableMetadata={selectableMetadata}
                    selectedTileLayer={selectedTileLayer}
                    setBlueRectangleData={setBlueRectangleData}
                    setFirstToggles={setFirstToggles}
                    setGeoJsonKey={setGeoJsonKey}
                    setOpenExportForm={setOpenExportForm}
                    setOrthoimageName={setOrthoimageName}
                    setPlotColor={setPlotColor}
                    setSecondToggles={setSecondToggles}
                    setSelectableMetadata={setSelectableMetadata}
                    setSelectedTileLayer={setSelectedTileLayer}
                />
                <Box
                    id="map"
                    width="100%"
                    height={`calc(100vh - ${APPBAR_HEIGHT}px)`}
                >
                    <MapContainer
                        attributionControl={false}
                        center={[26.0, 30.0]}
                        maxZoom={25}
                        minZoom={3}
                        zoom={3}
                        maxBounds={[
                            [-90, -270],
                            [90, 270],
                        ]}
                        maxBoundsViscosity={0.8}
                        style={{
                            width: "100%",
                            height: "100%",
                            backgroundImage: "url(/static/mapbg.png)",
                            backgroundSize: "1300px",
                        }}
                        ref={setMap}
                    >
                        <MapLayout
                            blueRectangleData={blueRectangleData}
                            displayToggles={displayToggles}
                            geoJsonKey={geoJsonKey}
                            isLiteral={isLiteral}
                            orthoimageName={orthoimageName}
                            parcellaireDispatch={parcellaireDispatch}
                            parcellaireState={parcellaireState}
                            plotColor={plotColor}
                            transformationToggles={transformationToggles}
                            selectedTileLayer={selectedTileLayer}
                            setCoordMarkerMode={setCoordMarkerMode}
                            setGeoJsonKey={setGeoJsonKey}
                        />
                    </MapContainer>
                </Box>
            </Stack>
            <Drawer
                open={openDrawer}
                onClose={() => setOpenDrawer(false)}
                variant="persistent"
                PaperProps={{
                    sx: {
                        width: DRAWER_WIDTH,
                        bgcolor: "background.neutral",
                    },
                }}
                anchor="right"
            >
                <>
                    <DrawerHeader>
                        <Typography variant="h5">Plot map creation</Typography>
                    </DrawerHeader>
                    <Container
                        maxWidth="xl"
                        sx={{ bgcolor: "background.default" }}
                    >
                        {isLiteral ? (
                            <ParcellaireCreationFormNoImport
                                coordMarkerMode={coordMarkerMode}
                                isLiteral={true}
                                map={map}
                                parcellaireDispatch={parcellaireDispatch}
                                setBlueRectangleData={setBlueRectangleData}
                                setCoordMarkerMode={setCoordMarkerMode}
                                setGeoJsonKey={setGeoJsonKey}
                                setIsOpen={setOpenDrawer}
                                setSelectableMetadata={setSelectableMetadata}
                            />
                        ) : (
                            <ParcellaireCreationContainer
                                coordMarkerMode={coordMarkerMode}
                                map={map}
                                parcellaireDispatch={parcellaireDispatch}
                                setBlueRectangleData={setBlueRectangleData}
                                setCoordMarkerMode={setCoordMarkerMode}
                                setGeoJsonKey={setGeoJsonKey}
                                setIsOpen={setOpenDrawer}
                                setSelectableMetadata={setSelectableMetadata}
                            />
                        )}
                    </Container>
                </>
            </Drawer>

            <Fab
                size={openDrawer ? "small" : "large"}
                color="primary"
                variant={openDrawer ? "circular" : "extended"}
                sx={(theme) => ({
                    transition: theme.transitions.create(
                        "right",
                        openDrawer
                            ? {
                                  duration:
                                      theme.transitions.duration.leavingScreen,
                                  easing: theme.transitions.easing.easeOut,
                              }
                            : {
                                  duration:
                                      theme.transitions.duration.enteringScreen,
                                  easing: theme.transitions.easing.sharp,
                              }
                    ),
                    position: "absolute",
                    right: openDrawer ? DRAWER_WIDTH - 20 : 10,
                    top: 76,
                    zIndex: theme.zIndex.drawer,
                })}
                onClick={() => setOpenDrawer(!openDrawer)}
            >
                {openDrawer ? (
                    <LastPageIcon />
                ) : (
                    <>
                        Create new plot map
                        <AddIcon />
                    </>
                )}
            </Fab>

            {isLiteral && (
                <Dialog open={openExportForm} fullWidth maxWidth="xs">
                    <ParcellaireMetadataForm
                        parcellaireState={parcellaireState}
                        setIsOpen={setOpenExportForm}
                    />
                </Dialog>
            )}
        </FormProvider>
    );
}

const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: "center",
}));

const getParcellaireFormDefaultValues = (isLiteral) =>
    isLiteral
        ? {
              xCount: 20,
              yCount: 4,
              latitude: null,
              longitude: null,
              xSize: 3,
              ySize: 1,
              xSpacing: 0.5,
              ySpacing: 0.2,
              angle: 0,
              selectedMetadata: {
                  label: "X and Y",
                  id: DYNAMIC_FEATURE_X_Y_FIELD_NAME,
              },
          }
        : {
              xCount: "",
              yCount: "",
              latitude: null,
              longitude: null,
              xSize: "",
              ySize: "",
              xSpacing: "",
              ySpacing: "",
              angle: 0,
              selectedMetadata: null,
          };
