import {
    Divider,
    Grid,
    Stack,
    TextField,
    Tooltip,
    Typography,
} from "@mui/material";
import { FLIGHT_SEQUENCE_REGEX, INPUT_VALIDATION } from "../../../constants";
import { useBBCHStages, useCrops } from "../../../hooks";
import { useFormContext, useWatch } from "react-hook-form";

import { BBCHStageTooltip } from "../../../components/BBCHStageTooltip";
import InfoIcon from "@mui/icons-material/Info";
import { PipelineTemplateBBCHRow } from "./PipelineTemplateBBCHRow";

export function PipelineTemplateBBCHForm() {
    const {
        formState: { errors },
        register,
    } = useFormContext();
    const [selectedCropUuid, ...selectedNestedTGDT] = useWatch({
        name: [
            "cropUuid",
            "canopyDevelopment",
            "biomassProxy",
            "trialQuality",
            "harvestIndex",
            "plantStress",
        ],
    });

    // loading and errors handled in parent component
    const {
        data: { rows: bbchStages },
    } = useBBCHStages();
    const {
        data: { rows: crops },
    } = useCrops();

    const selectedPlantScales =
        crops.find((crop) => crop.uuid === selectedCropUuid)?.PlantScales ?? [];

    const selectedTGDT = selectedNestedTGDT.flat();

    return (
        <Grid item xs={12} container spacing={1} alignContent="center">
            <Grid item xs={12}>
                <Typography>Order the traits per phenological stage</Typography>
                <Divider />
            </Grid>
            <Grid item xs={12}>
                <Typography sx={{ fontStyle: "italic", color: "grey" }}>
                    Acquisition timing
                </Typography>
                <Divider />
            </Grid>

            <Grid item xs={3}>
                <Stack direction="row" spacing={1} alignItems="center">
                    <Typography>BBCH phenological stages</Typography>
                    <BBCHStageTooltip bbchStages={bbchStages} />
                </Stack>
            </Grid>
            <Grid item xs={9}>
                <Stack
                    direction="row"
                    justifyContent="space-around"
                    alignItems="center"
                    spacing={1}
                >
                    {bbchStages.map((bbchStage) => (
                        <Typography key={bbchStage.uuid}>
                            {bbchStage.stage}
                        </Typography>
                    ))}
                </Stack>
            </Grid>

            <Grid item xs={3}>
                <Stack direction="row" spacing={1} alignItems="center">
                    <Typography>Flight sequence</Typography>
                    <FlightSequenceTooltip />
                </Stack>
            </Grid>
            <Grid item xs={9}>
                <Stack
                    direction="row"
                    justifyContent="space-around"
                    alignItems="center"
                    spacing={1}
                >
                    {bbchStages.map((bbchStage) => (
                        <TextField
                            key={bbchStage.uuid}
                            fullWidth
                            error={Boolean(
                                errors.flightSequences?.[bbchStage.uuid]
                            )}
                            helperText={
                                errors.flightSequences?.[bbchStage.uuid]
                                    ?.message
                            }
                            {...register(`flightSequences.${bbchStage.uuid}`, {
                                pattern: {
                                    value: FLIGHT_SEQUENCE_REGEX,
                                    message: INPUT_VALIDATION.INVALID_SHORT,
                                },
                            })}
                        />
                    ))}
                </Stack>
            </Grid>

            <Grid item xs={12}>
                <Typography sx={{ fontStyle: "italic", color: "grey" }}>
                    Selected traits
                </Typography>
                <Divider />
            </Grid>
            {selectedTGDT.length > 0 ? (
                selectedTGDT.map((TGDT) => (
                    <PipelineTemplateBBCHRow
                        bbchStages={bbchStages}
                        selectedPlantScales={selectedPlantScales}
                        traitGroupDataType={TGDT}
                        key={TGDT.uuid}
                    />
                ))
            ) : (
                <Grid item xs={12}>
                    <Typography
                        sx={{
                            fontStyle: "italic",
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        Please select at least one trait.
                    </Typography>
                </Grid>
            )}
        </Grid>
    );
}

function FlightSequenceTooltip() {
    return (
        <Tooltip
            title={
                <Stack>
                    <Typography>Must be a number or a number range.</Typography>

                    <Typography>Examples:</Typography>
                    <Typography sx={{ fontStyle: "italic" }}>- "2"</Typography>
                    <Typography sx={{ fontStyle: "italic" }}>
                        - "4-7"
                    </Typography>
                </Stack>
            }
        >
            <InfoIcon fontSize="small" sx={{ color: "grey.500" }} />
        </Tooltip>
    );
}
