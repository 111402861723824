import {
    DYNAMIC_FEATURE_X_Y_FIELD_NAME,
    GEOMAN_CUSTOM_TOGGLE_BUTTON_NAMES,
    TRANSFORM_RANGE_ERROR,
    UNKNOWN_TRANSFORM_ERROR,
} from "./constants";
import { GeoJSON, TileLayer, WMSTileLayer } from "react-leaflet";
import { applyTransform, geoJSONUtil } from "./utils";

import L from "leaflet";
import { PARCELLAIRE_REDUCER_ACTIONS } from "./parcellaireReducer";
import { VectorPolyline } from "./VectorPolyline";
import { useGeomanConfig } from "./hooks";
import { useMemo } from "react";
import { useSnackbar } from "../../hooks";
import { useWatch } from "react-hook-form";

export const MapLayout = ({
    blueRectangleData,
    displayToggles,
    geoJsonKey,
    isLiteral,
    orthoimageName,
    parcellaireDispatch,
    parcellaireState,
    plotColor,
    transformationToggles,
    selectedTileLayer,
    setCoordMarkerMode,
    setGeoJsonKey,
}) => {
    const { openSnackbar } = useSnackbar();
    useGeomanConfig({ parcellaireDispatch, setCoordMarkerMode, setGeoJsonKey });

    const selectedMetadata = useWatch({ name: "selectedMetadata" });

    const [displayBlue, hiddenPlotMode] = useMemo(() => {
        const toggledDisplays = [false, false];
        displayToggles.forEach((toggleButton) => {
            if (
                toggleButton ===
                GEOMAN_CUSTOM_TOGGLE_BUTTON_NAMES.DISPLAY_BLUE_MODE
            )
                toggledDisplays[0] = true;
            if (
                toggleButton ===
                GEOMAN_CUSTOM_TOGGLE_BUTTON_NAMES.HIDDEN_PLOT_MODE
            )
                toggledDisplays[1] = true;
        });
        return toggledDisplays;
    }, [displayToggles]);

    const blueRectangleOnFeature = (_, layer) => {
        layer.setStyle({
            color: "black",
            opacity: 0.1,
            interactive: false,
        });
        layer.bringToBack();
    };

    const geoJsonOnEachFeature = (feature, layer) => {
        layer.setStyle({ color: plotColor });
        if (parcellaireState.selectedLayers.length) {
            const style = parcellaireState.selectedLayers.find(
                (selectedLayer) =>
                    geoJSONUtil.getIdOfFeature(selectedLayer.feature) ===
                    geoJSONUtil.getIdOfFeature(feature)
            )
                ? {
                      color: plotColor,
                      opacity: 1.0,
                      interactive: true,
                  }
                : {
                      color: "black",
                      opacity: 0.4,
                      interactive: false,
                  };

            layer.setStyle(style);
        } else if (
            parcellaireState.lockedLayers.find(
                (lockedLayer) =>
                    geoJSONUtil.getIdOfFeature(lockedLayer.feature) ===
                    geoJSONUtil.getIdOfFeature(feature)
            )
        ) {
            layer.setStyle({
                color: "black",
                opacity: 1.0,
                interactive: false,
            });
        }

        // sets layer color to white if it has been moved
        if (
            parcellaireState.transformModeVectors.find(
                (vector) =>
                    vector.shapeObjectId === geoJSONUtil.getIdOfFeature(feature)
            )
        ) {
            layer.setStyle({ color: "white" });
        }

        layer.bindPopup(
            `Name: ${feature.properties.name ?? feature.properties.plot_id ?? `X${feature.properties.x}_Y${feature.properties.y}`}
            <br/>X: ${feature.properties.x}
            <br/>Y: ${feature.properties.y}`
        );

        // triggers when the layer is moved
        layer.on("pm:edit", () => {
            const shapeObjectId = geoJSONUtil.getIdOfFeature(feature);

            const oldFeature = geoJSONUtil.findFeatureById(
                parcellaireState.checkpointGeoJsonData,
                shapeObjectId
            );
            const [oldCenterLongitude, oldCenterLatitude] =
                geoJSONUtil.getCenterOfFeature(oldFeature);
            const { lat: newCenterLatitude, lng: newCenterLongitude } =
                layer.getCenter();

            const vector = {
                shapeObjectId,
                longitude: newCenterLongitude - oldCenterLongitude,
                latitude: newCenterLatitude - oldCenterLatitude,
            };

            // shapeObjectId, vector, toggles, setTransformError are required
            const vectorIndex = parcellaireState.transformModeVectors.findIndex(
                (vector) => vector.shapeObjectId === shapeObjectId
            );

            const newTransformModeVectors =
                vectorIndex === -1
                    ? parcellaireState.transformModeVectors.concat(vector)
                    : parcellaireState.transformModeVectors.toSpliced(
                          vectorIndex,
                          1,
                          vector
                      );

            try {
                const transform = applyTransform(
                    parcellaireState,
                    transformationToggles,
                    newTransformModeVectors
                );

                parcellaireDispatch({
                    type: PARCELLAIRE_REDUCER_ACTIONS.PM_EDIT,
                    transform,
                });
            } catch (error) {
                // TODO: error boundary https://hiphen.atlassian.net/browse/HCC-822
                openSnackbar(
                    error instanceof RangeError
                        ? TRANSFORM_RANGE_ERROR
                        : UNKNOWN_TRANSFORM_ERROR,
                    "error"
                );
            }

            setGeoJsonKey(new Date().getTime());
        });
    };

    const geoJsonPointToLayer = (feature, latlng) => {
        // this transforms markers for points into circle features (that cannot be interacted with, for now)
        return feature.id
            ? L.circleMarker(latlng, {
                  interactive: false,
                  radius: 5,
              }).bindTooltip(feature.id, {
                  permanent: true,
                  direction: "top",
              })
            : L.circleMarker(latlng, {
                  interactive: false,
                  radius: 5,
              });
    };

    const geoJsonTextPointToLayer = (geoJsonPoint, latlng) => {
        return L.marker(latlng, {
            icon: L.divIcon({
                className: "",
                html: `<b style="display:inline-block; transform:translate(-50%, -50%); color: white;text-shadow:-1px -1px 0 #000,1px -1px 0 #000,-1px 1px 0 #000,1px 1px 0 #000;">
                ${
                    selectedMetadata.id === DYNAMIC_FEATURE_X_Y_FIELD_NAME
                        ? `${geoJsonPoint.properties.x}_${geoJsonPoint.properties.y}`
                        : geoJsonPoint.properties[selectedMetadata.id]
                }
                </b>`,
                iconSize: "auto",
            }),
            customType: "idLabel",
            interactive: false,
        });
    };

    return (
        <>
            <TileLayer
                url={selectedTileLayer.url}
                minZoom={2}
                maxNativeZoom={isLiteral ? 19 : 17}
                maxZoom={25}
            />
            {parcellaireState.transformModeVectors.map((vector) => (
                <VectorPolyline
                    key={vector.shapeObjectId}
                    vector={vector}
                    checkpointGeoJsonData={
                        parcellaireState.checkpointGeoJsonData
                    }
                />
            ))}
            {displayBlue && blueRectangleData && (
                <GeoJSON
                    key={`${geoJsonKey}-blueRectangle`}
                    data={blueRectangleData}
                    bubblingMouseEvents={false}
                    onEachFeature={blueRectangleOnFeature}
                    pointToLayer={geoJsonPointToLayer}
                />
            )}
            {!hiddenPlotMode && parcellaireState.transformedGeoJson && (
                <>
                    <GeoJSON
                        key={geoJsonKey}
                        data={parcellaireState.transformedGeoJson}
                        bubblingMouseEvents={false}
                        onEachFeature={geoJsonOnEachFeature}
                        pointToLayer={geoJsonPointToLayer}
                    />
                    {selectedMetadata && (
                        <GeoJSON
                            key={`${geoJsonKey}-text`}
                            data={{
                                type: "FeatureCollection",
                                features:
                                    parcellaireState.transformedGeoJson.features.map(
                                        (feature) => ({
                                            type: "Feature",
                                            geometry: {
                                                type: "Point",
                                                coordinates:
                                                    geoJSONUtil.getCenterOfFeature(
                                                        feature
                                                    ),
                                            },
                                            properties: feature.properties,
                                        })
                                    ),
                            }}
                            pointToLayer={geoJsonTextPointToLayer}
                        />
                    )}
                </>
            )}
            {parcellaireState.metadata && (
                <WMSTileLayer
                    key={`cloverfield:${parcellaireState.metadata.site.id}-${parcellaireState.metadata.date}-${orthoimageName}`}
                    layers={`cloverfield:${parcellaireState.metadata.site.id}-${parcellaireState.metadata.date}-${orthoimageName}`}
                    url={process.env.REACT_APP_GEOSERVER_URL}
                    format="image/png"
                    transparent
                    opacity={1}
                    maxZoom={25}
                    tiled
                />
            )}
        </>
    );
};
