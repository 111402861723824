import { BACKEND_ROUTES } from "../../../backendRoutes";
import { SYSTEMS } from "../../../constants";
import { useLiteralSite } from "./useLiteralSite";
import { useMemo } from "react";
import useSWR from "swr";

export const useLiteralRequests = () => {
    const siteResponse = useLiteralSite();

    const {
        data: pipelineTemplates,
        error: pipelineTemplatesError,
        ...response
    } = useSWR(
        siteResponse.data
            ? `${BACKEND_ROUTES.PIPELINE_TEMPLATE}?parentInfo=true&contractUuid=${siteResponse.data.contractUuid}&sort=name`
            : null
    );

    const literalPipelineTemplates = useMemo(
        () =>
            pipelineTemplates?.rows.filter(
                (PT) =>
                    PT.AcquisitionVector.SystemModel.system === SYSTEMS.LITERAL
            ),
        [pipelineTemplates]
    );

    return [siteResponse, { ...response, data: literalPipelineTemplates }];
};
