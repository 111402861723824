import { Box } from "@mui/material";
import PropTypes from "prop-types";

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};
export function TabPanel({ children, value, index, boxStyle, ...other }) {
    return (
        <div role="tabpanel" hidden={value !== index} {...other}>
            {value === index && <Box sx={boxStyle}>{children}</Box>}
        </div>
    );
}
