import {
    deleteLayers,
    epsgConverter,
    getSelectableMetadata,
    replaceMapDataGeoJson,
} from "./utils";

import { DYNAMIC_FEATURE_ID_FIELD_NAME } from "./constants";
import L from "leaflet";
import proj4 from "proj4";
import slugify from "slugify";

export function parcellaireCreationSubmitNoImport(
    payload,
    setIsOpen,
    site,
    parcellaireDispatch,
    map,
    setGeoJsonKey,
    setSelectableMetadata
) {
    const usedEPSG = epsgConverter(payload.latitude, payload.longitude);

    const slugifiedSiteName = site ? slugify(site.name, "_") : null;

    proj4.defs(usedEPSG.epsgCode, usedEPSG.epsgString);
    // most of the logic here comes from another tool that I edited slightly
    if (!payload.xSpacing) payload.xSpacing = 0;
    if (!payload.ySpacing) payload.ySpacing = 0;

    const createdParcellaire = { type: "FeatureCollection", features: [] };

    const positionTable = [];

    for (let i = 0; i < payload.xCount * payload.yCount; i++) {
        const tempX = i % payload.xCount;
        const tempY = Math.floor(i / payload.xCount);
        positionTable[i] = {
            x: tempX * payload.xSize + tempX * payload.xSpacing,
            y: tempY * payload.ySize + tempY * payload.ySpacing,
            xIndex: tempX + 1,
            yIndex: tempY + 1,
        };
    }

    const [xCenter, yCenter] = proj4("WGS84", usedEPSG.epsgCode, [
        parseFloat(payload.longitude),
        parseFloat(payload.latitude),
    ]); // base longitude and latitude transformed in meters

    const trueAngle = (payload.angle / 180) * Math.PI;
    const xDirection = [Math.cos(trueAngle), Math.sin(trueAngle)];
    const yDirection = [-Math.sin(trueAngle), Math.cos(trueAngle)];

    const meters_to_WGS84 = (x, y) => {
        return proj4(usedEPSG.epsgCode, "WGS84", [
            xCenter + x * xDirection[0] + y * yDirection[0],
            yCenter + x * xDirection[1] + y * yDirection[1],
        ]);
    };

    positionTable.forEach((position, index) => {
        const [x1, x2, y1, y2] = [
            position.x,
            position.x + payload.xSize,
            position.y,
            position.y + payload.ySize,
        ];
        const firstPointCoord = meters_to_WGS84(x1, y1); // this is defined in a const because it's used twice and we don't want to calculate it twice
        createdParcellaire.features.push({
            type: "Feature",
            properties: site
                ? {
                      [DYNAMIC_FEATURE_ID_FIELD_NAME]: index,
                      experiment: slugifiedSiteName,
                      name: `${slugifiedSiteName}_X${position.xIndex}_Y${position.yIndex}`,
                      x: position.xIndex,
                      y: position.yIndex,
                  }
                : {
                      [DYNAMIC_FEATURE_ID_FIELD_NAME]: index,
                      plot_id: `default_X${position.xIndex}_Y${position.yIndex}`,
                      x: position.xIndex,
                      y: position.yIndex,
                  },
            geometry: {
                type: "Polygon",
                coordinates: [
                    [
                        firstPointCoord,
                        meters_to_WGS84(x2, y1),
                        meters_to_WGS84(x2, y2),
                        meters_to_WGS84(x1, y2),
                        firstPointCoord,
                    ],
                ],
            },
        });
    });

    replaceMapDataGeoJson(parcellaireDispatch, createdParcellaire, map);

    // removing coord marker
    deleteLayers(map, (layer) => layer instanceof L.Marker);

    setIsOpen(false);

    setSelectableMetadata(
        getSelectableMetadata(site ? ["name", "x", "y"] : ["x", "y"])
    );

    setGeoJsonKey(() => {
        return new Date().getTime();
    });
}
