import { Box, Stack } from "@mui/material";

import { MAP_TILE_LAYERS } from "./constants";
import PropTypes from "prop-types";

export const TileLayerControl = ({
    selectedTileLayer,
    setSelectedTileLayer,
}) => {
    return (
        <Stack direction="row" spacing={1}>
            {Object.values(MAP_TILE_LAYERS).map((layer) => (
                <Box
                    key={layer.name}
                    sx={{
                        ...(selectedTileLayer === layer && {
                            outlineWidth: 2,
                            outlineStyle: "solid",
                            outlineColor: "error.main",
                        }),
                        height: "22px",
                        width: "54px",
                        borderRadius: 1,
                        backgroundPosition: "center center",
                        backgroundSize: "cover",
                        backgroundImage: `url("${layer.file}")`,
                    }}
                    onClick={() => setSelectedTileLayer(layer)}
                />
            ))}
        </Stack>
    );
};

TileLayerControl.propTypes = {
    selectedTileLayer: PropTypes.shape({
        name: PropTypes.string.isRequired,
    }).isRequired,
    setSelectedTileLayer: PropTypes.func.isRequired,
};
