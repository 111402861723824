import { Controller, useFormContext, useWatch } from "react-hook-form";
import {
    DEADLINE_TYPE,
    INPUT_VALIDATION,
    PLOT_MAP_PROVIDER,
    SYSTEMS,
} from "../../../constants";
import {
    Divider,
    Grid,
    MenuItem,
    TextField,
    Tooltip,
    Typography,
} from "@mui/material";
import {
    useAcquisitionVectors,
    useCrops,
    useSystemModels,
} from "../../../hooks";

import InfoIcon from "@mui/icons-material/Info";
import PropTypes from "prop-types";
import { objectArrayStringify } from "../../utils";

const systemValues = Object.values(SYSTEMS);
const plotMapProviderValues = Object.values(PLOT_MAP_PROVIDER);
const deadlineTypeValues = Object.values(DEADLINE_TYPE);

PipelineTemplateDataForm.propTypes = {
    hasNoFocalLength: PropTypes.bool,
};

export function PipelineTemplateDataForm({ hasNoFocalLength }) {
    const {
        formState: { errors },
        register,
        reset,
    } = useFormContext();
    const [selectedSystemModelUuid, selectedSystem] = useWatch({
        name: ["systemModelUuid", "system"],
    });

    // loading and errors handled in parent component
    const {
        data: { rows: acquisitionVectors },
    } = useAcquisitionVectors();
    const {
        data: { rows: crops },
    } = useCrops();
    const {
        data: { rows: systemModels },
    } = useSystemModels();

    return (
        <>
            <Grid container item spacing={1} xs={12}>
                <Grid item xs={4}>
                    <TextField
                        id="name"
                        fullWidth
                        type="text"
                        label="Template name *"
                        error={Boolean(errors.name)}
                        helperText={errors.name?.message}
                        {...register("name", {
                            required: INPUT_VALIDATION.REQUIRED,
                        })}
                    />
                </Grid>
                <Grid item xs={1}>
                    <Tooltip
                        title={
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <Typography>
                                        A template name helps to describe the
                                        group of field trials to which your
                                        traits selection will be applied. Hence,
                                        during the campaign, when you start
                                        uploading data, these template names
                                        will be associated to each acquisition
                                        date to indicate which traits to
                                        compute.
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography>
                                        Keep it short and simple and as
                                        descriptive as possible.
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography>Examples:</Typography>
                                    <Typography sx={{ fontStyle: "italic" }}>
                                        - "Trial sites in Asia"
                                    </Typography>
                                    <Typography sx={{ fontStyle: "italic" }}>
                                        - "Trial sites in Europe"
                                    </Typography>
                                    <Typography sx={{ fontStyle: "italic" }}>
                                        - "Early sowing experiment"
                                    </Typography>
                                    <Typography sx={{ fontStyle: "italic" }}>
                                        - "Irrigation stress experiment"
                                    </Typography>
                                    <Typography sx={{ fontStyle: "italic" }}>
                                        - "Sunflower high-resolution in Asia"
                                    </Typography>
                                    <Typography sx={{ fontStyle: "italic" }}>
                                        - "Sunflower high-resolution in Europe"
                                    </Typography>
                                </Grid>
                            </Grid>
                        }
                    >
                        <InfoIcon fontSize="small" sx={{ color: "grey.500" }} />
                    </Tooltip>
                </Grid>
            </Grid>

            <Grid item xs={6}>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Typography>Data source</Typography>
                        <Divider />
                    </Grid>
                    <Grid item xs={6}>
                        <Controller
                            name="cropUuid"
                            render={({
                                field: { ref, onChange, ...fieldProps },
                            }) => (
                                <TextField
                                    {...fieldProps}
                                    fullWidth
                                    id="cropUuid"
                                    select
                                    label="Crop *"
                                    inputRef={ref}
                                    error={Boolean(errors.cropUuid)}
                                    helperText={errors.cropUuid?.message}
                                    onChange={(event) => {
                                        onChange(event.target.value);
                                        reset((formValues) => ({
                                            ...formValues,
                                            canopyDevelopment: [],
                                            biomassProxy: [],
                                            trialQuality: [],
                                            harvestIndex: [],
                                            plantStress: [],
                                            bbchTraits: {},
                                        }));
                                    }}
                                >
                                    {crops.map((crop) => (
                                        <MenuItem
                                            key={crop.uuid}
                                            value={crop.uuid}
                                        >
                                            {crop.name}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            )}
                            rules={{
                                required: INPUT_VALIDATION.REQUIRED,
                            }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Controller
                            name="system"
                            render={({
                                field: { ref, onChange, ...fieldProps },
                            }) => (
                                <TextField
                                    {...fieldProps}
                                    fullWidth
                                    id="system"
                                    select
                                    label="System *"
                                    inputRef={ref}
                                    error={Boolean(errors.system)}
                                    helperText={errors.system?.message}
                                    onChange={(event) => {
                                        // Need to set dependent fields to blank to avoid inconsistency
                                        onChange(event.target.value);
                                        reset((formValues) => ({
                                            ...formValues,
                                            systemModelUuid: "",
                                            acquisitionVectorUuid: "",
                                            canopyDevelopment: [],
                                            biomassProxy: [],
                                            trialQuality: [],
                                            harvestIndex: [],
                                            plantStress: [],
                                            bbchTraits: {},
                                        }));
                                    }}
                                >
                                    {systemValues.map((system) => (
                                        <MenuItem key={system} value={system}>
                                            {system}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            )}
                            rules={{
                                required: INPUT_VALIDATION.REQUIRED,
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Controller
                            name="systemModelUuid"
                            render={({
                                field: { ref, onChange, ...fieldProps },
                            }) => (
                                <TextField
                                    {...fieldProps}
                                    fullWidth
                                    id="systemModelUuid"
                                    select
                                    disabled={!selectedSystem}
                                    label="System model *"
                                    inputRef={ref}
                                    error={Boolean(errors.systemModelUuid)}
                                    helperText={errors.systemModelUuid?.message}
                                    onChange={(event) => {
                                        // Need to set dependent fields to blank to avoid inconsistency
                                        onChange(event.target.value);
                                        reset((formValues) => ({
                                            ...formValues,
                                            acquisitionVectorUuid: "",
                                            canopyDevelopment: [],
                                            biomassProxy: [],
                                            trialQuality: [],
                                            harvestIndex: [],
                                            plantStress: [],
                                            bbchTraits: {},
                                        }));
                                    }}
                                >
                                    {systemModels
                                        .filter(
                                            (systemModel) =>
                                                systemModel.system ===
                                                selectedSystem
                                        )
                                        .map((systemModel) => (
                                            <MenuItem
                                                key={systemModel.uuid}
                                                value={systemModel.uuid}
                                            >
                                                {systemModel.name}
                                            </MenuItem>
                                        ))}
                                </TextField>
                            )}
                            rules={{
                                required: INPUT_VALIDATION.REQUIRED,
                            }}
                        />
                    </Grid>
                    <Grid item xs={hasNoFocalLength ? 7 : 12}>
                        <Controller
                            name="acquisitionVectorUuid"
                            render={({
                                field: { ref, onChange, ...fieldProps },
                            }) => (
                                <TextField
                                    {...fieldProps}
                                    fullWidth
                                    id="acquisitionVectorUuid"
                                    select
                                    disabled={!selectedSystemModelUuid}
                                    label="Acquisition Vector *"
                                    inputRef={ref}
                                    error={Boolean(
                                        errors.acquisitionVectorUuid
                                    )}
                                    helperText={
                                        errors.acquisitionVectorUuid?.message
                                    }
                                    onChange={(event) => {
                                        // Need to set dependent fields to blank to avoid inconsistency
                                        onChange(event.target.value);
                                        reset((formValues) => ({
                                            ...formValues,
                                            canopyDevelopment: [],
                                            biomassProxy: [],
                                            trialQuality: [],
                                            harvestIndex: [],
                                            plantStress: [],
                                            bbchTraits: {},
                                        }));
                                    }}
                                >
                                    {acquisitionVectors
                                        .filter(
                                            (acquisitionVector) =>
                                                acquisitionVector.systemModelUuid ===
                                                selectedSystemModelUuid
                                        )
                                        .map((acquisitionVector) => (
                                            <MenuItem
                                                key={acquisitionVector.uuid}
                                                value={acquisitionVector.uuid}
                                            >
                                                {acquisitionVector.name} (
                                                {objectArrayStringify(
                                                    acquisitionVector.SensorBundles,
                                                    ["Sensor", "dataType"]
                                                )}
                                                )
                                            </MenuItem>
                                        ))}
                                </TextField>
                            )}
                            rules={{
                                required: INPUT_VALIDATION.REQUIRED,
                            }}
                        />
                    </Grid>
                    {hasNoFocalLength && (
                        <Grid item xs={5}>
                            <TextField
                                id="focalLength35mmEqv"
                                fullWidth
                                type="number"
                                label="Focal Length (35mm eqv) *"
                                error={Boolean(errors.focalLength35mmEqv)}
                                helperText={errors.focalLength35mmEqv?.message}
                                {...register("focalLength35mmEqv", {
                                    min: {
                                        value: 0.01,
                                        message: INPUT_VALIDATION.POSITIVE,
                                    },
                                    required: INPUT_VALIDATION.REQUIRED_SHORT,
                                    valueAsNumber: true,
                                    shouldUnregister: true,
                                })}
                                inputProps={{
                                    step: "0.01",
                                    inputMode: "decimal",
                                }}
                            />
                        </Grid>
                    )}
                </Grid>
            </Grid>

            <Grid item xs={3}>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Typography>Data delivery</Typography>
                        <Divider />
                    </Grid>

                    <Grid item xs={12}>
                        <Controller
                            name="deadlineType"
                            render={({ field: { ref, ...fieldProps } }) => (
                                <TextField
                                    {...fieldProps}
                                    fullWidth
                                    id="deadlineType"
                                    select
                                    label="Delivery timing *"
                                    inputRef={ref}
                                    error={Boolean(errors.deadlineType)}
                                    helperText={errors.deadlineType?.message}
                                >
                                    {deadlineTypeValues.map((deadlineType) => (
                                        <MenuItem
                                            key={deadlineType.value}
                                            value={deadlineType.value}
                                        >
                                            {deadlineType.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            )}
                            rules={{
                                required: INPUT_VALIDATION.REQUIRED,
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Controller
                            name="plotMapProvider"
                            render={({ field: { ref, ...fieldProps } }) => (
                                <TextField
                                    {...fieldProps}
                                    fullWidth
                                    id="plotMapProvider"
                                    select
                                    label="Plot map provider *"
                                    inputRef={ref}
                                    error={Boolean(errors.plotMapProvider)}
                                    helperText={errors.plotMapProvider?.message}
                                >
                                    {plotMapProviderValues.map(
                                        (plotMapProvider) => (
                                            <MenuItem
                                                key={plotMapProvider.value}
                                                value={plotMapProvider.value}
                                            >
                                                {plotMapProvider.label}
                                            </MenuItem>
                                        )
                                    )}
                                </TextField>
                            )}
                            rules={{
                                required: INPUT_VALIDATION.REQUIRED,
                            }}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}
