import { useBBCHStages, useTraitGroupDataTypes } from "../../../hooks";

import { TRAIT_GROUP_CATEGORY } from "../../../constants";
import { useMemo } from "react";

/**
 * If isCreation true and no pipelineTemplate: we are creating a new pipeline template.
 * If isCreation true and pipelineTemplate provided: we are cloning an existing pipeline template.
 * If isCreation false: pipelineTemplate must be provided and we are modifying an existing pipeline template.
 */
export const useDefaultValues = ({ isCreation, pipelineTemplate }) => {
    const {
        data: { rows: bbchStages },
    } = useBBCHStages();
    const {
        data: { rows: traitGroupDataTypes },
    } = useTraitGroupDataTypes();

    return useMemo(() => {
        // If isCreation false, pipelineTemplate must be provided
        if (!isCreation && !pipelineTemplate)
            throw new TypeError(
                "pipelineTemplate must be provided if isCreation is false"
            );

        /**
         * bbchTraitArrays is an object whose keys are a traitGroupDataTypeUuid, with each value corresponding to an array of bbchStageUuids.
         * bbchTraitArrays={[traitGroupDataTypeUuid]: [bbch1, bbch2, etc.]}
         */
        const bbchTraitsValue = pipelineTemplate
            ? pipelineTemplate.PipelineTemplateTraitGroups.reduce(
                  (previous, PTTG) => {
                      if (previous[PTTG.traitGroupDataTypeUuid]) {
                          previous[PTTG.traitGroupDataTypeUuid].push(
                              PTTG.bbchStageUuid
                          );
                      } else {
                          previous[PTTG.traitGroupDataTypeUuid] = [
                              PTTG.bbchStageUuid,
                          ];
                      }
                      return previous;
                  },
                  {}
              )
            : {};

        /**
         * flightSequences is an object where each key is a bbch stage uuid and the value is the corresponding sequence value.
         * The default value will have a key for each bbch stage, whose value then comes from the pipeline template's flightSequences,
         * or an empty string if it does not exist.
         */
        const flightSequences = Object.fromEntries(
            bbchStages.map((bbchStage) => {
                const sequence =
                    pipelineTemplate?.FlightSequences.find(
                        (flightSequence) =>
                            flightSequence.bbchStageUuid === bbchStage.uuid
                    )?.sequence ?? "";
                return [bbchStage.uuid, sequence];
            })
        );

        return pipelineTemplate
            ? {
                  name: isCreation
                      ? `${pipelineTemplate.name} - Clone`
                      : pipelineTemplate.name,
                  cropUuid: pipelineTemplate.cropUuid,
                  system: pipelineTemplate.AcquisitionVector.SystemModel.system,
                  systemModelUuid:
                      pipelineTemplate.AcquisitionVector.SystemModel.uuid,
                  acquisitionVectorUuid: pipelineTemplate.acquisitionVectorUuid,
                  deadlineType: pipelineTemplate.deadlineType,
                  plotMapProvider: pipelineTemplate.plotMapProvider,
                  focalLength35mmEqv: pipelineTemplate.focalLength35mmEqv,
                  /**
                   * canopyDevelopment, biomassProxy, trialQuality, harvestIndex, and plantStress, are arrays of selected trait group data types, per trait category
                   * canopyDevelopment contains all selected trait group data types for the category "Canopy Development", and so on.
                   * */
                  canopyDevelopment: traitGroupDataTypes.filter(
                      (traitGroupDataType) =>
                          traitGroupDataType.TraitGroup.category ===
                              TRAIT_GROUP_CATEGORY.CANOPY_DEVELOPMENT &&
                          pipelineTemplate.PipelineTemplateTraitGroups.find(
                              (PTTG) =>
                                  PTTG.traitGroupDataTypeUuid ===
                                  traitGroupDataType.uuid
                          )
                  ),
                  biomassProxy: traitGroupDataTypes.filter(
                      (traitGroupDataType) =>
                          traitGroupDataType.TraitGroup.category ===
                              TRAIT_GROUP_CATEGORY.BIOMASS_PROXY &&
                          pipelineTemplate.PipelineTemplateTraitGroups.find(
                              (PTTG) =>
                                  PTTG.traitGroupDataTypeUuid ===
                                  traitGroupDataType.uuid
                          )
                  ),
                  trialQuality: traitGroupDataTypes.filter(
                      (traitGroupDataType) =>
                          traitGroupDataType.TraitGroup.category ===
                              TRAIT_GROUP_CATEGORY.TRIAL_QUALITY &&
                          pipelineTemplate.PipelineTemplateTraitGroups.find(
                              (PTTG) =>
                                  PTTG.traitGroupDataTypeUuid ===
                                  traitGroupDataType.uuid
                          )
                  ),
                  harvestIndex: traitGroupDataTypes.filter(
                      (traitGroupDataType) =>
                          traitGroupDataType.TraitGroup.category ===
                              TRAIT_GROUP_CATEGORY.HARVEST_INDEX_AND_QUALITY &&
                          pipelineTemplate.PipelineTemplateTraitGroups.find(
                              (PTTG) =>
                                  PTTG.traitGroupDataTypeUuid ===
                                  traitGroupDataType.uuid
                          )
                  ),
                  plantStress: traitGroupDataTypes.filter(
                      (traitGroupDataType) =>
                          traitGroupDataType.TraitGroup.category ===
                              TRAIT_GROUP_CATEGORY.PLANT_STRESS &&
                          pipelineTemplate.PipelineTemplateTraitGroups.find(
                              (PTTG) =>
                                  PTTG.traitGroupDataTypeUuid ===
                                  traitGroupDataType.uuid
                          )
                  ),
                  bbchTraits: bbchTraitsValue,
                  flightSequences,
                  presetUuid: pipelineTemplate.presetUuid || "",
              }
            : {
                  name: "",
                  cropUuid: "",
                  system: "",
                  systemModelUuid: "",
                  acquisitionVectorUuid: "",
                  deadlineType: "",
                  plotMapProvider: "",
                  focalLength35mmEqv: null,
                  bbchTraits: bbchTraitsValue,
                  canopyDevelopment: [],
                  biomassProxy: [],
                  trialQuality: [],
                  harvestIndex: [],
                  plantStress: [],
                  flightSequences,
                  presetUuid: "",
              };
    }, [pipelineTemplate, traitGroupDataTypes, bbchStages, isCreation]);
};
