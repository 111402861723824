import { Grid, Typography } from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import { MissionProtocolSensorData } from "./MissionProtocolSensorData";
import { MissionProtocolStageTable } from "./MissionProtocolStageTable";
import PropTypes from "prop-types";
import WarningIcon from "@mui/icons-material/Warning";

MissionProtocolDetails.propTypes = {
    pipelineTemplate: PropTypes.object.isRequired,
    bbchStageData: PropTypes.object.isRequired,
    traitGroupDataTypeArrays: PropTypes.object.isRequired,
    gsdPerStage: PropTypes.array.isRequired,
    minGSD: PropTypes.object.isRequired,
    displayTraits: PropTypes.bool.isRequired,
    maxTraitCount: PropTypes.number.isRequired,
    printed: PropTypes.bool,
};

export function MissionProtocolDetails({
    pipelineTemplate,
    bbchStageData,
    TGDTSortedByDatatype,
    gsdPerStage,
    minGSD,
    displayTraits,
    maxTraitCount,
    printed = false,
}) {
    return (
        <Grid container spacing={5}>
            <Grid container spacing={2} item xs={12}>
                <Grid item xs={3}>
                    <MissionProtocolSensorData
                        pipelineTemplate={pipelineTemplate}
                    />
                </Grid>

                <Grid item xs={9}>
                    <MissionProtocolStageTable
                        pipelineTemplate={pipelineTemplate}
                        bbchStageData={bbchStageData}
                        TGDTSortedByDatatype={TGDTSortedByDatatype}
                        gsdPerStage={gsdPerStage}
                        minGSD={minGSD}
                        displayTraits={displayTraits}
                        maxTraitCount={maxTraitCount}
                        printed={printed}
                    />
                </Grid>
            </Grid>
            <Grid container item spacing={2} xs={12}>
                <Grid item xs={12}>
                    <Typography>
                        <WarningIcon color="warning" /> means the targeted
                        flight altitude is slightly lower than the minimum
                        altitude the selected UAV can fly at. The trait will
                        still be calculated.
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography>
                        <CloseIcon color="error" /> means the targeted flight
                        altitude is much lower than the minimum altitude the
                        selected UAV can fly at. The trait will not be
                        calculated.
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography>
                        Minimum overlap expected should be 75% (side overlap) /
                        80% (front overlap) if the drone allows it.
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography>
                        Minimum number of ground control points (GCP) should be
                        6 per hectare (or 6 per 2 acres).
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography>
                        For plant count, plant leaves should not overlap each
                        other so that individual plants are clearly
                        distinguishable.
                    </Typography>
                </Grid>
            </Grid>
        </Grid>
    );
}
