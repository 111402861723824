import {
    Button,
    DialogActions,
    Divider,
    FormControlLabel,
    Grid,
    InputAdornment,
    Switch,
    TextField,
    Typography,
} from "@mui/material";
import { Controller, useForm, useFormContext } from "react-hook-form";
import { epsgConverter, readCSVToArray } from "./utils";

import AddCircleIcon from "@mui/icons-material/AddCircle";
import DoneIcon from "@mui/icons-material/Done";
import { INPUT_VALIDATION } from "../../constants";
import PropTypes from "prop-types";
import { parcellaireCreationSubmitImport } from "./parcellaireCreationSubmitImport";
import proj4 from "proj4";
import { useEffect } from "react";
import { useSnackbar } from "../../hooks";

ParcellaireCreationFormImport.propTypes = {
    map: PropTypes.any,
    parcellaireDispatch: PropTypes.func.isRequired,
    setBlueRectangleData: PropTypes.func.isRequired,
    setGeoJsonKey: PropTypes.func.isRequired,
    setIsOpen: PropTypes.func.isRequired,
    setSelectableMetadata: PropTypes.func.isRequired,
};

export function ParcellaireCreationFormImport({
    map,
    parcellaireDispatch,
    setBlueRectangleData,
    setGeoJsonKey,
    setIsOpen,
    setSelectableMetadata,
}) {
    const { openSnackbar } = useSnackbar();

    const { setValue: setValueOuterForm } = useFormContext();

    const {
        control,
        watch,
        register,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm({
        defaultValues: {
            csvData: null,
            blueRectangle: null,
            xSize: "",
            ySize: "",
            xSpacing: "",
            ySpacing: "",
            xAngle: 0,
            yAngle: 0,
            usedEPSG: {},
            o: [],
            x: [],
            y: [],
            reverseSizes: false,
            sizeScaling: false,
            blockName: null,
        },
    });

    const [
        csvDataWatch,
        blueRectangleWatch,
        xSizeWatch,
        ySizeWatch,
        sizeScalingWatch,
        reverseSizesWatch,
    ] = watch([
        "csvData",
        "blueRectangle",
        "xSize",
        "ySize",
        "sizeScaling",
        "reverseSizes",
    ]);

    useEffect(() => {
        if (blueRectangleWatch) {
            const defaultCoord = blueRectangleWatch.features.find(
                (feature) => feature.id === "O"
            ).geometry.coordinates;
            const xCoord = blueRectangleWatch.features.find(
                (feature) => feature.id === "X"
            ).geometry.coordinates;
            const yCoord = blueRectangleWatch.features.find(
                (feature) => feature.id === "Y"
            ).geometry.coordinates;
            const plotProperties = blueRectangleWatch.features.find(
                (feature) => feature.id === "Polygon"
            ).properties;

            const usedEPSG = epsgConverter(defaultCoord[1], defaultCoord[0]);

            proj4.defs(usedEPSG.epsgCode, usedEPSG.epsgString);

            const defaultCoordMeter = proj4(
                "WGS84",
                usedEPSG.epsgCode,
                defaultCoord
            );
            const xCoordMeter = proj4("WGS84", usedEPSG.epsgCode, xCoord);
            const yCoordMeter = proj4("WGS84", usedEPSG.epsgCode, yCoord);
            const usedAngleX = (
                ((Math.atan2(
                    xCoordMeter[1] - defaultCoordMeter[1],
                    xCoordMeter[0] - defaultCoordMeter[0]
                ) *
                    180) /
                    Math.PI +
                    360) %
                360
            ).toFixed(10);
            const usedAngleY = (
                ((Math.atan2(
                    yCoordMeter[1] - defaultCoordMeter[1],
                    yCoordMeter[0] - defaultCoordMeter[0]
                ) *
                    180) /
                    Math.PI +
                    360) %
                360
            ).toFixed(10);
            setValue("usedEPSG", usedEPSG);
            setValue("o", defaultCoord);
            setValue("x", xCoord);
            setValue("y", yCoord);
            setValue("xAngle", usedAngleX);
            setValue("yAngle", usedAngleY);
            setValue("xSize", plotProperties.plot_length);
            setValue("ySize", plotProperties.plot_width);
            setValue("blockName", plotProperties.block_name);
        }
    }, [blueRectangleWatch, setValue]);

    const onSubmit = (payload) => {
        parcellaireCreationSubmitImport(
            payload,
            setIsOpen,
            parcellaireDispatch,
            map,
            setGeoJsonKey,
            openSnackbar,
            false,
            null,
            setSelectableMetadata
        );
        setBlueRectangleData(payload.blueRectangle);

        setValueOuterForm("selectedMetadata", null);
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography>Data source</Typography>
                    <Divider />
                </Grid>
                <Grid container item xs={12} spacing={2} alignItems="center">
                    <Grid item xs="auto">
                        <Button
                            variant="contained"
                            component="label"
                            sx={{ textTransform: "none" }}
                        >
                            Import field book
                            <input
                                hidden
                                type="file"
                                accept=".csv"
                                onChange={(event) => {
                                    const csvFile = event.target.files[0];

                                    if (csvFile) {
                                        readCSVToArray(
                                            csvFile,
                                            setValue,
                                            openSnackbar,
                                            false
                                        );
                                    }
                                }}
                            />
                        </Button>
                    </Grid>
                    <Grid item xs="auto">
                        {csvDataWatch && <DoneIcon color="success" />}
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Typography>Location & Geometry</Typography>
                    <Divider />
                </Grid>
                <Grid container item xs={12} spacing={2} alignItems="center">
                    <Grid item xs="auto">
                        <Button
                            variant="contained"
                            component="label"
                            sx={{ textTransform: "none" }}
                        >
                            Import field map
                            <input
                                hidden
                                type="file"
                                accept=".geojson"
                                onChange={(event) => {
                                    const geoJsonFile = event.target.files[0];

                                    if (geoJsonFile) {
                                        const reader = new FileReader();

                                        reader.onload = (event) =>
                                            setValue(
                                                "blueRectangle",
                                                JSON.parse(event.target.result)
                                            );
                                        reader.readAsText(geoJsonFile);
                                    }
                                }}
                            />
                        </Button>
                    </Grid>
                    <Grid item xs="auto">
                        {blueRectangleWatch && <DoneIcon color="success" />}
                    </Grid>
                </Grid>
                {blueRectangleWatch && (
                    <Grid
                        container
                        item
                        xs={12}
                        spacing={2}
                        alignItems="center"
                    >
                        <Grid item xs={12}>
                            <Controller
                                control={control}
                                name="sizeScaling"
                                render={({
                                    field: { ref, value, ...fieldProps },
                                }) => (
                                    <FormControlLabel
                                        {...fieldProps}
                                        id="sizeScaling"
                                        name="sizeScaling"
                                        control={<Switch inputRef={ref} />}
                                        label="Use field map's plot size instead of O X Y coordinates"
                                        labelPlacement="end"
                                        checked={value}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Controller
                                control={control}
                                name="reverseSizes"
                                render={({
                                    field: {
                                        ref,
                                        onChange,
                                        name,
                                        ...fieldProps
                                    },
                                }) => (
                                    <FormControlLabel
                                        {...fieldProps}
                                        id="reverseSizes"
                                        name="reverseSizes"
                                        control={<Switch />}
                                        label={
                                            sizeScalingWatch
                                                ? "Swap length and width"
                                                : "Swap X and Y points"
                                        }
                                        labelPlacement="end"
                                        checked={reverseSizesWatch}
                                        onChange={(event) => {
                                            onChange(event.target.checked);
                                        }}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Typography>
                                Plot size:{" "}
                                {sizeScalingWatch
                                    ? reverseSizesWatch
                                        ? `${ySizeWatch}m x ${xSizeWatch}m`
                                        : `${xSizeWatch}m x ${ySizeWatch}m`
                                    : "Based on O X Y"}
                            </Typography>
                        </Grid>
                    </Grid>
                )}
                <Grid container item xs={12} spacing={2}>
                    <Grid item xs={6}>
                        <TextField
                            type="number"
                            label="X spacing"
                            id="xSpacing"
                            error={Boolean(errors.xSpacing)}
                            helperText={errors.xSpacing?.message}
                            {...register("xSpacing", {
                                required: INPUT_VALIDATION.REQUIRED,
                                valueAsNumber: true,
                                validate: (value) =>
                                    value >= 0 || INPUT_VALIDATION.POSITIVE,
                            })}
                            inputProps={{
                                step: "0.01",
                                inputMode: "decimal",
                            }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        m
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            type="number"
                            label="Y spacing"
                            id="ySpacing"
                            error={Boolean(errors.ySpacing)}
                            helperText={errors.ySpacing?.message}
                            {...register("ySpacing", {
                                required: INPUT_VALIDATION.REQUIRED,
                                valueAsNumber: true,
                                validate: (value) =>
                                    value >= 0 || INPUT_VALIDATION.POSITIVE,
                            })}
                            inputProps={{
                                step: "0.01",
                                inputMode: "decimal",
                            }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        m
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <DialogActions>
                <Button
                    variant="contained"
                    startIcon={<AddCircleIcon />}
                    type="submit"
                >
                    Create
                </Button>
            </DialogActions>
        </form>
    );
}
