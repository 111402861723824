import { GEOMAN_CUSTOM_TOGGLE_BUTTON_NAMES } from "../constants";
import { geoJSONUtil } from "./geoJSONUtil";
import { transformUtil } from "./transformUtil";

export function applyTransform(state, toggles, usedTransformModeVectors) {
    const finalTransformModeVectors =
        usedTransformModeVectors ?? state.transformModeVectors;

    const temporaryTransformedGeoJson = transformGeoJsonFromVectors(
        {
            ...state.checkpointGeoJsonData,
            features: state.selectedLayers.length
                ? state.selectedLayers.map((layer) => layer.feature)
                : state.checkpointGeoJsonData.features.filter(
                      (feature) =>
                          !state.lockedLayers.find(
                              (lockedLayer) =>
                                  geoJSONUtil.getIdOfFeature(feature) ===
                                  geoJSONUtil.getIdOfFeature(
                                      lockedLayer.feature
                                  )
                          )
                  ),
        },
        finalTransformModeVectors,
        toggles
    );

    if (state.selectedLayers.length) {
        state.transformedGeoJson.features.forEach((oldFeature) => {
            if (
                !state.selectedLayers.find(
                    (selectedLayer) =>
                        geoJSONUtil.getIdOfFeature(selectedLayer.feature) ===
                        geoJSONUtil.getIdOfFeature(oldFeature)
                )
            )
                temporaryTransformedGeoJson.features.push(oldFeature);
        });
    } else if (state.lockedLayers.length) {
        state.transformedGeoJson.features.forEach((oldFeature) => {
            if (
                state.lockedLayers.find(
                    (lockedLayer) =>
                        geoJSONUtil.getIdOfFeature(lockedLayer.feature) ===
                        geoJSONUtil.getIdOfFeature(oldFeature)
                )
            )
                temporaryTransformedGeoJson.features.push(oldFeature);
        });
    }

    return {
        ...state,
        transformedGeoJson: temporaryTransformedGeoJson,
        transformModeVectors: finalTransformModeVectors,
    };
}

function transformGeoJsonFromVectors(
    geoJSON,
    transformModeVectors,
    toggles = [GEOMAN_CUSTOM_TOGGLE_BUTTON_NAMES.LOCKED_SHAPE_MODE]
) {
    const buttons = {
        singleTarget: toggles.includes(
            GEOMAN_CUSTOM_TOGGLE_BUTTON_NAMES.SINGLE_TARGET_MODE
        ),
        shift: toggles.includes(GEOMAN_CUSTOM_TOGGLE_BUTTON_NAMES.SHIFT_MODE),
        lockedShape: toggles.includes(
            GEOMAN_CUSTOM_TOGGLE_BUTTON_NAMES.LOCKED_SHAPE_MODE
        ),
    };
    if (
        !buttons.singleTarget &&
        (transformModeVectors.length >= 2 ||
            (buttons.shift && transformModeVectors.length >= 1))
    ) {
        const oldCenters = [];
        const newCenters = [];
        transformModeVectors.forEach((vector) => {
            const feature = geoJSONUtil.findFeatureById(
                geoJSON,
                vector.shapeObjectId
            );
            const oldCenter = geoJSONUtil.getCenterOfFeature(feature);
            oldCenters.push(oldCenter);
            const newGeometry = geoJSONUtil.shiftGeometry(
                feature.geometry,
                vector.longitude,
                vector.latitude
            );
            const newCenter = geoJSONUtil.getCenterOfGeometry(newGeometry);
            newCenters.push(newCenter);
        });

        // const { transform, referenceCoord } = TransformUtil.calculateAffineTransform(oldCenters, newCenters)
        const { transform, referenceCoord } = transformUtil.calculateTransform(
            oldCenters,
            newCenters,
            null
        );
        const transformedGeoJSON = geoJSONUtil.transformGeoJSON(
            geoJSON,
            transform,
            referenceCoord,
            buttons.lockedShape
        );

        return transformedGeoJSON;
    } else {
        const transformedGeoJSON = geoJSONUtil.cloneGeoJSON(geoJSON);

        transformModeVectors.forEach((vector) => {
            const feature = geoJSONUtil.findFeatureById(
                transformedGeoJSON,
                vector.shapeObjectId
            );
            feature.geometry = geoJSONUtil.shiftGeometry(
                feature.geometry,
                vector.longitude,
                vector.latitude
            );
        });

        return transformedGeoJSON;
    }
}
