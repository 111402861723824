/**
 * This file is copied directly from the Slant Range repo,
 * except that "TransformUtil" (pascal case) has been renamed to "transformUtil" (camel case).
 */

import { EARTH_RADIUS } from "../constants";

const RADIANS_PER_DEGREE = 0.01745329251994; // (M_PI / 180.0)
const DEGREES_PER_RADIAN = 57.29577951308232; // (180.0 / M_PI)
const TRANSFORM_TYPE = {
    TRANSFORM_BEST_APPLICABLE: 0,
    TRANSFORM_SHIFT: 1,
    TRANSFORM_SIMILARITY: 2,
    TRANSFORM_AFFINE: 3,
};

export const transformUtil = {
    convertCoordToPoint(coord, referenceCoord) {
        const result = {};
        result.x =
            EARTH_RADIUS *
            Math.sin(RADIANS_PER_DEGREE * (coord[0] - referenceCoord[0])) *
            Math.cos(RADIANS_PER_DEGREE * referenceCoord[1]);
        result.y =
            EARTH_RADIUS *
            Math.sin(RADIANS_PER_DEGREE * (coord[1] - referenceCoord[1]));
        return result;
    },

    convertPointToCoord(point, referenceCoord) {
        const result = [];
        result[0] =
            referenceCoord[0] +
            DEGREES_PER_RADIAN *
                Math.asin(
                    point.x /
                        EARTH_RADIUS /
                        Math.cos(RADIANS_PER_DEGREE * referenceCoord[1])
                );
        result[1] =
            referenceCoord[1] +
            DEGREES_PER_RADIAN * Math.asin(point.y / EARTH_RADIUS);

        if (isNaN(result[0]) || isNaN(result[1])) throw new RangeError();

        return result;
    },

    dist(a, b) {
        const dy = b.y - a.y;
        const dx = b.x - a.x;
        return Math.sqrt(dy * dy + dx * dx);
    },

    isUnity(transform) {
        let sum = 0;
        for (let i = 0; i < 3; i++) {
            for (let j = 0; j < 3; j++) {
                sum += transform[i + 3 * j] === (i === j);
            }
        }
        return sum === 3;
    },

    getTriplets(count) {
        const result = [];
        for (let i = 0; i < count; i++) {
            for (let j = i + 1; j < count; j++) {
                for (let k = j + 1; k < count; k++) {
                    const t = { i, j, k };
                    result.push(t);
                }
            }
        }
        return result;
    },

    getDoublets(count) {
        const result = [];
        for (let i = 0; i < count; i++) {
            for (let j = i + 1; j < count; j++) {
                const d = { i, j };
                result.push(d);
            }
        }
        return result;
    },

    getSample(points, group) {
        let result;
        if (points.length >= 3) {
            if (Math.max(group.i, Math.max(group.j, group.k)) < points.length) {
                result = new Array(3);
                result[0] = { x: points[group.i].x, y: points[group.i].y };
                result[1] = { x: points[group.j].x, y: points[group.j].y };
                result[2] = { x: points[group.k].x, y: points[group.k].y };
            }
        } else if (points.length === 2) {
            if (Math.max(group.i, group.j) < points.length) {
                result = new Array(2);
                result[0] = { x: points[group.i].x, y: points[group.i].y };
                result[1] = { x: points[group.j].x, y: points[group.j].y };
            }
        } else {
            if (Math.max(group.i, Math.max(group.j, group.k)) < points.length) {
                result = new Array(1);
                result[0] = { x: points[group.i].x, y: points[group.i].y };
            }
        }
        return result;
    },

    tiePointsToExactAffineMatrix33(oldSample, newSample) {
        const transform = new Array(9);

        const dx10 = oldSample[1].x - oldSample[0].x;
        const dx20 = oldSample[2].x - oldSample[0].x;
        const dx21 = oldSample[2].x - oldSample[1].x;
        const dy10 = oldSample[1].y - oldSample[0].y;
        const dy20 = oldSample[2].y - oldSample[0].y;
        const dy21 = oldSample[2].y - oldSample[1].y;
        const du10 = newSample[1].x - newSample[0].x;
        const du20 = newSample[2].x - newSample[0].x;
        const du21 = newSample[2].x - newSample[1].x;
        const dv10 = newSample[1].y - newSample[0].y;
        const dv20 = newSample[2].y - newSample[0].y;
        const dv21 = newSample[2].y - newSample[1].y;

        // Calculate transform[0];
        let denT0 = dx10 * dy20 - dy10 * dx20;
        if (denT0 !== 0) {
            transform[0] = (du10 * dy20 - dy10 * du20) / denT0;
        } else {
            denT0 = dx10 * dy21 - dy10 * dx21;
            if (denT0 !== 0) {
                transform[0] = (du10 * dy21 - dy10 * du21) / denT0;
            } else {
                denT0 = dx20 * dy21 - dy20 * dx21;
                if (denT0 !== 0) {
                    transform[0] = (du20 * dy21 - dy20 * du21) / denT0;
                } else {
                    return;
                }
            }
        }
        // Calculate transform[1]:
        if (dy10 !== 0) {
            transform[1] = du10 / dy10 - (transform[0] * dx10) / dy10;
        } else {
            if (dy20 !== 0) {
                transform[1] = du20 / dy20 - (transform[0] * dx20) / dy20;
            } else {
                if (dy21 !== 0) {
                    transform[1] = du21 / dy21 - (transform[0] * dx21) / dy21;
                } else {
                    return;
                }
            }
        }
        // Calculate transform[2] (use one of three ways to do it):
        transform[2] =
            newSample[0].x -
            transform[0] * oldSample[0].x -
            transform[1] * oldSample[0].y;
        transform[2] =
            newSample[1].x -
            transform[0] * oldSample[1].x -
            transform[1] * oldSample[1].y;
        transform[2] =
            newSample[2].x -
            transform[0] * oldSample[2].x -
            transform[1] * oldSample[2].y;

        // Similarly, calculate transform[3], transform[4], and transform[5]:
        // Calculate transform[3];
        let denT3 = dx10 * dy20 - dy10 * dx20;
        if (denT3 !== 0) {
            transform[3] = (dv10 * dy20 - dy10 * dv20) / denT3;
        } else {
            denT3 = dx10 * dy21 - dy10 * dx21;
            if (denT3 !== 0) {
                transform[3] = (dv10 * dy21 - dy10 * dv21) / denT3;
            } else {
                denT3 = dx20 * dy21 - dy20 * dx21;
                if (denT3 !== 0) {
                    transform[3] = (dv20 * dy21 - dy20 * dv21) / denT3;
                } else {
                    return;
                }
            }
        }
        // Calculate transform[4]:
        if (dy10 !== 0) {
            transform[4] = dv10 / dy10 - (transform[3] * dx10) / dy10;
        } else {
            if (dy20 !== 0) {
                transform[4] = dv20 / dy20 - (transform[3] * dx20) / dy20;
            } else {
                if (dy21 !== 0) {
                    transform[4] = dv21 / dy21 - (transform[3] * dx21) / dy21;
                } else {
                    return;
                }
            }
        }
        // Calculate transform[5] (use one of three ways to do it):
        transform[5] =
            newSample[0].y -
            transform[3] * oldSample[0].x -
            transform[4] * oldSample[0].y;
        transform[5] =
            newSample[1].y -
            transform[3] * oldSample[1].x -
            transform[4] * oldSample[1].y;
        transform[5] =
            newSample[2].y -
            transform[3] * oldSample[2].x -
            transform[4] * oldSample[2].y;

        // We don't have perspective coefficients:
        transform[6] = transform[7] = 0;
        transform[8] = 1;

        // Done
        return transform;
    },

    tiePointsToExactSimilarityMatrix33(oldSample, newSample) {
        const transform = [0, 0, 0, 0, 0, 0, 0, 0, 0];

        // Shift:
        // const dX = ((newSample[0].x - oldSample[0].x) + (newSample[1].x - oldSample[1].x)) / 2
        // const dY = ((newSample[0].y - oldSample[0].y) + (newSample[1].y - oldSample[1].y)) / 2
        // Scale:
        const Dx = oldSample[1].x - oldSample[0].x;
        const Dy = oldSample[1].y - oldSample[0].y;
        const Du = newSample[1].x - newSample[0].x;
        const Dv = newSample[1].y - newSample[0].y;
        const Lxy = Math.sqrt(Dx * Dx + Dy * Dy);
        const Luv = Math.sqrt(Du * Du + Dv * Dv);
        const Scale = Luv / Lxy;

        // Rotation:
        // Cosine of angle between the vectors is dot product normalized by the product of lengths:
        const CosR = (Dx * Du + Dy * Dv) / (Lxy * Luv);
        // Sine of angle between the vectors is the 3rd component of cross product normalized by the product of lengths:
        const SinR = (Dx * Dv - Du * Dy) / (Lxy * Luv);

        // Now we can fill out the matrix coefficients:
        transform[0] = Scale * CosR;
        transform[1] = -Scale * SinR;
        transform[3] = Scale * SinR;
        transform[4] = Scale * CosR;
        // Calculate shift:
        transform[2] =
            newSample[0].x -
            (transform[0] * oldSample[0].x + transform[1] * oldSample[0].y);
        transform[5] =
            newSample[0].y -
            (transform[3] * oldSample[0].x + transform[4] * oldSample[0].y);
        transform[8] = 1.0;

        return transform;
    },

    transformPoint(transform, point) {
        const result = {};
        result.x =
            transform[0] * point.x + transform[1] * point.y + transform[2];
        result.y =
            transform[3] * point.x + transform[4] * point.y + transform[5];
        return result;
    },

    calculateTransformErrors(oldPoints, newPoints, transform) {
        // Bail out if any of tiepoint is empty:
        if (!(oldPoints.length || newPoints.length)) {
            return null;
        }

        // Bail out if sizes of tiepoint don't match:
        if (oldPoints.length !== newPoints.length) {
            return null;
        }

        let max = 0;
        let total = 0;
        for (let i = 0; i < oldPoints.length; i++) {
            const transformedPoint = this.transformPoint(
                transform,
                oldPoints[i]
            );
            const err = this.dist(transformedPoint, newPoints[i]);
            max = Math.max(max, err);
            total += err;
        }
        const mean = total / oldPoints.length;
        return { max, mean };
    },

    calculateAffineTransform2(oldPoints, newPoints) {
        // Create triplets of indices out of the vectors:
        const triplets = this.getTriplets(oldPoints.length);

        // Calculate xformdataation matrices for all three-point combinations:
        const matrices = [];
        for (let i = 0; i < triplets.length; i++) {
            const oldSample = this.getSample(oldPoints, triplets[i]);
            const newSample = this.getSample(newPoints, triplets[i]);
            if (oldSample && newSample) {
                const matrix = this.tiePointsToExactAffineMatrix33(
                    oldSample,
                    newSample
                );
                if (matrix) {
                    matrices.push(matrix);
                }
            }
        }

        // Calculate maximum and mean xformdataation errors applying all
        // exact three-tie-point xformdataation to the entire ensemble of
        // base coordinates and comparing the results to (given) warp coordinates:
        let maxMaxError = 0;
        let maxMeanError = 0;
        let maxMaxErrorIdx = Number.MAX_SAFE_INTEGER;
        // let maxMeanErrorIdx = Number.MAX_SAFE_INTEGER
        if (matrices.length > 1) {
            const meanErrors = [];
            const maxErrors = [];
            for (let i = 0; i < matrices.length; i++) {
                const { max, mean } = this.calculateTransformErrors(
                    oldPoints,
                    newPoints,
                    matrices[i]
                );
                maxErrors.push(max);
                meanErrors.push(mean);
                if (max > maxMaxError) {
                    maxMaxError = max;
                    maxMaxErrorIdx = i;
                }
                if (mean > maxMeanError) {
                    maxMeanError = mean;
                    // maxMeanErrorIdx = i
                }
            }
        }

        // Calculate the ensemble transformation matrix as an average of all individual
        // matrices except the one with the largest maxMaxError:
        const transform = [0, 0, 0, 0, 0, 0, 0, 0, 1];
        let count = 0;
        for (let i = 0; i < matrices.length; i++) {
            if (i !== maxMaxErrorIdx) {
                for (let k = 0; k < 6; k++) {
                    transform[k] += matrices[i][k];
                }
                count++;
            }
        }
        for (let k = 0; k < 6; k++) {
            transform[k] /= count;
        }

        // Calculate transformation errors:
        this.calculateTransformErrors(oldPoints, newPoints, transform);

        return { transform };
    },

    calculateSimilarityTransform(oldPoints, newPoints) {
        // Bail out if any of tiepoint is empty:
        if (!(oldPoints.length || newPoints.length)) {
            return null;
        }

        // Bail out if sizes of tiepoint don't match:
        if (oldPoints.length !== newPoints.length) {
            return null;
        }

        // Bail out if there are fewer than 2 tiepoints:
        if (newPoints.length < 2) {
            return null;
        }

        // Create doublets of indices out of the vectors:
        const doublets = this.getDoublets(oldPoints.length);

        // Calculate xformdataation matrices for all double-point combinations:
        const matrices = [];
        for (let i = 0; i < doublets.length; i++) {
            const oldSample = this.getSample(oldPoints, doublets[i]);
            const newSample = this.getSample(newPoints, doublets[i]);
            if (oldSample && newSample) {
                const matrix = this.tiePointsToExactSimilarityMatrix33(
                    oldSample,
                    newSample
                );
                if (matrix) {
                    matrices.push(matrix);
                }
            }
        }

        // Calculate maximum and mean xformdataation errors applying all
        // exact three-tie-point xformdataation to the entire ensemble of
        // base coordinates and comparing the results to (given) warp coordinates:
        let maxMaxError = 0;
        let maxMeanError = 0;
        let maxMaxErrorIdx = Number.MAX_SAFE_INTEGER;
        // let maxMeanErrorIdx = Number.MAX_SAFE_INTEGER
        if (matrices.length > 1) {
            const meanErrors = [];
            const maxErrors = [];
            for (let i = 0; i < matrices.length; i++) {
                const { max, mean } = this.calculateTransformErrors(
                    oldPoints,
                    newPoints,
                    matrices[i]
                );
                maxErrors.push(max);
                meanErrors.push(mean);
                if (max > maxMaxError) {
                    maxMaxError = max;
                    maxMaxErrorIdx = i;
                }
                if (mean > maxMeanError) {
                    maxMeanError = mean;
                    // maxMeanErrorIdx = i
                }
            }
        }

        // Calculate the ensemble transformation matrix as an average of all individual
        // matrices except the one with the largest maxMaxError:
        const transform = [0, 0, 0, 0, 0, 0, 0, 0, 1];
        let count = 0;
        for (let i = 0; i < matrices.length; i++) {
            if (i !== maxMaxErrorIdx) {
                for (let k = 0; k < 6; k++) {
                    transform[k] += matrices[i][k];
                }
                count++;
            }
        }
        for (let k = 0; k < 6; k++) {
            transform[k] /= count;
        }

        // Calculate transformation errors:
        const { meanErr, maxErr } = this.calculateTransformErrors(
            oldPoints,
            newPoints,
            transform
        );

        return { transform, meanErr, maxErr };
    },

    calculateAffineTransform(oldCoords, newCoords) {
        // Bail out if any of tiepoint is empty:
        if (!(oldCoords.length || newCoords.length)) {
            return null;
        }

        // Bail out if sizes of tiepoint don't match:
        if (oldCoords.length !== newCoords.length) {
            return null;
        }

        // Bail out if there are fewer than 3 tiepoints:
        if (newCoords.length < 3) {
            return null;
        }

        // Select a reference coordinates as the SouthWestern-most point in centers:
        const referenceCoord = [180, 90];
        oldCoords.forEach((coord) => {
            referenceCoord[0] = Math.min(referenceCoord[0], coord[0]);
            referenceCoord[1] = Math.min(referenceCoord[1], coord[1]);
        });
        newCoords.forEach((coord) => {
            referenceCoord[0] = Math.min(referenceCoord[0], coord[0]);
            referenceCoord[1] = Math.min(referenceCoord[1], coord[1]);
        });

        // Populate base and warp vectors (converting them to meters from the {LatMin,LonMin} point:
        const oldPoints = oldCoords.map((coord) =>
            this.convertCoordToPoint(coord, referenceCoord)
        );
        const newPoints = newCoords.map((coord) =>
            this.convertCoordToPoint(coord, referenceCoord)
        );

        // Calculate the affine transformation
        const { transform } = this.calculateAffineTransform2(
            oldPoints,
            newPoints
        );

        return { transform, referenceCoord };
    },

    calculateTransform(oldCoords, newCoords, transformType) {
        // Bail out if any of tiepoint is empty:
        if (!(oldCoords.length || newCoords.length)) {
            return null;
        }

        // Bail out if sizes of tiepoint don't match:
        if (oldCoords.length !== newCoords.length) {
            return null;
        }

        if (transformType == null) {
            transformType = TRANSFORM_TYPE.TRANSFORM_BEST_APPLICABLE;
        }

        // Calculate the minimum number of origin/update pairs:
        let minPairs = 1;
        if (transformType !== TRANSFORM_TYPE.TRANSFORM_BEST_APPLICABLE) {
            minPairs = transformType;
        }

        // Bail out if there are fewer than minPairs tiepoints:
        if (oldCoords.length < minPairs) {
            return null;
        }

        // Calculate the best transform applicable to the number of pairs:
        if (transformType === TRANSFORM_TYPE.TRANSFORM_BEST_APPLICABLE) {
            const key =
                Object.keys(TRANSFORM_TYPE)[Math.min(3, oldCoords.length)];
            transformType = TRANSFORM_TYPE[key];
        }

        // Select a reference coordinates as the SouthWestern-most point in centers:
        const referenceCoord = [180, 90];
        oldCoords.forEach((coord) => {
            referenceCoord[0] = Math.min(referenceCoord[0], coord[0]);
            referenceCoord[1] = Math.min(referenceCoord[1], coord[1]);
        });
        newCoords.forEach((coord) => {
            referenceCoord[0] = Math.min(referenceCoord[0], coord[0]);
            referenceCoord[1] = Math.min(referenceCoord[1], coord[1]);
        });

        // Populate base and warp vectors (converting them to meters from the {LatMin,LonMin} point:
        const oldPoints = oldCoords.map((coord) =>
            this.convertCoordToPoint(coord, referenceCoord)
        );
        const newPoints = newCoords.map((coord) =>
            this.convertCoordToPoint(coord, referenceCoord)
        );

        switch (transformType) {
            case TRANSFORM_TYPE.TRANSFORM_SHIFT: {
                const transform = [0, 0, 0, 0, 0, 0, 0, 0, 1];
                let dX = 0;
                let dY = 0;
                const count = newPoints.length;
                for (let i = 0; i < newPoints.length; i++) {
                    dX += (newPoints[i].x - oldPoints[i].x) / count;
                    dY += (newPoints[i].y - oldPoints[i].y) / count;
                }
                transform[0] = transform[4] = transform[8] = 1;
                transform[2] = dX;
                transform[5] = dY;

                return { transform, referenceCoord };
            }
            case TRANSFORM_TYPE.TRANSFORM_SIMILARITY: {
                const { transform } = this.calculateSimilarityTransform(
                    oldPoints,
                    newPoints
                );

                return { transform, referenceCoord };
            }
            case TRANSFORM_TYPE.TRANSFORM_AFFINE: {
                const { transform } = this.calculateAffineTransform2(
                    oldPoints,
                    newPoints
                );

                return { transform, referenceCoord };
            }
            default: {
                throw new Error("There should be a transform type specified.");
            }
        }
    },

    transformCoord(coord, transform, referenceCoord) {
        const oldPoint = this.convertCoordToPoint(coord, referenceCoord);
        const newPoint = this.transformPoint(transform, oldPoint);
        const result = this.convertPointToCoord(newPoint, referenceCoord);
        return result;
    },
};
