import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@mui/material";

export function BookingTableSkeleton() {
    return (
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }}>
                <TableHead>
                    <TableRow>
                        <TableCell
                            sx={{
                                bgcolor: (theme) => theme.palette.grey[210],
                            }}
                        >
                            System
                        </TableCell>
                        <TableCell
                            align="center"
                            sx={{
                                bgcolor: (theme) => theme.palette.grey[210],
                            }}
                        >
                            Jan.
                        </TableCell>
                        <TableCell
                            align="center"
                            sx={{
                                bgcolor: (theme) => theme.palette.grey[210],
                            }}
                        >
                            Feb.
                        </TableCell>
                        <TableCell
                            align="center"
                            sx={{
                                bgcolor: (theme) => theme.palette.grey[210],
                            }}
                        >
                            Mar.
                        </TableCell>
                        <TableCell
                            align="center"
                            sx={{
                                bgcolor: (theme) => theme.palette.grey[210],
                            }}
                        >
                            Apr.
                        </TableCell>
                        <TableCell
                            align="center"
                            sx={{
                                bgcolor: (theme) => theme.palette.grey[210],
                            }}
                        >
                            May.
                        </TableCell>
                        <TableCell
                            align="center"
                            sx={{
                                bgcolor: (theme) => theme.palette.grey[210],
                            }}
                        >
                            Jun.
                        </TableCell>
                        <TableCell
                            align="center"
                            sx={{
                                bgcolor: (theme) => theme.palette.grey[210],
                            }}
                        >
                            Jul.
                        </TableCell>
                        <TableCell
                            align="center"
                            sx={{
                                bgcolor: (theme) => theme.palette.grey[210],
                            }}
                        >
                            Aug.
                        </TableCell>
                        <TableCell
                            align="center"
                            sx={{
                                bgcolor: (theme) => theme.palette.grey[210],
                            }}
                        >
                            Sep.
                        </TableCell>
                        <TableCell
                            align="center"
                            sx={{
                                bgcolor: (theme) => theme.palette.grey[210],
                            }}
                        >
                            Oct.
                        </TableCell>
                        <TableCell
                            align="center"
                            sx={{
                                bgcolor: (theme) => theme.palette.grey[210],
                            }}
                        >
                            Nov.
                        </TableCell>
                        <TableCell
                            align="center"
                            sx={{
                                bgcolor: (theme) => theme.palette.grey[210],
                            }}
                        >
                            Dec.
                        </TableCell>
                        <TableCell
                            align="center"
                            sx={{
                                bgcolor: (theme) => theme.palette.grey[210],
                            }}
                        >
                            Sub-total
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell
                            component="th"
                            scope="row"
                            sx={{
                                bgcolor: (theme) => theme.palette.grey[210],
                            }}
                        >
                            Sub-total
                        </TableCell>
                        {[
                            "january",
                            "february",
                            "march",
                            "april",
                            "may",
                            "june",
                            "july",
                            "august",
                            "september",
                            "october",
                            "november",
                            "december",
                            "subtotal",
                        ].map((key) => {
                            return (
                                <TableCell
                                    key={key}
                                    align="center"
                                    sx={{
                                        bgcolor: (theme) =>
                                            theme.palette.grey[500],
                                    }}
                                />
                            );
                        })}
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    );
}
