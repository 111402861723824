import { Box, Tab, Tabs } from "@mui/material";

import { ParcellaireCreationFormImport } from "./ParcellaireCreationFormImport";
import { ParcellaireCreationFormNoImport } from "./ParcellaireCreationFormNoImport";
import PropTypes from "prop-types";
import { TabPanel } from "../../components";
import { useState } from "react";

ParcellaireCreationContainer.propTypes = {
    coordMarkerMode: PropTypes.bool.isRequired,
    map: PropTypes.any,
    parcellaireDispatch: PropTypes.func.isRequired,
    setBlueRectangleData: PropTypes.func.isRequired,
    setCoordMarkerMode: PropTypes.func.isRequired,
    setGeoJsonKey: PropTypes.func.isRequired,
    setIsOpen: PropTypes.func.isRequired,
    setSelectableMetadata: PropTypes.func.isRequired,
};

export function ParcellaireCreationContainer({
    coordMarkerMode,
    map,
    parcellaireDispatch,
    setBlueRectangleData,
    setCoordMarkerMode,
    setGeoJsonKey,
    setIsOpen,
    setSelectableMetadata,
}) {
    const [tabValue, setTabValue] = useState(0);

    const handleChange = (_, newValue) => {
        setTabValue(newValue);
    };

    return (
        <>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                    value={tabValue}
                    onChange={handleChange}
                    sx={{ flexGrow: 1 }}
                >
                    <Tab label="Import Data" />
                    <Tab label="From Scratch" />
                </Tabs>
            </Box>
            <TabPanel
                value={tabValue}
                index={0}
                boxStyle={{ p: (theme) => theme.spacing(3, 0, 0, 0) }}
            >
                <ParcellaireCreationFormImport
                    map={map}
                    parcellaireDispatch={parcellaireDispatch}
                    setBlueRectangleData={setBlueRectangleData}
                    setGeoJsonKey={setGeoJsonKey}
                    setIsOpen={setIsOpen}
                    setSelectableMetadata={setSelectableMetadata}
                />
            </TabPanel>
            <TabPanel
                value={tabValue}
                index={1}
                boxStyle={{ p: (theme) => theme.spacing(3, 0, 0, 0) }}
            >
                <ParcellaireCreationFormNoImport
                    coordMarkerMode={coordMarkerMode}
                    map={map}
                    parcellaireDispatch={parcellaireDispatch}
                    setBlueRectangleData={setBlueRectangleData}
                    setCoordMarkerMode={setCoordMarkerMode}
                    setGeoJsonKey={setGeoJsonKey}
                    setIsOpen={setIsOpen}
                    setSelectableMetadata={setSelectableMetadata}
                />
            </TabPanel>
        </>
    );
}
