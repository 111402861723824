import { useController, useFormContext, useWatch } from "react-hook-form";

import { DatePicker } from "@mui/x-date-pickers";
import { INPUT_VALIDATION } from "../../constants";
import { eachMonthOfInterval } from "date-fns";
import { formatDateISO } from "../../utils/formatTime";
import { useState } from "react";

const DATE_LIMIT = {
    START: "start",
    END: "end",
};
export const DatePickerField = ({ dateLimit, label, isDisabled }) => {
    const { setValue, getValues } = useFormContext();
    const [dateError, setDateError] = useState();
    const [startDateWatch, endDateWatch] = useWatch({
        name: ["startDate", "endDate"],
    });
    const name = `${dateLimit}Date`;
    const {
        field: { ref, onChange, ...fieldProps },
        fieldState: { error },
    } = useController({
        name,
        rules: {
            required: INPUT_VALIDATION.REQUIRED,
            validate: () => !dateError,
        },
    });

    const interval = {
        [DATE_LIMIT.START]: startDateWatch,
        [DATE_LIMIT.END]: endDateWatch,
    };
    const { [dateLimit]: date } = interval;

    const handleDateChange = (value) => {
        const oppositeDate =
            dateLimit === DATE_LIMIT.START ? endDateWatch : startDateWatch;
        if (value && oppositeDate) {
            const newMonthlyAcquisitions = eachMonthOfInterval({
                ...interval,
                [dateLimit]: value,
            }).map((month) => ({
                count: null,
                date: formatDateISO(month),
            }));

            const dataAllowances = getValues("dataAllowances");
            dataAllowances.forEach(({ monthlyAcquisitions }, index) => {
                const isDifferent =
                    monthlyAcquisitions.length !==
                        newMonthlyAcquisitions.length ||
                    monthlyAcquisitions.some(
                        ({ date }, i) => date !== newMonthlyAcquisitions[i].date
                    );

                if (isDifferent) {
                    newMonthlyAcquisitions.forEach((newMonth) => {
                        const existingMonth = monthlyAcquisitions?.find(
                            (month) => month.date === newMonth.date
                        );
                        newMonth.count = existingMonth?.count ?? null;
                    });
                    setValue(
                        `dataAllowances.${index}.monthlyAcquisitions`,
                        newMonthlyAcquisitions
                    );
                }
            });
        }
    };

    const handleDateError = (error) => {
        switch (error) {
            case "invalidDate":
                setDateError(INPUT_VALIDATION.INVALID_DATE);
                break;

            case "minDate":
                setDateError(INPUT_VALIDATION.MIN_END_DATE_ERROR);
                break;

            case "maxDate":
                setDateError(INPUT_VALIDATION.MAX_START_DATE_ERROR);
                break;

            default:
                setDateError(undefined);
        }
    };

    return (
        <DatePicker
            {...fieldProps}
            slotProps={{
                textField:
                    !date && error?.type === "required"
                        ? {
                              helperText: error.message,
                              error: true,
                              fullWidth: true,
                          }
                        : { helperText: dateError, fullWidth: true },
                field: { clearable: true },
            }}
            inputRef={ref}
            id={name}
            label={label}
            disabled={isDisabled}
            onChange={(value) => {
                handleDateChange(value);
                onChange(value);
            }}
            onError={handleDateError}
            {...(dateLimit === DATE_LIMIT.START
                ? { maxDate: endDateWatch ?? undefined }
                : { minDate: startDateWatch ?? undefined })}
            format="yyyy-MM-dd"
        />
    );
};
