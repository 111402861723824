import { DYNAMIC_FEATURE_ID_FIELD_NAME } from "../constants";
import { PARCELLAIRE_REDUCER_ACTIONS } from "../parcellaireReducer";
import { geoJSONUtil } from "./geoJSONUtil";
import { getSelectableMetadata } from "./getSelectableMetadata";
import { hasDuplicate } from "../../../utils";

export function readGeoJsonPlotFile(
    geoJsonFile,
    parcellaireDispatch,
    map,
    openSnackbar,
    setSelectableMetadata,
    site = null
) {
    if (geoJsonFile) {
        const reader = new FileReader();

        reader.onload = function (event) {
            try {
                const geoJson = JSON.parse(event.target.result);

                if (Array.isArray(geoJson.features)) {
                    // Dynamically add an unique id to each feature
                    // Also checks for unicity and / or missing items such as geometry or specific properties
                    const availableMetadata = [];
                    const unicityCheck = { name: [], x_y_experiment: [] };
                    const defaultExperimentName = site ? site.name : "default";
                    const formatedFeatures = geoJson.features.map(
                        ({ geometry, properties = {} }, index) => {
                            if (!geometry)
                                throw new Error(
                                    "Invalid GeoJson: missing geometry for some feature(s)."
                                );
                            const formatedProperties = {};

                            Object.keys(properties).forEach((property) => {
                                if (
                                    formatedProperties[
                                        `${property.toLowerCase().trim()}`
                                    ]
                                )
                                    throw new Error(
                                        "Invalid GeoJson: duplicate properties on some feature(s)."
                                    );

                                formatedProperties[
                                    `${property.toLowerCase().trim()}`
                                ] = properties[property];
                            });

                            properties[DYNAMIC_FEATURE_ID_FIELD_NAME] =
                                `${index}`;

                            return { geometry, properties: formatedProperties };
                        }
                    );
                    formatedFeatures.forEach(({ properties = {} }) => {
                        availableMetadata.push(
                            ...Object.keys(properties).filter(
                                (property) =>
                                    property !== DYNAMIC_FEATURE_ID_FIELD_NAME
                            )
                        );

                        if (site && !properties.name)
                            throw new Error(
                                "Invalid GeoJson: missing names for some feature(s)."
                            );

                        if (properties.x == null || properties.y == null)
                            throw new Error(
                                "Invalid GeoJson: missing Xs and/or Ys for some feature(s)."
                            );

                        unicityCheck.name.push(properties.name);
                        unicityCheck.x_y_experiment.push(
                            `${properties.x}_${properties.y}_${properties.experiment ?? defaultExperimentName}`
                        );
                    });

                    if (hasDuplicate(unicityCheck.x_y_experiment))
                        throw new Error(
                            `Invalid GeoJson: duplicated X & Y pairs${site ? "" : " per experiment"}.`
                        );

                    const uniqueMetadata = [...new Set(availableMetadata)];

                    if (site && hasDuplicate(unicityCheck.name)) {
                        throw new Error("Invalid GeoJson: duplicated names.");
                    }

                    setSelectableMetadata(
                        getSelectableMetadata(uniqueMetadata)
                    );
                    replaceMapDataGeoJson(parcellaireDispatch, geoJson, map);
                } else {
                    throw new Error(
                        "The GeoJson must have an array of features."
                    );
                }
            } catch (err) {
                openSnackbar(err.message, "error");
            }
        };

        reader.readAsText(geoJsonFile);
    }
}

export function replaceMapDataGeoJson(parcellaireDispatch, parcellaire, map) {
    parcellaireDispatch({
        type: PARCELLAIRE_REDUCER_ACTIONS.GEOJSON,
        geoJson: parcellaire,
    });

    const bounds = geoJSONUtil.getBoundsOfFeatures(parcellaire.features);

    if (bounds) {
        bounds.forEach((coord) => coord.reverse());
        map?.fitBounds(bounds);
    }
}

export function readMetadata(event, parcellaireDispatch, setGeoJsonKey) {
    const metadataFile = event.target.files[0];

    if (metadataFile) {
        const reader = new FileReader();

        reader.onload = function (event) {
            const jsonData = JSON.parse(event.target.result);

            if (jsonData?.site?.id && jsonData?.date) {
                parcellaireDispatch({
                    type: PARCELLAIRE_REDUCER_ACTIONS.METADATA,
                    metadata: jsonData,
                });
                // We need to rerender the GeoJson layers so they have the latest
                // parcellaireState in their event handlers
                setGeoJsonKey(new Date().getTime());
            }
            // TODO: use snackbar instead of alert
            else alert("Seems not a valid session-metadata.json");
        };

        reader.readAsText(metadataFile);
    }
}

export function readCSVToArray(csvFile, setValue, openSnackbar, isLiteral) {
    if (csvFile) {
        const reader = new FileReader();

        reader.onload = function (event) {
            try {
                const strData = event.target.result;

                let tupleDelimiter = null;
                for (let i = 0; tupleDelimiter === null; i++) {
                    if (strData[i] === "," || strData[i] === ";")
                        tupleDelimiter = strData[i];
                    if (i + 1 === strData.length) tupleDelimiter = ",";
                }

                let lineDelimiter = null;
                for (let i = 0; lineDelimiter === null; i++) {
                    if (
                        strData[i] === "\n" ||
                        strData[i] === "\r" ||
                        strData[i] === "\r\n"
                    )
                        lineDelimiter = strData[i];
                    else if (i + 1 === strData.length) lineDelimiter = "\n";
                }

                const arrData = strData.split(lineDelimiter);
                const csvResult = arrData.map((line) =>
                    line.split(tupleDelimiter)
                );

                const unicityCheck = { x_y: [], x_y_experiment: [] };

                const csvHeaders = csvResult[0].map((header) =>
                    header.toLowerCase().trim()
                );

                csvResult.slice(1).forEach((line) => {
                    if (line.length > 1) {
                        // making sure line isn't empty data (or last line of the csv)
                        const metadata = {};
                        line.forEach((cell, index) => {
                            metadata[csvHeaders[index]] = cell.trim();
                        });

                        if (!metadata.experiment || isLiteral) {
                            metadata.experiment = "default";
                        }

                        unicityCheck.x_y.push(`${metadata.x}_${metadata.y}`);
                        unicityCheck.x_y_experiment.push(
                            `${metadata.x}_${metadata.y}_${metadata.experiment}`
                        );
                    }
                });

                if (hasDuplicate(unicityCheck.x_y_experiment))
                    throw new Error(
                        `Invalid GeoJson: duplicated X & Y pairs${isLiteral ? "" : " per experiment"}.`
                    );

                if (hasDuplicate(unicityCheck.x_y))
                    openSnackbar(
                        "Warning: duplicated X & Y pairs detected. Be weary, as plots might overlap on creation.",
                        "warning"
                    );

                setValue("csvData", csvResult);
            } catch (err) {
                openSnackbar(err.message, "error");
            }
        };

        reader.readAsText(csvFile);
    }
}

// For now, only geoJson with an array of features is considered
// (since this should be the case when importing).
export function removeToolId(geoJson) {
    if (Array.isArray(geoJson?.features)) {
        const clonedGeoJson = geoJSONUtil.cloneGeoJSON(geoJson);

        clonedGeoJson.features.forEach((feature) => {
            delete feature.properties[DYNAMIC_FEATURE_ID_FIELD_NAME];
        });

        return clonedGeoJson;
    } else {
        return geoJson;
    }
}
