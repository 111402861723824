import Logo from "../components/Logo";
import Page from "../components/Page";
import { Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

const RootStyle = styled(Page)(({ theme }) => ({
    [theme.breakpoints.up("md")]: {
        display: "flex",
    },
}));

const ContentStyle = styled("div")(({ theme }) => ({
    margin: "auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(12, 0),
}));

export const AuthenticatingPage = () => (
    <RootStyle>
        <ContentStyle>
            <Logo />
            <Typography variant="h4">Authentication in progress</Typography>
        </ContentStyle>
    </RootStyle>
);
