import { Container, IconButton, Stack, Tab, Tabs } from "@mui/material";
import {
    ProtocolSimulatorForm,
    ResolutionSimulator,
    SensorSimulatorForm,
} from "../features/sop";

import CloseIcon from "@mui/icons-material/Close";
import ExploreIcon from "@mui/icons-material/Explore";
import LensBlurIcon from "@mui/icons-material/LensBlur";
import PropTypes from "prop-types";
import SquareFootIcon from "@mui/icons-material/SquareFoot";
import { TabPanel } from "../components";
import { useState } from "react";

// The tabs mechanism here is based on MUI's basic tab example
// https://mui.com/material-ui/react-tabs/#basic-tabs

SopSimulator.propTypes = {
    closeModal: PropTypes.func.isRequired,
};

export default function SopSimulator({ closeModal }) {
    const [value, setValue] = useState(0);

    const handleChange = (_, newValue) => {
        setValue(newValue);
    };

    return (
        <Container maxWidth="xl" sx={{ bgcolor: "background.default" }}>
            <Stack
                direction="row"
                sx={{ borderBottom: 1, borderColor: "divider" }}
                alignItems="center"
            >
                <Tabs
                    value={value}
                    onChange={handleChange}
                    sx={{ flexGrow: 1 }}
                >
                    <Tab
                        icon={<ExploreIcon />}
                        iconPosition="start"
                        label="Protocol Simulator"
                    />
                    <Tab
                        icon={<SquareFootIcon />}
                        iconPosition="start"
                        label="Sensor Simulator"
                    />
                    <Tab
                        icon={<LensBlurIcon />}
                        iconPosition="start"
                        label="Resolution Simulator"
                    />
                </Tabs>
                <IconButton onClick={closeModal}>
                    <CloseIcon />
                </IconButton>
            </Stack>
            <TabPanel value={value} index={0} boxStyle={{ p: 3 }}>
                <ProtocolSimulatorForm />
            </TabPanel>
            <TabPanel value={value} index={1} boxStyle={{ p: 3 }}>
                <SensorSimulatorForm />
            </TabPanel>
            <TabPanel value={value} index={2} boxStyle={{ p: 3 }}>
                <ResolutionSimulator />
            </TabPanel>
        </Container>
    );
}
