export const DYNAMIC_FEATURE_ID_FIELD_NAME = "TRANSFORM_TOOL_ID";
export const EARTH_RADIUS = 6371008.8; // Mean Earth Radius in meters

export const DRAWER_WIDTH = 400;

export const TRANSFORM_RANGE_ERROR =
    "Move not allowed since transform calculation impossible.";

export const UNKNOWN_TRANSFORM_ERROR =
    "An unknown error has occured. Please try again later.";

export const COORDINATE_DECIMAL_COUNT = 7;

export const GEOMAN_CUSTOM_TOGGLE_BUTTON_NAMES = Object.freeze({
    DISPLAY_BLUE_MODE: "displayBlueMode",
    HIDDEN_PLOT_MODE: "hiddenPlotMode",
    SINGLE_TARGET_MODE: "singleTargetMode",
    SHIFT_MODE: "shiftMode",
    LOCKED_SHAPE_MODE: "lockedShapeMode",
});

export const DYNAMIC_FEATURE_X_Y_FIELD_NAME = "HIPHEN_X_Y";

export const MAP_TILE_LAYERS = Object.freeze({
    SATELLITE: {
        name: "sat",
        mapbox: "satellite-streets-v12",
        url: "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}",
        file: "/static/illustrations/tileLayers/sat.png",
    },
    OPEN_STREET_MAP: {
        name: "osm",
        mapbox: "streets-v12",
        url: "https://tile.openstreetmap.org/{z}/{x}/{y}.png",
        file: "/static/illustrations/tileLayers/osm.png",
    },
    TOPO_MAP: {
        name: "topo",
        mapbox: "outdoors-v12",
        url: "https://server.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}.png",
        file: "/static/illustrations/tileLayers/topo.png",
    },
    DARK: {
        name: "dark",
        mapbox: "dark-v12",
        url: "https://basemaps.cartocdn.com/dark_all/{z}/{x}/{y}{r}.png",
        file: "/static/illustrations/tileLayers/dark.png",
    },
});
