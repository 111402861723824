import { Grid, TextField, Typography } from "@mui/material";

import { INPUT_VALIDATION } from "../../constants";
import { format } from "date-fns";
import { useFormContext } from "react-hook-form";

export function MonthlyAcquisitionForm({ dataAllowanceIndex, dataAllowance }) {
    const {
        register,
        formState: { errors },
    } = useFormContext();

    const monthlyAcquisitionsError =
        errors.dataAllowances?.[dataAllowanceIndex]?.monthlyAcquisitions;

    return (
        <Grid container spacing={1} columns={20}>
            <Grid item xs={20}>
                <Typography>Number of dates expected per month</Typography>
            </Grid>
            {dataAllowance.monthlyAcquisitions.map(
                (monthlyAcquisition, index) => (
                    <Grid item xs={5} xl={4} key={monthlyAcquisition.date}>
                        <TextField
                            fullWidth
                            type="number"
                            id={`dataAllowances.${dataAllowanceIndex}.monthlyAcquisitions.${index}.count`}
                            label={format(
                                new Date(monthlyAcquisition.date),
                                "yyyy/MM"
                            )}
                            error={Boolean(
                                monthlyAcquisitionsError?.[index]?.count
                            )}
                            helperText={
                                monthlyAcquisitionsError?.[index]?.count
                                    ?.message
                            }
                            {...register(
                                `dataAllowances.${dataAllowanceIndex}.monthlyAcquisitions.${index}.count`,
                                {
                                    min: {
                                        value: 0,
                                        message: INPUT_VALIDATION.POSITIVE,
                                    },
                                    setValueAs: (value) =>
                                        value === "" ? 0 : Number(value),
                                    deps: `dataAllowances.${dataAllowanceIndex}.monthlyAcquisitions`,
                                }
                            )}
                            inputProps={{ inputMode: "numeric" }}
                        />
                    </Grid>
                )
            )}
        </Grid>
    );
}
