import {
    Button,
    Collapse,
    Grid,
    IconButton,
    MenuItem,
    Paper,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import { INPUT_VALIDATION, SYSTEMS } from "../../constants";

import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import DeleteIcon from "@mui/icons-material/Delete";
import { MonthlyAcquisitionForm } from "./MonthlyAcquisitionForm";
import { eachMonthOfInterval } from "date-fns";
import { formatDateISO } from "../../utils/formatTime";

export const DataAllowanceRow = ({
    index,
    remove,
    update,
    expandedDataAllowance,
    setExpandedDataAllowance,
    availableSystems,
    dataAllowance,
}) => {
    const {
        formState: { errors },
        register,
        getValues,
    } = useFormContext();

    const totalDateCount = dataAllowance.monthlyAcquisitions.reduce(
        (previousValue, { count }) => previousValue + count,
        0
    );
    const dataAllowanceError = errors.dataAllowances?.[index];
    const renderSystemSelect = ({
        field: { ref, onChange, ...fieldProps },
    }) => (
        <TextField
            {...fieldProps}
            fullWidth
            id={`dataAllowances.${index}.system`}
            label="System *"
            select
            inputRef={ref}
            error={Boolean(dataAllowanceError?.system)}
            helperText={dataAllowanceError?.system?.message}
            onChange={(event) => {
                /* When modifying an existing data allowance (system), if the new system is "LITERAL"
                 (or if the system was "LITERAL"), we reset the data allowance fields.*/
                if (
                    event.target.value === SYSTEMS.LITERAL ||
                    dataAllowance.system === SYSTEMS.LITERAL
                ) {
                    const startDate = getValues("startDate");
                    const endDate = getValues("endDate");
                    update(index, {
                        system: event.target.value,
                        siteCount: null,
                        featureCount: null,
                        acquisitionCount: null,
                        monthlyAcquisitions:
                            event.target.value === SYSTEMS.LITERAL
                                ? []
                                : eachMonthOfInterval({
                                      start: startDate,
                                      end: endDate,
                                  }).map((month) => ({
                                      count: null,
                                      date: formatDateISO(month),
                                  })),
                    });
                } else {
                    onChange(event.target.value);
                }
                if (event.target.value !== SYSTEMS.LITERAL) {
                    setExpandedDataAllowance(index);
                }
            }}
        >
            {availableSystems
                .concat(dataAllowance.system || [])
                .map((system) => (
                    <MenuItem key={system} value={system}>
                        {system}
                    </MenuItem>
                ))}
        </TextField>
    );

    return (
        <Stack direction="row" alignItems="center" spacing={1}>
            <Paper sx={{ p: 1, flex: 1 }} elevation={2}>
                <Grid container spacing={1}>
                    <Grid item xs={4}>
                        <Controller
                            name={`dataAllowances.${index}.system`}
                            render={renderSystemSelect}
                            rules={{ required: INPUT_VALIDATION.REQUIRED }}
                        />
                    </Grid>
                    {dataAllowance.system &&
                        (dataAllowance.system !== SYSTEMS.LITERAL ? (
                            <>
                                <Grid item xs={3}>
                                    <TextField
                                        id={`dataAllowances.${index}.siteCount`}
                                        fullWidth
                                        type="number"
                                        label="Sites"
                                        error={Boolean(
                                            dataAllowanceError?.siteCount
                                        )}
                                        helperText={
                                            dataAllowanceError?.siteCount
                                                ?.message
                                        }
                                        {...register(
                                            `dataAllowances.${index}.siteCount`,
                                            {
                                                min: {
                                                    value: 1,
                                                    message:
                                                        INPUT_VALIDATION.POSITIVE,
                                                },
                                                valueAsNumber: true,
                                            }
                                        )}
                                        inputProps={{
                                            inputMode: "numeric",
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField
                                        id={`dataAllowances.${index}.featureCount`}
                                        fullWidth
                                        type="number"
                                        label="Plots"
                                        error={Boolean(
                                            dataAllowanceError?.featureCount
                                        )}
                                        helperText={
                                            dataAllowanceError?.featureCount
                                                ?.message
                                        }
                                        {...register(
                                            `dataAllowances.${index}.featureCount`,
                                            {
                                                min: {
                                                    value: 1,
                                                    message:
                                                        INPUT_VALIDATION.POSITIVE,
                                                },
                                                valueAsNumber: true,
                                            }
                                        )}
                                        inputProps={{
                                            inputMode: "numeric",
                                        }}
                                    />
                                </Grid>

                                <Grid item xs={2}>
                                    <Button
                                        endIcon={
                                            <CalendarMonthIcon
                                                fontSize="inherit"
                                                color="primary"
                                            />
                                        }
                                        onClick={() =>
                                            setExpandedDataAllowance(
                                                expandedDataAllowance === index
                                                    ? null
                                                    : index
                                            )
                                        }
                                        sx={{ color: "black" }}
                                    >
                                        {totalDateCount}
                                    </Button>
                                </Grid>
                                <Controller
                                    name={`dataAllowances.${index}.monthlyAcquisitions`}
                                    render={({
                                        field,
                                        fieldState: { error },
                                    }) => (
                                        <>
                                            {Boolean(error) && (
                                                <Grid item xs={12}>
                                                    <Typography color="error">
                                                        {error?.message}
                                                    </Typography>
                                                </Grid>
                                            )}
                                            <Grid
                                                item
                                                xs={12}
                                                ref={field.ref}
                                                tabIndex={0}
                                            >
                                                <Collapse
                                                    in={
                                                        expandedDataAllowance ===
                                                        index
                                                    }
                                                >
                                                    <MonthlyAcquisitionForm
                                                        dataAllowanceIndex={
                                                            index
                                                        }
                                                        dataAllowance={
                                                            dataAllowance
                                                        }
                                                    />
                                                </Collapse>
                                            </Grid>
                                        </>
                                    )}
                                    rules={{
                                        validate: (value, formValues) =>
                                            formValues.dataAllowances[index]
                                                .system === SYSTEMS.LITERAL ||
                                            Boolean(
                                                value.reduce(
                                                    (
                                                        previousValue,
                                                        { count }
                                                    ) => previousValue + count,
                                                    0
                                                )
                                            ) ||
                                            "Total date count should be higher than 0",
                                    }}
                                />
                            </>
                        ) : (
                            <Grid item xs={4}>
                                <TextField
                                    id={`dataAllowances.${index}.acquisitionCount`}
                                    fullWidth
                                    type="number"
                                    label="Acquisitions"
                                    error={Boolean(
                                        dataAllowanceError?.acquisitionCount
                                    )}
                                    helperText={
                                        dataAllowanceError?.acquisitionCount
                                            ?.message
                                    }
                                    {...register(
                                        `dataAllowances.${index}.acquisitionCount`,
                                        {
                                            min: {
                                                value: 1,
                                                message:
                                                    INPUT_VALIDATION.POSITIVE,
                                            },
                                            valueAsNumber: true,
                                        }
                                    )}
                                    inputProps={{
                                        inputMode: "numeric",
                                    }}
                                />
                            </Grid>
                        ))}
                </Grid>
            </Paper>

            <IconButton
                color="error"
                onClick={() => remove(index)}
                sx={{ height: "fit-content" }}
            >
                <DeleteIcon />
            </IconButton>
        </Stack>
    );
};
