import { Grid } from "@mui/material";
import { MissionProtocolSensorData } from "../MissionProtocolSensorData";
import { MissionProtocolStageTableLiteral } from "./MissionProtocolStageTableLiteral";

export function MissionProtocolLiteral({
    pipelineTemplate,
    TGDTSortedByDatatype,
}) {
    return (
        <Grid container spacing={2}>
            <Grid item xs={4}>
                <MissionProtocolSensorData
                    isLiteral
                    pipelineTemplate={pipelineTemplate}
                />
            </Grid>

            <Grid item xs={8}>
                <MissionProtocolStageTableLiteral
                    TGDTSortedByDatatype={TGDTSortedByDatatype}
                />
            </Grid>
        </Grid>
    );
}
