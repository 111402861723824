import { Stack, Tooltip, Typography } from "@mui/material";
import { heightFromGsdAndPipelineTemplate, roundNumber } from "../utils";

import { ALLOWED_FACTOR_BELOW_MINIMUM_GSD } from "../../constants";
import CircleIcon from "@mui/icons-material/Circle";
import CloseIcon from "@mui/icons-material/Close";
import WarningIcon from "@mui/icons-material/Warning";

export function BBCHStageRowDisplay({
    pipelineTemplate,
    traitGroupDataType,
    bbchStage,
    minGSD,
    hasTooltip = false,
}) {
    if (
        pipelineTemplate.PipelineTemplateTraitGroups.some(
            (item) =>
                item.traitGroupDataTypeUuid === traitGroupDataType.uuid &&
                item.bbchStageUuid === bbchStage.uuid
        )
    ) {
        // very important: the questionmark before .gsd prevents a crash that would happen because of fetch timing and cached data
        const thisStageGsd = pipelineTemplate.gsdPerTraitGroupDataType.find(
            (gsdPTG) =>
                gsdPTG.traitGroupDataTypeUuid === traitGroupDataType.uuid
        )?.gsd;

        const thisStageHeight = roundNumber(
            heightFromGsdAndPipelineTemplate(
                thisStageGsd,
                pipelineTemplate,
                traitGroupDataType.dataType
            ),
            1
        );

        if (hasTooltip)
            return (
                <Tooltip
                    title={
                        <Stack>
                            <Typography>{`GSD: ${thisStageGsd}mm`}</Typography>
                            <Typography>
                                {`Altitude: ${thisStageHeight}m`}
                            </Typography>
                        </Stack>
                    }
                >
                    <div>
                        <BBCHIconDisplay
                            minGSD={minGSD}
                            thisStageGsd={thisStageGsd}
                            traitGroupDataType={traitGroupDataType}
                        />
                    </div>
                </Tooltip>
            );
        return (
            <BBCHIconDisplay
                minGSD={minGSD}
                thisStageGsd={thisStageGsd}
                traitGroupDataType={traitGroupDataType}
            />
        );
    } else {
        return null;
    }
}

const BBCHIconDisplay = ({ minGSD, thisStageGsd, traitGroupDataType }) =>
    minGSD && thisStageGsd < minGSD[traitGroupDataType.dataType] ? (
        thisStageGsd * ALLOWED_FACTOR_BELOW_MINIMUM_GSD <
        minGSD[traitGroupDataType.dataType] ? (
            <CloseIcon color="error" />
        ) : (
            <WarningIcon color="warning" />
        )
    ) : (
        <CircleIcon color="success" />
    );
