import {
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
} from "@mui/material";

import PropTypes from "prop-types";
import download from "downloadjs";

export const FieldBookTutorial = ({ closeModal }) => (
    <>
        <DialogTitle>Field Book</DialogTitle>
        <Divider variant="middle" />
        <DialogContent>
            <img
                src="/static/illustrations/field_book_illustration.png"
                alt="Field Book explanation"
            />
        </DialogContent>
        <DialogActions>
            <Button type="button" onClick={closeModal}>
                Close
            </Button>
            <Button
                type="button"
                variant="outlined"
                onClick={() => download("/static/field_book_template.csv")}
            >
                Download template
            </Button>
        </DialogActions>
    </>
);

FieldBookTutorial.propTypes = {
    closeModal: PropTypes.func.isRequired,
};
