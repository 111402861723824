import { Box, Checkbox, Grid, Stack, Typography } from "@mui/material";

import { Controller } from "react-hook-form";
import { MODEL_PROPTYPES } from "../../../models";
import PropTypes from "prop-types";
import { useMemo } from "react";

PipelineTemplateBBCHRow.propTypes = {
    bbchStages: PropTypes.arrayOf(
        PropTypes.shape(MODEL_PROPTYPES.BBCHStage).isRequired
    ).isRequired,
    selectedPlantScales: PropTypes.array.isRequired,
    traitGroupDataType: PropTypes.object.isRequired,
};

export function PipelineTemplateBBCHRow({
    bbchStages,
    selectedPlantScales,
    traitGroupDataType,
}) {
    // for this trait group data type, we return its reference object uuid taken from its trait reference
    const referenceObjectUuid = useMemo(
        () => traitGroupDataType.TraitReference.referenceObjectUuid,
        [traitGroupDataType]
    );

    return (
        <>
            <Grid item xs={3}>
                <Box minHeight="100%" display="flex" alignItems="center">
                    <Typography>{`${traitGroupDataType.TraitGroup.name} (${traitGroupDataType.dataType})`}</Typography>
                </Box>
            </Grid>
            <Grid item xs={9}>
                <Stack
                    direction="row"
                    justifyContent="space-around"
                    alignItems="center"
                    spacing={1}
                >
                    {bbchStages.map((bbchStage) => (
                        <Controller
                            key={bbchStage.uuid}
                            name={`bbchTraits.${traitGroupDataType.uuid}`}
                            shouldUnregister
                            defaultValue={[]}
                            render={({
                                field: { value: values, onChange, ...field },
                            }) => (
                                <Checkbox
                                    {...field}
                                    value={bbchStage.uuid}
                                    checked={values.includes(bbchStage.uuid)}
                                    onChange={(e) =>
                                        onChange(
                                            e.target.checked
                                                ? values.concat(bbchStage.uuid)
                                                : values.filter(
                                                      (value) =>
                                                          value !==
                                                          bbchStage.uuid
                                                  )
                                        )
                                    }
                                    sx={{
                                        "&.Mui-disabled": {
                                            opacity: "35%",
                                        },
                                    }}
                                    disabled={
                                        !(
                                            isBBCHPickable(
                                                bbchStage,
                                                traitGroupDataType.TraitGroup
                                            ) &&
                                            selectedPlantScales.find(
                                                (plantScale) =>
                                                    referenceObjectUuid ===
                                                    plantScale.referenceObjectUuid
                                            )
                                        )
                                    }
                                />
                            )}
                        />
                    ))}
                </Stack>
            </Grid>
        </>
    );
}

function isBBCHPickable(bbchStage, traitGroup) {
    const trueMinOrder = Math.max(
        ...traitGroup.Traits.map(
            (singleTrait) => singleTrait.MinBBCHStage.order
        )
    );
    const trueMaxOrder = Math.min(
        ...traitGroup.Traits.map(
            (singleTrait) => singleTrait.MaxBBCHStage.order
        )
    );

    return bbchStage.order <= trueMaxOrder && bbchStage.order >= trueMinOrder;
}
