import {
    Box,
    Card,
    Dialog,
    Icon,
    IconButton,
    Stack,
    Tooltip,
    Typography,
} from "@mui/material";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import { useIsBasicRole, useUsers } from "../../hooks";
import { useMemo, useState } from "react";

import AddCircleOutlinedIcon from "@mui/icons-material/AddCircleOutlined";
import { BACKEND_ROUTES } from "../../backendRoutes";
import { CLOVERFIELD_MAP } from "../../constants";
import { FRONTEND_ROUTES } from "../../frontendRoutes";
import { FetchErrorAlert } from "../../components/FetchErrorAlert";
import { Link } from "react-router-dom";
import MapIcon from "@mui/icons-material/Map";
import { SiteCreateForm } from "./SiteCreateForm";
import { SiteTableSkeleton } from "./SiteTableSkeleton";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { iso31661 as countriesArray } from "iso-3166";
import { getDataGridHeight } from "../utils";
import useSWR from "swr";

export function SiteTable({ contract, hasLiteralTemplates }) {
    const isBasicRole = useIsBasicRole();

    const [pagination, setPage] = useState({
        page: 0,
        pageSize: pageSizeOptions[0],
    });
    const [isOpen, setIsOpen] = useState(false);

    const {
        data: users,
        error: usersError,
        isLoading: areUsersLoading,
    } = useUsers();
    const {
        data: sites,
        error: sitesError,
        mutate,
        isLoading: areSitesLoading,
    } = useSWR(
        `${
            BACKEND_ROUTES.SITE
        }?parentInfo=true&contractUuid=${contract.uuid}&limit=${pagination.pageSize}&offset=${
            pagination.page * pagination.pageSize
        }`,
        { keepPreviousData: true }
    );

    const rows = useMemo(
        () =>
            sites?.rows.map((site) => ({
                id: site.uuid,
                uuid: site.uuid,
                name: site.name,
                displayName: site.displayName,
                supervisor: `${site.Supervisor.firstName} ${site.Supervisor.lastName}`,
                supervisorUuid: site.supervisorUuid,
                country: site.countryCode
                    ? countriesArray.find(
                          (country) => country.alpha3 === site.countryCode
                      )?.name ?? "Unknown country"
                    : "No country",
                cloverfieldUuid: site.cloverfieldUuid,
            })),
        [sites]
    );

    const columns = useMemo(
        () => [
            {
                field: "actions",
                headerName: "Actions",
                type: "actions",
                minWidth: 120,
                flex: 0.5,
                getActions: (params) => {
                    const isCloverfieldLinkDisabled =
                        !params.row.cloverfieldUuid || !CLOVERFIELD_MAP;

                    const actions = [
                        <Tooltip title="View details">
                            <GridActionsCellItem
                                label="View details"
                                icon={
                                    <VisibilityIcon
                                        fontSize="small"
                                        color="primary"
                                    />
                                }
                                component={Link}
                                to={`/${FRONTEND_ROUTES.ORDERS}/${FRONTEND_ROUTES.CONTRACT}/${contract.uuid}/${FRONTEND_ROUTES.SITE}/${params.row.id}`}
                            />
                        </Tooltip>,
                        <Tooltip title="Go to Cloverfield site">
                            <GridActionsCellItem
                                label="Go to Cloverfield site"
                                icon={
                                    <Icon
                                        fontSize="small"
                                        sx={{
                                            filter: isCloverfieldLinkDisabled
                                                ? "grayscale(100%)"
                                                : "none",
                                        }}
                                    >
                                        <img
                                            alt="Cloverfield logo"
                                            src="/static/cloverfield_logo_green.png"
                                        />
                                    </Icon>
                                }
                                component={Link}
                                disabled={isCloverfieldLinkDisabled}
                                to={`${CLOVERFIELD_MAP}/${params.row.cloverfieldUuid}`}
                            />
                        </Tooltip>,
                    ];

                    return hasLiteralTemplates
                        ? // Add Literal Parcellaire action in second position if there are literal PT
                          actions.toSpliced(
                              1,
                              0,
                              <Tooltip title="Literal Plot Map Tool">
                                  <GridActionsCellItem
                                      label="Literal Plot Map Tool"
                                      icon={
                                          <MapIcon
                                              fontSize="small"
                                              color="primary"
                                          />
                                      }
                                      component={Link}
                                      to={`/${FRONTEND_ROUTES.PARCELLAIRE_LITERAL}/${params.row.id}`}
                                  />
                              </Tooltip>
                          )
                        : actions;
                },
                filterable: false,
            },
            {
                field: "name",
                headerName: "Name",
                minWidth: 250,
                flex: 3,
            },
            {
                field: "displayName",
                headerName: "Display name",
                minWidth: 250,
                flex: 3,
            },
            {
                field: "country",
                headerName: "Country",
                minWidth: 250,
                flex: 3,
            },
            {
                field: "supervisor",
                headerName: "Supervisor",
                minWidth: 250,
                flex: 2,
            },
        ],
        [contract.uuid, hasLiteralTemplates]
    );
    const columnVisibilityModel = useMemo(
        () => ({ supervisor: !isBasicRole }),
        [isBasicRole]
    );

    const mergedFetchError = usersError ?? sitesError;
    if (mergedFetchError) return <FetchErrorAlert error={mergedFetchError} />;
    if (areSitesLoading || areUsersLoading) return <SiteTableSkeleton />;

    return (
        <Stack>
            <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
            >
                <Typography variant="h6" gutterBottom>
                    Sites
                </Typography>
                <Stack direction="row" spacing={1}>
                    <IconButton size="large" onClick={() => setIsOpen(true)}>
                        <AddCircleOutlinedIcon
                            fontSize="inherit"
                            color="primary"
                        />
                    </IconButton>
                    <Dialog open={isOpen} fullWidth maxWidth="sm">
                        <SiteCreateForm
                            contract={contract}
                            closeModal={() => setIsOpen(false)}
                            mutate={mutate}
                            users={
                                users ??
                                (contract.Supervisor
                                    ? [contract.Supervisor]
                                    : [])
                            }
                        />
                    </Dialog>
                </Stack>
            </Stack>

            <Card>
                <Box
                    sx={{
                        height: getDataGridHeight(400, sites.rows.length),
                        width: "100%",
                    }}
                >
                    <DataGrid
                        rows={rows}
                        columns={columns}
                        pageSizeOptions={pageSizeOptions}
                        rowCount={sites.count}
                        paginationMode="server"
                        paginationModel={pagination}
                        onPaginationModelChange={setPage}
                        initialState={initialState}
                        columnVisibilityModel={columnVisibilityModel}
                    />
                </Box>
            </Card>
        </Stack>
    );
}

const initialState = {
    sorting: { sortModel: [{ field: "name", sort: "asc" }] },
};

const pageSizeOptions = [100];
