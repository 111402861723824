import { Box, Card, Icon, Stack, Tooltip, Typography } from "@mui/material";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import { Link, useParams } from "react-router-dom";
import {
    getDataGridHeight,
    objectArrayStringify,
    userNamesComparator,
} from "../utils";
import { useCallback, useMemo } from "react";
import { useFetch, useIsBasicRole, useSnackbar, useUsers } from "../../hooks";

import { BACKEND_ROUTES } from "../../backendRoutes";
import { CLOVERFIELD_MAP } from "../../constants";
import { FetchErrorAlert } from "../../components/FetchErrorAlert";
import { MissionTableSkeleton } from "./MissionTableSkeleton";
import { formatDateISO } from "../../utils/formatTime";
import useSWR from "swr";
import { useSite } from "../site";

// this fake uuid doesn't meet the uuidv4 requirements on purpose so we are 100% sure that the fetched data cannot have this value by default
// it's used as a replacement to null for supervisorUuid and must be used because MUI has issues with values being '' for selects
const noUserUuidValue = "this solution works somehow!";

export function MissionTable() {
    const { patch } = useFetch();
    const { siteUuid } = useParams();
    const isBasicRole = useIsBasicRole();
    const { openSnackbar } = useSnackbar();

    const {
        data: site,
        error: siteError,
        isLoading: isSiteLoading,
    } = useSite();
    const {
        data: users,
        error: usersError,
        isLoading: areUsersLoading,
    } = useUsers();
    const {
        data: missions,
        error: missionsError,
        isLoading: areMissionsLoading,
        mutate,
    } = useSWR(
        `${BACKEND_ROUTES.MISSION}?parentInfo=true&acquisitionVector=true&siteUuid=${siteUuid}`
    );

    const handleProcessRowUpdateError = useCallback(
        (error) => {
            openSnackbar(error.message, "error");
        },
        [openSnackbar]
    );

    const processRowUpdate = useCallback(
        async (newRow, oldRow) => {
            const updatedMission = await patch(
                `${BACKEND_ROUTES.MISSION}/${newRow.uuid}`,
                {
                    body: {
                        supervisorUuid:
                            newRow.supervisorUuid === noUserUuidValue
                                ? null
                                : newRow.supervisorUuid,
                    },
                    forwardError: true,
                }
            );
            if (updatedMission) {
                openSnackbar(
                    `Updated Mission of ${newRow.date} successfully.`,
                    "success"
                );
                mutate();
                return newRow;
            }
            return oldRow;
        },
        [mutate, openSnackbar, patch]
    );

    const isCloverfieldLinkDisabled =
        !site?.cloverfieldUuid || !CLOVERFIELD_MAP;

    const columns = useMemo(
        () => [
            {
                field: "actions",
                headerName: "Actions",
                type: "actions",
                minWidth: 80,
                flex: 0.5,
                getActions: (params) => [
                    <Tooltip title="Go to Cloverfield mission">
                        <GridActionsCellItem
                            label="Go to Cloverfield mission"
                            icon={
                                <Icon
                                    fontSize="small"
                                    sx={{
                                        filter: isCloverfieldLinkDisabled
                                            ? "grayscale(100%)"
                                            : "none",
                                    }}
                                >
                                    <img
                                        alt="Cloverfield logo"
                                        src="/static/cloverfield_logo_green.png"
                                    />
                                </Icon>
                            }
                            component={Link}
                            disabled={isCloverfieldLinkDisabled}
                            to={`${CLOVERFIELD_MAP}/${site.cloverfieldUuid}/${params.row.date}`}
                        />
                    </Tooltip>,
                ],
                filterable: false,
            },
            {
                field: "date",
                headerName: "Acquisition Date",
                minWidth: 100,
                flex: 2,
            },
            {
                field: "dataType",
                headerName: "Data Type",
                minWidth: 140,
                flex: 2,
            },
            {
                field: "acquisitionVector",
                headerName: "Acquisition Vector",
                minWidth: 320,
                flex: 3,
            },
            {
                field: "supervisorUuid",
                headerName: "Supervisor",
                minWidth: 260,
                flex: 2,
                editable: !isBasicRole,
                type: "singleSelect",
                sortComparator: (userUuidA, userUuidB) =>
                    userNamesComparator(
                        userUuidA,
                        userUuidB,
                        users,
                        noUserUuidValue,
                        "No Supervisor"
                    ),
                valueOptions: (users ?? [])
                    .map((user) => ({
                        value: user.uuid,
                        label: `${user.firstName} ${user.lastName}`,
                    }))
                    .concat({
                        value: noUserUuidValue,
                        label: "No supervisor",
                    }),
            },
        ],
        [isCloverfieldLinkDisabled, isBasicRole, site, users]
    );

    const rows = useMemo(
        () =>
            missions?.rows.map((mission) => ({
                id: mission.uuid,
                uuid: mission.uuid,
                date: formatDateISO(mission.date),
                acquisitionVector: mission.AcquisitionVector.name,
                dataType: objectArrayStringify(
                    mission.AcquisitionVector.SensorBundles,
                    ["Sensor", "dataType"]
                ),
                supervisorUuid: mission.supervisorUuid ?? noUserUuidValue,
            })),
        [missions]
    );

    const columnVisibilityModel = useMemo(
        () => ({ supervisorUuid: !isBasicRole }),
        [isBasicRole]
    );

    const mergedFetchError = usersError ?? missionsError ?? siteError;
    if (mergedFetchError) return <FetchErrorAlert error={mergedFetchError} />;
    if (isSiteLoading || areMissionsLoading || areUsersLoading)
        return <MissionTableSkeleton />;

    return (
        <>
            <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                mb={2}
            >
                <Typography variant="h6" gutterBottom>
                    Missions
                </Typography>
            </Stack>

            <Card>
                <Box
                    height={getDataGridHeight(400, missions.rows.length)}
                    width="100%"
                >
                    <DataGrid
                        rows={rows}
                        columns={columns}
                        pageSizeOptions={pageSizeOptions}
                        rowCount={missions.count}
                        processRowUpdate={processRowUpdate}
                        onProcessRowUpdateError={handleProcessRowUpdateError}
                        initialState={initialState}
                        columnVisibilityModel={columnVisibilityModel}
                    />
                </Box>
            </Card>
        </>
    );
}

const initialState = {
    sorting: { sortModel: [{ field: "date", sort: "asc" }] },
};

const pageSizeOptions = [100];
