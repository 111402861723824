import {
    Button,
    Divider,
    FormControlLabel,
    Grid,
    MenuItem,
    Stack,
    Switch,
    TextField,
    Typography,
} from "@mui/material";
import {
    CF_CONTRACT_SYSTEMS,
    CONTRACT_STATUS,
    INPUT_VALIDATION,
    PAGINATION,
} from "../../constants";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { getMonth, getYear, parseISO } from "date-fns";
import { useFetch, useIsBasicRole, useSnackbar, useUsers } from "../../hooks";

import { BACKEND_ROUTES } from "../../backendRoutes";
import { ContractFormSkeleton } from "./ContractFormSkeleton";
import { DataAllowanceForm } from "./DataAllowanceForm";
import { DatePicker } from "@mui/x-date-pickers";
import { DatePickerField } from "./DatePickerField";
import { FRONTEND_ROUTES } from "../../frontendRoutes";
import { FetchErrorAlert } from "../../components/FetchErrorAlert";
import { LoadingButton } from "@mui/lab";
import { formatDateISO } from "../../utils/formatTime";
import { useNavigate } from "react-router-dom";
import useSWR from "swr";
import useSWRImmutable from "swr/immutable";
import { useState } from "react";

export const ContractForm = ({ contract, mutate }) => {
    const navigate = useNavigate();
    const { post, patch } = useFetch();
    const isBasicRole = useIsBasicRole();
    const { openSnackbar } = useSnackbar();
    const [reportDateError, setDateError] = useState();
    const {
        data: users,
        error: usersError,
        isLoading: areUsersLoading,
    } = useUsers();
    const {
        data: companies,
        error: companiesError,
        isLoading: areCompaniesLoading,
    } = useSWR(
        contract
            ? null
            : `${BACKEND_ROUTES.COMPANY}?sort=name&limit=${PAGINATION.COMPANY.LIMIT.MAX}`
    );
    const {
        data: uploadTemplates,
        error: uploadTemplatesError,
        isLoading: areUploadTemplatesLoading,
    } = useSWRImmutable(BACKEND_ROUTES.UPLOAD_TEMPLATES);

    const methods = useForm({
        defaultValues: {
            name: "",
            companyUuid: "",
            startDate: null,
            endDate: null,
            supervisorUuid: "",
            salespersonUuid: "",
            reportDate: null,
            status: CONTRACT_STATUS.PRE_ORDERED,
            system: "",
            uploadTemplateId: "",
            hasExperiment: false,
            dataAllowances: [],
        },
        resetOptions: { keepDirtyValues: true },
        values: contract && {
            name: contract.name,
            companyUuid: contract.companyUuid,
            startDate: parseISO(contract.startDate),
            endDate: parseISO(contract.endDate),
            supervisorUuid: contract.supervisorUuid,
            salespersonUuid: contract.salespersonUuid,
            reportDate: contract.reportDate
                ? parseISO(contract.reportDate)
                : null,
            status: contract.status,
            system: contract.system,
            uploadTemplateId: contract.UploadTemplate.id,
            hasExperiment: contract.hasExperiment,
            dataAllowances: contract.DataAllowances.map((dataAllowance) => ({
                system: dataAllowance.system,
                siteCount: dataAllowance.siteCount,
                featureCount: dataAllowance.featureCount,
                acquisitionCount: dataAllowance.acquisitionCount,
                monthlyAcquisitions: dataAllowance.MonthlyAcquisitions.map(
                    ({ date, count }) => ({
                        count,
                        date,
                    })
                ),
            })),
        },
    });
    const {
        control,
        register,
        handleSubmit,
        reset,
        formState: { errors, isSubmitting, isDirty },
    } = methods;

    const mergedFetchError =
        companiesError ?? usersError ?? uploadTemplatesError;
    if (mergedFetchError) return <FetchErrorAlert error={mergedFetchError} />;
    if (areCompaniesLoading || areUsersLoading || areUploadTemplatesLoading)
        return <ContractFormSkeleton />;

    const onSubmit = async ({ companyUuid, uploadTemplateId, ...payload }) => {
        const body = {
            ...payload,
            startDate: formatDateISO(payload.startDate),
            endDate: formatDateISO(payload.endDate),
            reportDate: payload.reportDate && formatDateISO(payload.reportDate),
            uploadTemplateId: uploadTemplateId || undefined,
            dataAllowances: payload.dataAllowances.map((dataAllowance) => ({
                ...dataAllowance,
                monthlyAcquisitions: dataAllowance.monthlyAcquisitions.map(
                    ({ date, count }) => ({
                        year: getYear(date),
                        month: getMonth(date) + 1,
                        count: count ?? 0,
                    })
                ),
            })),
        };

        const updatedContract = await (contract
            ? patch(`${BACKEND_ROUTES.CONTRACT}/${contract.uuid}`, { body })
            : post(`${BACKEND_ROUTES.COMPANY}/${companyUuid}/contracts`, {
                  body,
              }));

        if (updatedContract) {
            openSnackbar(
                `Campaign ${updatedContract.name} ${
                    contract ? "updated" : "created"
                } successfully.`,
                "success"
            );

            contract
                ? mutate()
                : navigate(
                      `/${FRONTEND_ROUTES.ORDERS}/${FRONTEND_ROUTES.CONTRACT}/${updatedContract.uuid}`
                  );
        }
    };

    const onCancel = () => {
        contract
            ? reset()
            : navigate(
                  `/${FRONTEND_ROUTES.ORDERS}/${FRONTEND_ROUTES.CONTRACT}`
              );
    };

    // If users are nullish, the contract must have been given since basic role cannot
    // be creating a new contract
    const supervisors = users ?? [contract.Supervisor];
    const salespeople = users ?? [contract.Salesperson];

    const requiredLabel = !contract ? " *" : "";

    return (
        <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Typography variant="h4" gutterBottom>
                            {contract
                                ? "Campaign management"
                                : "Create a new campaign"}
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        xs={6}
                        sx={{
                            borderRightColor: "divider",
                            borderRightStyle: "solid",
                            borderRightWidth: 2,
                            pr: 1,
                        }}
                        container
                        spacing={1}
                        alignItems="center"
                    >
                        <Grid item xs={6} xl={4}>
                            <Controller
                                control={control}
                                name="companyUuid"
                                render={({ field: { ref, ...fieldProps } }) => (
                                    <TextField
                                        {...fieldProps}
                                        fullWidth
                                        id="companyUuid"
                                        label={`Company${requiredLabel}`}
                                        disabled={Boolean(
                                            contract || isBasicRole
                                        )}
                                        select
                                        inputRef={ref}
                                        error={Boolean(errors.companyUuid)}
                                        helperText={errors.companyUuid?.message}
                                    >
                                        {companies ? (
                                            companies.rows.map((company) => (
                                                <MenuItem
                                                    key={company.uuid}
                                                    value={company.uuid}
                                                >
                                                    {company.name}
                                                </MenuItem>
                                            ))
                                        ) : (
                                            <MenuItem
                                                key={contract.companyUuid}
                                                value={contract.companyUuid}
                                            >
                                                {contract.Company?.name}
                                            </MenuItem>
                                        )}
                                    </TextField>
                                )}
                                rules={{
                                    required: INPUT_VALIDATION.REQUIRED,
                                }}
                            />
                        </Grid>
                        <Grid item xs={6} xl={4}>
                            <TextField
                                name="name"
                                id="name"
                                label={`Name${requiredLabel}`}
                                fullWidth
                                disabled={isBasicRole}
                                type="text"
                                error={Boolean(errors.name)}
                                helperText={errors.name?.message}
                                {...register("name", {
                                    required: INPUT_VALIDATION.REQUIRED,
                                })}
                            />
                        </Grid>
                        <Grid
                            item
                            xs={0}
                            xl={4}
                            display={{ xs: "none", xl: "block" }}
                        />
                        <Grid item xs={6} xl={4}>
                            <DatePickerField
                                dateLimit="start"
                                isDisabled={Boolean(contract || isBasicRole)}
                                label={`Starting Date${requiredLabel}`}
                            />
                        </Grid>
                        <Grid item xs={6} xl={4}>
                            <DatePickerField
                                dateLimit="end"
                                isDisabled={Boolean(contract || isBasicRole)}
                                label={`Ending Date${requiredLabel}`}
                            />
                        </Grid>

                        {!isBasicRole && (
                            <>
                                <Grid item xs={6} xl={4}>
                                    <Controller
                                        name="reportDate"
                                        control={control}
                                        render={({
                                            field: { ref, ...fieldProps },
                                        }) => (
                                            <DatePicker
                                                {...fieldProps}
                                                slotProps={{
                                                    field: {
                                                        clearable: true,
                                                    },
                                                    textField: {
                                                        fullWidth: true,
                                                        helperText:
                                                            reportDateError,
                                                    },
                                                }}
                                                format="yyyy-MM-dd"
                                                inputRef={ref}
                                                id="reportDate"
                                                label="Report Date"
                                                onError={(reason) => {
                                                    switch (reason) {
                                                        case "invalidDate":
                                                            setDateError(
                                                                INPUT_VALIDATION.INVALID_DATE
                                                            );
                                                            break;

                                                        default:
                                                            setDateError(
                                                                undefined
                                                            );
                                                    }
                                                }}
                                            />
                                        )}
                                        rules={{
                                            validate: () => !reportDateError,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={6} xl={4}>
                                    <Controller
                                        control={control}
                                        name="status"
                                        render={({
                                            field: { ref, ...fieldProps },
                                        }) => (
                                            <TextField
                                                {...fieldProps}
                                                fullWidth
                                                id="status"
                                                label={`Status${requiredLabel}`}
                                                select
                                                inputRef={ref}
                                                error={Boolean(errors.status)}
                                                helperText={
                                                    errors.status?.message
                                                }
                                            >
                                                {Object.values(
                                                    CONTRACT_STATUS
                                                ).map((status) => (
                                                    <MenuItem
                                                        key={status}
                                                        value={status}
                                                    >
                                                        {status}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        )}
                                        rules={{
                                            required: INPUT_VALIDATION.REQUIRED,
                                        }}
                                    />
                                </Grid>
                            </>
                        )}

                        {!isBasicRole && (
                            <Grid item xs={6} xl={4}>
                                <Controller
                                    control={control}
                                    name="supervisorUuid"
                                    render={({
                                        field: { ref, ...fieldProps },
                                    }) => (
                                        <TextField
                                            {...fieldProps}
                                            fullWidth
                                            id="supervisorUuid"
                                            label={`Campaign Manager${requiredLabel}`}
                                            select
                                            inputRef={ref}
                                            error={Boolean(
                                                errors.supervisorUuid
                                            )}
                                            helperText={
                                                errors.supervisorUuid?.message
                                            }
                                        >
                                            {supervisors.map((user) => (
                                                <MenuItem
                                                    key={user.uuid}
                                                    value={user.uuid}
                                                >
                                                    {user.firstName}{" "}
                                                    {user.lastName}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    )}
                                    rules={{
                                        required: INPUT_VALIDATION.REQUIRED,
                                    }}
                                />
                            </Grid>
                        )}
                        <Grid item xs={6} xl={4}>
                            <Controller
                                control={control}
                                name="salespersonUuid"
                                render={({ field: { ref, ...fieldProps } }) => (
                                    <TextField
                                        {...fieldProps}
                                        fullWidth
                                        id="salespersonUuid"
                                        label={`Project Lead${requiredLabel}`}
                                        disabled={isBasicRole}
                                        select
                                        inputRef={ref}
                                        error={Boolean(errors.salespersonUuid)}
                                        helperText={
                                            errors.salespersonUuid?.message
                                        }
                                    >
                                        {salespeople.map((user) => (
                                            <MenuItem
                                                key={user.uuid}
                                                value={user.uuid}
                                            >
                                                {user.firstName} {user.lastName}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                )}
                                rules={{
                                    required: INPUT_VALIDATION.REQUIRED,
                                }}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Divider
                                sx={{
                                    borderStyle: "dashed",
                                    borderWidth: "thin",
                                }}
                            />
                            <Typography
                                variant="h6"
                                sx={{ color: "text.secondary" }}
                            >
                                Cloverfield fields
                            </Typography>
                        </Grid>
                        <Grid item xs={6} xl={4}>
                            <Controller
                                control={control}
                                name="system"
                                render={({ field: { ref, ...fieldProps } }) => (
                                    <TextField
                                        {...fieldProps}
                                        fullWidth
                                        id="system"
                                        label={`System${requiredLabel}`}
                                        select
                                        inputRef={ref}
                                        error={Boolean(errors.system)}
                                        helperText={errors.system?.message}
                                    >
                                        {Object.values(CF_CONTRACT_SYSTEMS).map(
                                            (system) => (
                                                <MenuItem
                                                    key={system.value}
                                                    value={system.value}
                                                >
                                                    {system.label}
                                                </MenuItem>
                                            )
                                        )}
                                    </TextField>
                                )}
                                rules={{
                                    required: INPUT_VALIDATION.REQUIRED,
                                }}
                            />
                        </Grid>
                        <Grid item xs={6} xl={4}>
                            <Controller
                                control={control}
                                name="uploadTemplateId"
                                render={({ field: { ref, ...fieldProps } }) => (
                                    <TextField
                                        {...fieldProps}
                                        fullWidth
                                        id="uploadTemplateId"
                                        label="Upload Template"
                                        select
                                        inputRef={ref}
                                        error={Boolean(errors.uploadTemplateId)}
                                        helperText={
                                            errors.uploadTemplateId?.message
                                        }
                                    >
                                        {uploadTemplates.rows
                                            .concat({
                                                id: "",
                                                name: "-- Unset --",
                                            })
                                            .map((template) => (
                                                <MenuItem
                                                    key={template.id}
                                                    value={template.id}
                                                >
                                                    {template.name}
                                                </MenuItem>
                                            ))}
                                    </TextField>
                                )}
                            />
                        </Grid>
                        <Grid item xs={6} xl={4}>
                            <Controller
                                control={control}
                                name="hasExperiment"
                                render={({
                                    field: { ref, value, ...fieldProps },
                                }) => (
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                {...fieldProps}
                                                inputRef={ref}
                                                checked={value}
                                            />
                                        }
                                        label="Enable plot map"
                                        labelPlacement="start"
                                        sx={{ ml: "auto" }}
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={6} sx={{ display: "flex" }}>
                        <DataAllowanceForm />
                    </Grid>
                    {(!contract || isDirty) && !isBasicRole && (
                        <Grid item xs={12}>
                            <Stack
                                direction="row"
                                spacing={3}
                                justifyContent="flex-end"
                            >
                                <Button type="button" onClick={onCancel}>
                                    Cancel
                                </Button>
                                <LoadingButton
                                    type="submit"
                                    variant="contained"
                                    loading={isSubmitting}
                                >
                                    {contract ? "Update" : "Create"}
                                </LoadingButton>
                            </Stack>
                        </Grid>
                    )}
                </Grid>
            </form>
        </FormProvider>
    );
};
