import {
    Autocomplete,
    Box,
    Button,
    Grid,
    MenuItem,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { useFetch, useIsBasicRole, useSnackbar, useUsers } from "../../hooks";

import { BACKEND_ROUTES } from "../../backendRoutes";
import { CountryFlag } from "../../components/CountryFlag";
import { FetchErrorAlert } from "../../components/FetchErrorAlert";
import { INPUT_VALIDATION } from "../../constants";
import { LoadingButton } from "@mui/lab";
import { SORTED_COUNTRIES } from "./constants";
import { SiteEditFormSkeleton } from "./SiteEditFormSkeleton";
import { styled } from "@mui/material/styles";
import { useSite } from "./useSite";

const LabelStyle = styled(Typography)(({ theme }) => ({
    color: theme.palette.text.secondary,
    fontWeight: 600,
}));

export const SiteEditForm = () => {
    const { patch } = useFetch();
    const isBasicRole = useIsBasicRole();
    const { openSnackbar } = useSnackbar();

    const {
        data: users,
        error: usersError,
        isLoading: areUsersLoading,
    } = useUsers();
    const {
        data: site,
        error: siteError,
        isLoading: isSiteLoading,
        mutate,
    } = useSite();

    const {
        control,
        register,
        handleSubmit,
        reset,
        formState: { errors, isSubmitting, isDirty },
    } = useForm({
        defaultValues: {
            name: "",
            displayName: "",
            contractUuid: "",
            supervisorUuid: "",
            countryCode: null,
        },
        resetOptions: { keepDirtyValues: true },
        values: site && {
            name: site.name,
            displayName: site.displayName,
            contractUuid: site.contractUuid,
            supervisorUuid: site.supervisorUuid,
            countryCode: site.countryCode
                ? SORTED_COUNTRIES.find(
                      (country) => country.alpha3 === site.countryCode
                  ) ?? null
                : null,
        },
    });

    const mergedFetchError = siteError ?? usersError;
    if (mergedFetchError) return <FetchErrorAlert error={mergedFetchError} />;
    if (isSiteLoading || areUsersLoading) return <SiteEditFormSkeleton />;

    const onSubmit = async (payload) => {
        const updatedSite = await patch(`${BACKEND_ROUTES.SITE}/${site.uuid}`, {
            body: {
                ...payload,
                countryCode: payload.countryCode?.alpha3 ?? null,
            },
        });

        if (updatedSite) {
            openSnackbar(
                `Site ${updatedSite.displayName} updated successfully.`,
                "success"
            );

            mutate();
        }
    };

    const onCancel = () => {
        reset();
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={1} alignItems="center">
                <Grid item xs={12}>
                    <Typography variant="h4" gutterBottom>
                        Site management
                    </Typography>
                </Grid>
                <Grid item xs={2}>
                    <LabelStyle>Campaign</LabelStyle>
                </Grid>
                <Grid item xs={4}>
                    <Typography>{site.Contract.name}</Typography>
                </Grid>
                <Grid item xs={6} />
                <Grid item xs={2}>
                    <LabelStyle>Name</LabelStyle>
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        name="name"
                        id="name"
                        disabled
                        fullWidth
                        type="text"
                        error={Boolean(errors.name)}
                        helperText={errors.name?.message}
                        {...register("name", {
                            required: INPUT_VALIDATION.REQUIRED,
                        })}
                    />
                </Grid>
                <Grid item xs={6} />
                <Grid item xs={2}>
                    <LabelStyle>Display Name</LabelStyle>
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        name="displayName"
                        id="displayName"
                        fullWidth
                        type="text"
                        error={Boolean(errors.displayName)}
                        helperText={errors.displayName?.message}
                        {...register("displayName", {
                            required: INPUT_VALIDATION.REQUIRED,
                        })}
                    />
                </Grid>
                <Grid item xs={6} />
                {!isBasicRole && (
                    <>
                        <Grid item xs={2}>
                            <LabelStyle>Site Supervisor</LabelStyle>
                        </Grid>
                        <Grid item xs={4}>
                            <Controller
                                control={control}
                                name="supervisorUuid"
                                render={({ field: { ref, ...fieldProps } }) => (
                                    <TextField
                                        {...fieldProps}
                                        fullWidth
                                        id="supervisorUuid"
                                        select
                                        inputRef={ref}
                                        error={Boolean(errors.supervisorUuid)}
                                        helperText={
                                            errors.supervisorUuid?.message
                                        }
                                    >
                                        {users ? (
                                            users.map((user) => (
                                                <MenuItem
                                                    key={user.uuid}
                                                    value={user.uuid}
                                                >
                                                    {user.firstName}{" "}
                                                    {user.lastName}
                                                </MenuItem>
                                            ))
                                        ) : (
                                            <MenuItem
                                                key={site.supervisorUuid}
                                                value={site.supervisorUuid}
                                            >
                                                {site.Supervisor.firstName}{" "}
                                                {site.Supervisor.lastName}
                                            </MenuItem>
                                        )}
                                    </TextField>
                                )}
                                rules={{ required: INPUT_VALIDATION.REQUIRED }}
                            />
                        </Grid>
                        <Grid item xs={6} />
                    </>
                )}
                <Grid item xs={2}>
                    <LabelStyle>Country</LabelStyle>
                </Grid>
                <Grid item xs={4}>
                    <Controller
                        control={control}
                        name="countryCode"
                        render={({
                            field: { ref, onChange, ...fieldProps },
                        }) => (
                            <Autocomplete
                                {...fieldProps}
                                id="countryCode"
                                disabled={isBasicRole}
                                options={SORTED_COUNTRIES}
                                isOptionEqualToValue={(option, value) =>
                                    option.alpha3 === value.alpha3
                                }
                                fullWidth
                                onChange={(_, value) => {
                                    onChange(value);
                                }}
                                getOptionLabel={(option) => option.name}
                                renderOption={(props, option) => (
                                    <Box {...props} component="li">
                                        <Stack direction="row" spacing={1}>
                                            <CountryFlag country={option} />
                                            <span>{option.name}</span>
                                        </Stack>
                                    </Box>
                                )}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        inputRef={ref}
                                        label="Country"
                                    />
                                )}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={6} />

                {isDirty && (
                    <Grid item xs={12}>
                        <Stack
                            direction="row"
                            spacing={3}
                            justifyContent="flex-end"
                        >
                            <Button type="button" onClick={onCancel}>
                                Cancel
                            </Button>
                            <LoadingButton
                                type="submit"
                                variant="contained"
                                loading={isSubmitting}
                            >
                                Update
                            </LoadingButton>
                        </Stack>
                    </Grid>
                )}
            </Grid>
        </form>
    );
};
